import React, { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';
import { AppContext } from "../context/AppContext";
import { getSliders } from '../calls/auths';
import PopupCard from './PopupCard';  // Import PopupCard component

const LandingPage = () => {
  const { user } = useContext(AppContext);
  const isAdmin = user?.user_type === "admin";
  const [slider, setSlider] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);  // State to manage popup visibility

  async function init() {
    const response = await getSliders();
    setSlider(response.data);
  }

  useEffect(() => {
    init();
  }, []);

  function handleDoubleClick() {
    if(!isAdmin) {
      return;
    }
    setPopupVisible(true);  // Show the popup
  }

  function handleClosePopup() {
    setPopupVisible(false);  // Hide the popup
  }

  return (
    <div className="relative flex flex-col md:flex-row items-center justify-center h-[800px]">
      <Swiper
        loop
        modules={[Autoplay]}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        className="w-full h-[800px] z-10"
        onDoubleClick={handleDoubleClick}
      >
        {slider.length > 0 && slider.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={`relative w-full h-[800px] flex ${isAdmin ? 'hover:border-4 hover:border-blue-500 hover:border-dashed' : ''}`}>
              <div className="w-full md:w-1/2 h-full bg-cover bg-top" style={{ backgroundImage: `url(${item.image1})` }}></div>
              <div className="hidden md:block w-1/2 h-full bg-cover bg-top" style={{ backgroundImage: `url(${item.image2})` }}></div>
              <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white p-4 mt-[4rem] md:mt-[12.25rem]">
                <h1 className="text-xl md:text-5xl font-bold bg-secondary px-2 py-2 rounded-t-xl uppercase">{item.text1}</h1>
                <p className="text-base md:text-xl bg-secondary p-3 rounded-b-full uppercase">{item.text2}</p>
                <div className="flex mt-4">
                  <a href={`${item.buttonlink1}`} className="bg-white hover:bg-primary hover:text-white px-6 py-3 rounded-md text-secondary font-semibold text-base md:text-xl mr-12">
                    {item.button1}
                  </a>
                  <a href={`${item.buttonlink2}`} className="bg-white hover:bg-primary hover:text-white px-6 py-3 rounded-md text-secondary font-semibold text-base md:text-xl">
                    {item.button2}
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Render Popup Card */}
      {isPopupVisible && <PopupCard onClose={handleClosePopup} sliderData={slider} />}
    </div>
  );
};

export default LandingPage;
