/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import Popup from "reactjs-popup";
import fetchProductInfo from "./fetchProductInfo";
import "./adminstyle.css"; // Import the CSS file
import { FiSearch } from "react-icons/fi"; // Import the icon
import {
  createDispute,
  getAllDispute,
  getAllSales,
  getSaleByOrderNumber,
  API_ENDPOINT,
  updateProductStatus,
  updateProductStock,
 // updateColorVar,
} from "../../calls/auths";
import Pagination from "./Pagination";
// import axios from "axios";

const Dispute = () => {
  const [formData, setFormData] = useState({
    receipt_no: "",
    sku_no: "",
    product_name: "",
    color: "",
    size: "",
    price: "",
    discount_price: "",
    product_condition: "",
    reason: "",
    dos: "",
    dod: "",
    exchanged_product: "",
    epsn: "",
    epc: "",
    eps: "",
    epp: "",
    balance: "",
  });

  const [dispute, setDispute] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [exchangeProductId, setExchangeProductId] = useState("");
  const [exchangeQuantity, setExchangeQuantity] = useState("");
  const [sales, setSales] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSales, setFilteredSales] = useState([]); // State for filtered sales
  const [filteredDispute, setFilteredDispute] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false); // To manage input disabling
  const inputRef = useRef();
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState("");

  useEffect(() => {
    async function fetchOrderDetails() {
      const token = localStorage.getItem("tripstore_login");
      const response = await getSaleByOrderNumber(token);
      if (response.status === 200) {
        setSales(response.data);
        setFilteredSales(response.data);
      }
    }
    fetchOrderDetails();
  });

  // Calculate balance based on epp and price
  const calculateBalance = () => {
    const eppValue = parseFloat(formData.epp) || 0; // Ensure it's a number
    const priceValue = parseFloat(formData.price) || 0; // Ensure it's a number
    return eppValue - priceValue; // Calculate the balance
  };

  const fetchOrderDetails = async ({ orderNumber }) => {
    const token = localStorage.getItem("tripstore_login");
    try {
      const { data, status } = await getSaleByOrderNumber(orderNumber, token);
      if (status === 200) {
        setOrderDetails(data); // Store the fetched order details
      } else {
        alert("Order not found.");
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
      alert("Failed to fetch order details.");
    }
  };

  const handleProductSelection = (product) => {
    setSelectedProduct(product); // Set the selected product
    setFormData({
      ...formData,
      sku_no: product.sku,
      product_name: product.product,
      color: product.color,
      size: product.size,
      price: product.price,
      discount_price: product.discountPercentage, // Adjust based on your structure
    });
  };

  const handleReceiptChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "receipt_no" && value.length >= 5) {
        await fetchOrderDetails({ orderNumber: value });

        if (orderDetails && orderDetails.timestamp) {
            const dateOfSale = new Date(orderDetails.timestamp);
            const formattedDate = dateOfSale.toISOString().split('T')[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                dos: formattedDate, // Set the formatted date for Date of Sales
            }));
            console.log("Date of Sales set to:", formattedDate); // Log the formatted date
        }

        const currentDate = new Date();
        setFormData((prevFormData) => ({
            ...prevFormData,
            dod: currentDate.toISOString().split('T')[0], // Set Date of Dispute to current date
        }));
    }
};

  const fetchDispute = async () => {
    try {
      const response = await getAllDispute();
      console.log("Response Data:", response.data);
      if (response.status === 200) {
        if (Array.isArray(response.data)) {
        const allDispute = response.data; // Assuming this is an array of all Dispute
        setFilteredDispute(allDispute);
        setTotalItems(allDispute.length); // Set the total number of items
        const startIndex = (currentPage - 1) * itemsPerPage;
        const paginatedDispute = allDispute.slice(
          startIndex,
          startIndex + itemsPerPage
        );
        setDispute(paginatedDispute);
      } else {
        console.error("Response data is not an array:", response.data);
        setFilteredDispute([]);
      }
    } else {
      console.error("Failed to fetch disputes:", response.status);
      setFilteredDispute([]);
    }
   } catch (error) {
      console.error("Error fetching Dispute:", error);
      setFilteredDispute([]);
    }
  };

  useEffect(() => {
    fetchDispute();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Fetch all sales from the server
  useEffect(() => {
    async function getSales() {
      const token = localStorage.getItem("tripstore_login");
      const response = await getAllSales(token);
      if (response.status === 200) {
        setSales(response.data);
        setFilteredSales(response.data); // Initialize filtered sales with all sales
      }
    }
    getSales();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Formatting formData (You can modify based on your API requirements)
    const formattedData = {
      ...formData,
      balance: calculateBalance(),
      price: parseFloat(formData.price), // Parse price as a number
      discount_price: parseFloat(formData.discount_price), // Parse discount price as a number
      dos: new Date(formData.dos), // Date of Sales
      dod: new Date(formData.dod), // Date of Dispute
      epsn: formData.epsn, 
    };

    if (!selectedProduct) {
      alert("Please select a product");
      return;
    }

    const productToDispute = selectedProduct;

    try {
      // Update the status of the selected product to 'dispute'
      await updateProductStatus(orderDetails._id, productToDispute._id, "Dispute");

      // Add the disputed product back to the inventory
      await updateProductStock(productToDispute.sku, 1);

      // Update the exchanged product quantity
      // if (exchangeProductId) {
      //   await updateColorVar(exchangeProductId, -exchangeQuantity); // Decrease the quantity of the exchanged product
      // }
      const response = await createDispute(formattedData); // Call the API to submit dispute data
      if (response.status === 200) {
        alert("Dispute submitted successfully!");
        // Optionally, refresh the form or list of disputes
        window.location.reload(); // Reload page to clear form and reset state
      } else {
        alert("Failed to submit dispute. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting dispute:", error);
      alert("There was an error submitting the dispute.");
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Define the addProduct function in your component
  const addProduct = (product, color) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      epsn: product.productNumber, // Set the SKU number
      exchanged_product: product.name, // Set product name
      epc: color.color, // Set color if available
      eps: product.size || "", // Set size if available
      epp: product.price.toString() || "", // Set price as string
    }));
  };

  const handleManualInput = async (productNumber) => {
    console.log('Input changed:', productNumber);
    setIsFetching(true); // Disable input
    const baseUrl = API_ENDPOINT();

    try {
      const product = await fetchProductInfo(productNumber, baseUrl);

      if (!product) {
        alert("Product not found.");
        setIsFetching(false);
        return;
      }

      // Add product details to formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        epsn: product.productNumber, // Set the SKU number
        exchanged_product: product.name, // Set product name
        epc: product.colors[0]?.color || '', // Set color if available
        eps: product.size || '', // Set size if available
        epp: product.price.toString() || '', // Set price as string
    }));

      setScannedBarcode("");
    } catch (error) {
      console.error(error);
      alert("Error fetching product information.");
    } finally {
      setIsFetching(false); // Re-enable input
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  async function getDispute() {
    const response = await getAllDispute();
    setDispute(response.data);
    setFilteredDispute(response.data); // Initialize filtereddispute with all dispute
  }

  useEffect(() => {
    getDispute();
  }, []);

  async function deleteDisputefn(id) {
      const response = await deleteDisputefn({id});
      if(response.status === 200) {
          getDispute();
      }
  }

  return (
    <div className="mt-2 p-2">
      <Popup trigger={<button className="mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black">Create Dispute</button>} position="right center" modal>
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-lg">
            <div className="form-container" style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <form
              onSubmit={handleSubmit}
              className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8 border border-black animated-border bg-white"
            >
              <p className="text-center text-lg font-medium">Create Dispute</p>
              <div className="grid grid-cols-2 gap-2">
                {/* Receipt No */}
                <div>
                  <label htmlFor="receipt_no" className=" ">
                    Receipt No
                  </label>
                  <div className="relative">
                    <input
                      name="receipt_no"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                      placeholder="Enter Receipt No"
                      value={formData.receipt_no}
                      onChange={handleReceiptChange}
                    />
                  </div>
                </div>

                {/* Show the product list as a dropdown if orderDetails are fetched */}
                {orderDetails && (
                  <div>
                    <label htmlFor="product_selection">
                      Select Returned Product
                    </label>
                    <select
                      id="product_selection"
                      className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                      onChange={(e) =>
                        handleProductSelection(
                          orderDetails.items[e.target.value]
                        )
                      }
                    >
                      <option value="">-- Select Product --</option>
                      {orderDetails.items.map((product, index) => (
                        <option key={product._id} value={index}>
                          {product.product} - {product.color} - {product.size} -
                          ₦{product.price}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Auto-fill other fields based on selected product */}
                <div>
                  <label htmlFor="sku_no" className=" ">
                    SKU No
                  </label>
                  <div className="relative">
                    <input
                      name="sku_no"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                      placeholder="Enter SKU No"
                      value={formData.sku_no}
                      onChange={(e) =>
                        setFormData({ ...formData, sku_no: e.target.value })
                      }
                      readOnly
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="product_name" className=" ">
                    Product Name
                  </label>
                  <div className="relative">
                    <input
                      name="product_name"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                      placeholder="Enter Product Name"
                      value={formData.product_name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          product_name: e.target.value,
                        })
                      }
                      readOnly
                    />
                  </div>
                </div>

                {/* Other form fields for color, size, price, discount_price... */}
                <div>
                  <label htmlFor="color" className=" ">
                    Color
                  </label>
                  <div className="relative">
                    <input
                      name="color"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                      placeholder="Enter Color"
                      value={formData.color}
                      onChange={(e) =>
                        setFormData({ ...formData, color: e.target.value })
                      }
                      readOnly
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="size" className=" ">
                    Size
                  </label>
                  <div className="relative">
                    <input
                      name="size"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                      placeholder="Enter Size"
                      value={formData.size}
                      onChange={(e) =>
                        setFormData({ ...formData, size: e.target.value })
                      }
                      readOnly
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="price" className=" ">
                    Price
                  </label>
                  <div className="relative">
                    <input
                      name="price"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                      placeholder="Enter Price"
                      value={formData.price}
                      onChange={(e) =>
                        setFormData({ ...formData, price: e.target.value })
                      }
                      readOnly
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="discount_price" className=" ">
                    Discount (%)
                  </label>
                  <div className="relative">
                    <input
                      name="discount_price"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                      placeholder="Product Discount Percentage"
                      value={formData.discount_price}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          discount_price: e.target.value,
                        })
                      }
                      readOnly
                    />
                  </div>
                </div>

                {/* Product Condition */}
                <div>
                  <label htmlFor="product_condition" className=" ">
                    Product Condition
                  </label>
                  <div className="relative">
                    <input
                      name="product_condition"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                      placeholder="Enter Product Condition"
                      value={formData.product_condition}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* Reason */}
                <div>
                  <label htmlFor="reason" className=" ">
                    Reason
                  </label>
                  <div className="relative">
                    <input
                      name="reason"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                      placeholder="Enter Reason"
                      value={formData.reason}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* Date of Sales */}
                <div>
                  <label htmlFor="dos" className="">
                    Date of Sales
                  </label>
                  <div className="relative">
                  <input
            name="dos"
            type="date"
            className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
            placeholder="Enter Date of Sales"
            value={formData.dos}
            onChange={handleChange}
        />
                  </div>
                </div>

                {/* Date of Dispute */}
                <div>
                  <label htmlFor="dod" className="">
                    Date of Dispute
                  </label>
                  <div className="relative">
                  <input
            name="dod"
            type="date"
            className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
            placeholder="Enter Date of Dispute"
            value={formData.dod}
            readOnly
            onChange={handleChange}
        />
                  </div>
                </div>

                {/* Exchanged Product SKU No */}
                <div>
                  <label htmlFor="epsn">Exchanged Product SKU No</label>
                  <div className="relative">
                    <input
                      name="epsn"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                      placeholder="Enter Exchanged Product SKU No"
                      value={formData.epsn}
                      onChange={(e) => handleManualInput(e.target.value)} // Trigger handleManualInput on change
                    />
                  </div>
                </div>
      <div>
    <label htmlFor="epsn">Exchanged Product SKU No</label>
    <div className="relative">
        <input
            name="epsn"
            type="text"
            className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
            placeholder="Enter Exchanged Product SKU No"
            value={formData.epsn}
            onChange={handleChange} // Update state on change
        />
        </div>
        </div>

                {/* Exchanged Product */}
                <div>
                  <label htmlFor="exchanged_product">Exchanged Product</label>
                  <div className="relative">
                    <input
                      name="exchanged_product"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                      placeholder="Enter Exchanged Product"
                      value={formData.exchanged_product}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>

                {/* Exchanged Product Color */}
                <div>
                  <label htmlFor="epc">Exchanged Product Color</label>
                  <div className="relative">
                    <input
                      name="epc"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                      placeholder="Enter Exchanged Product Color"
                      value={formData.epc}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>

                {/* Exchanged Product Size */}
                <div>
                  <label htmlFor="eps">Exchanged Product Size</label>
                  <div className="relative">
                    <input
                      name="eps"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                      placeholder="Enter Exchanged Product Size"
                      value={formData.eps}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>

                {/* Exchanged Product Price */}
                <div>
                  <label htmlFor="epp">Exchanged Product Price</label>
                  <div className="relative">
                    <input
                      name="epp"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                      placeholder="Enter Exchanged Product Price"
                      value={formData.epp}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>

                {/* Balance */}
                <div>
                  <label htmlFor="balance" className=" ">
                    Balance
                  </label>
                  <div className="relative">
                    <input
                      name="balance"
                      type="text"
                      className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                      readOnly
                      value={calculateBalance()}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="block w-full rounded-lg bg-black px-5 py-3 text-sm font-medium text-white"
              >
                Create Dispute
              </button>
            </form>
          </div>
          </div>
        </div>
      </Popup>
      <div className="relative mb-3">
        <label htmlFor="Search" className=" ">
          {" "}
          Search{" "}
        </label>
        <input
          type="text"
          id="Search"
          placeholder="Search for..."
          className="w-full rounded-md border-gray-200 py-2.5 pe-10 shadow-sm sm:text-sm"
          value={searchTerm}
          onChange={handleSearch}
        />
        <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
          <button type="button" className="text-gray-600 hover:text-gray-700">
            <span className="sr-only">Search</span>
            <FiSearch />
          </button>
        </span>
      </div>
      <div className="rounded-lg border border-gray-200">
        <div className="overflow-x-auto rounded-t-lg">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
            <thead className="">
              <tr>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  S/N
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Receipt No
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  SKU No
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Product Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Color
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Size
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Price
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Discount Price
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  PC
                </th>{" "}
                {/* Product Condition */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Reason
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  DOS
                </th>{" "}
                {/* Date of Sales */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  DOD
                </th>{" "}
                {/* Date of Dispute */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Exchanged Product
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  EPSN
                </th>{" "}
                {/* Exchanged Product SKU No */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  EPC
                </th>{" "}
                {/* Exchanged Product Color */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  EPS
                </th>{" "}
                {/* Exchanged Product Size */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  EPP
                </th>{" "}
                {/* Exchanged Product Price */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Balance
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {Array.isArray(filteredDispute) && filteredDispute.length > 0 ? ( filteredDispute.map((dispute, index) => (
            <tr key={dispute._id}>
            <td className="text-center py-2 font-medium text-gray-900">{index + 1}</td>
            <td className="text-center py-2 text-gray-700">{dispute.receipt_no}</td>
            <td className="text-center py-2 text-gray-700">{dispute.sku_no}</td>
            <td className="text-center py-2 text-gray-700">{dispute.product_name}</td>
            <td className="text-center py-2 text-gray-700">{dispute.color}</td>
            <td className="text-center py-2 text-gray-700">{dispute.size}</td>
            <td className="text-center py-2 text-gray-700">₦{dispute.price}</td>
            <td className="text-center py-2 text-gray-700">{dispute.discount_price}%</td>
            <td className="text-center py-2 text-gray-700">{dispute.product_condition}</td>
            <td className="text-center py-2 text-gray-700">{dispute.reason}</td>
            <td className="text-center py-2 text-gray-700">{new Date(dispute.dos).toLocaleDateString()}</td>
            <td className="text-center py-2 text-gray-700">{new Date(dispute.dod).toLocaleDateString()}</td>
            <td className="text-center py-2 text-gray-700">{dispute.exchanged_product}</td>
            <td className="text-center py-2 text-gray-700">{dispute.epsn}</td>
            <td className="text-center py-2 text-gray-700">{dispute.epc}</td>
            <td className="text-center py-2 text-gray-700">{dispute.eps}</td>
            <td className="text-center py-2 text-gray-700">₦{dispute.epp}</td>
            <td className="text-center py-2 text-gray-700">₦{dispute.balance}</td> {/* Calculate balance here */}
            <td className="text-center py-2">
                <Popup trigger={open => (
                        <button className="mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black">ACTION</button>
                    )}
                    position="right bottom"
                    closeOnDocumentClick
                >
                    <button className='mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black' onClick={() => deleteDisputefn(dispute._id)}> DELETE </button>
                </Popup>
            </td>
        </tr>
    ))
              ) : (
                <tr>
            <td colSpan="18" className="text-center py-2 text-gray-700">No disputes found.</td>
        </tr>
              )}
</tbody>
          </table>
        </div>
        <Pagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Dispute;
