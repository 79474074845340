import Bg from '../assets/bg.avif'

const TitleShowcase = ({ title }) => {

    return (
        <div className=" w-full h-[200px] bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${Bg})` }}>
            <div className=" w-full h-full bg-white/80 flex justify-center items-center flex-col text-center">
                <div className=" text-3xl font-semibold">{title}</div>
                <div className=" text-primary mt-2">Shop</div>
            </div>
        </div>
    )
}

export default TitleShowcase