const Description = ({description}) => {

    return (
        <div className="">
            <div className=" mb-2">Product Information</div>
            <div className=" text-gray-600 text-sm">{ description }</div>
        </div>
    )
}

export default Description