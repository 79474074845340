import { IoClose } from "react-icons/io5";
import Logo from '../assets/logo-bg.png'
import { NavLink } from "react-router-dom";

const SideNav = ({ expandedNav, setExpandedNav, children }) => {

    return (
        <div className={`${expandedNav ? " left-0" : " -left-[250px]"} transition-all w-[250px] absolute md:relative top-0 md:left-0 bottom-0 h-full z-30 bg-white shadow-lg overflow-hidden`}>
            <div onClick={() => setExpandedNav(false)} className=" md:hidden absolute right-4 top-4 text-2xl active:text-primary cursor-pointer">
                <IoClose />
            </div>
            <div className=" px-6 py-2 h-full flex flex-col">
                <NavLink className='w-20 md:w-full' to={'/'}>
                    <img src={Logo} className='w-44 md:w-[100px]' alt="logo" />
                </NavLink>
                <div className=" flex-1 overflow-y-auto overflow-x-hidden small-scrollbar scrollbar">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default SideNav
