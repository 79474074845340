import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import {
  createStickyTopContent,
  getAllStickyTopContent,
  deleteStickyTopContent,
  updateStickyTopContentStatus
} from '../../calls/auths';

Modal.setAppElement('#root');

const StickyTopContentPage = () => {
  const [formData, setFormData] = useState({
    mainText: '',
    subText: '',
    highlightText: '',
    offerText: '',
    bgColor: '#000000',
    textColor: '#FFFFFF',
    link: ''
  });

  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getAllStickyTopContent();
      console.log('Fetch API Response:', response);
      
      if (response.status === 200) {
        let newData = Array.isArray(response.data) ? response.data : [response.data];
        console.log('Setting table data to:', newData);
        setTableData(newData);
      } else {
        console.error('Failed to fetch data:', response);
        setError('Failed to fetch data. Please try again later.');
        setTableData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('An error occurred while fetching data. Please try again later.');
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log('Table data updated:', tableData);
  }, [tableData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    try {
      const response = await createStickyTopContent(formData);
      console.log('Create API response:', response);
      if (response.status === 201) {
        alert('Sticky Top Content created successfully!');
        setFormData({ mainText: '', subText: '', highlightText: '', offerText: '', bgColor: '#000000',
        textColor: '#FFFFFF',
        link: '' });
        setIsModalOpen(false);
  
        // Add the new item to the table data
        setTableData(prevState => [...prevState, response.data]);
      } else {
        console.error('Unexpected response status:', response.status);
        console.error('Response data:', response.data);
        alert('Failed to create Sticky Top Content. Please try again.');
      }
    } catch (error) {
      console.error('Error creating Sticky Top Content:', error);
      alert('Failed to create Sticky Top Content. Please try again.');
    }
  };

  const handleDelete = async (index, id) => {
    try {
      const response = await deleteStickyTopContent(id);
      if (response.status === 200) {
        setTableData(prevState => prevState.filter((_, i) => i !== index));
      } else {
        alert('Failed to delete Sticky Top Content. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting Sticky Top Content:', error);
      alert('Failed to delete Sticky Top Content. Please try again.');
    }
  };

  const handleChangeStatus = async (index, id, currentStatus) => {
    const newStatus = currentStatus === 'Active' ? 'Inactive' : 'Active';
    try {
      const response = await updateStickyTopContentStatus(id, newStatus);
      if (response.status === 200) {
        setTableData(prevState => 
          prevState.map((item, i) => i === index ? { ...item, status: newStatus } : item)
        );
      } else {
        alert('Failed to update status. Please try again.');
      }
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status. Please try again.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10">
      <button 
        onClick={() => setIsModalOpen(true)}
        className="mb-5 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Create Sticky Top Content
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Create Sticky Top Content"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-2xl font-bold mb-5">Create Sticky Top Content</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="mainText" className="block text-sm font-medium text-gray-700">Main Text</label>
            <input
              type="text"
              id="mainText"
              name="mainText"
              value={formData.mainText}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="subText" className="block text-sm font-medium text-gray-700">Sub Text</label>
            <input
              type="text"
              id="subText"
              name="subText"
              value={formData.subText}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="highlightText" className="block text-sm font-medium text-gray-700">Highlight Text</label>
            <input
              type="text"
              id="highlightText"
              name="highlightText"
              value={formData.highlightText}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="offerText" className="block text-sm font-medium text-gray-700">Offer Text</label>
            <input
              type="text"
              id="offerText"
              name="offerText"
              value={formData.offerText}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="bgColor" className="block text-sm font-medium text-gray-700">Background Color</label>
            <input
              type="color"
              id="bgColor"
              name="bgColor"
              value={formData.bgColor}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="textColor" className="block text-sm font-medium text-gray-700">Text Color</label>
            <input
              type="color"
              id="textColor"
              name="textColor"
              value={formData.textColor}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="link" className="block text-sm font-medium text-gray-700">Link</label>
            <input
              type="url"
              id="link"
              name="link"
              value={formData.link}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Create Sticky Top Content
          </button>
        </form>
      </Modal>

      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : Array.isArray(tableData) && tableData.length > 0 ? (
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="px-4 py-2">S/N</th>
              <th className="px-4 py-2">Main Text</th>
              <th className="px-4 py-2">Sub Text</th>
              <th className="px-4 py-2">Highlight Text</th>
              <th className="px-4 py-2">Offer Text</th>
              <th className="px-4 py-2">BG Color</th>
              <th className="px-4 py-2">Text Color</th>
              <th className="px-4 py-2">Link</th>
              <th className="px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((item, index) => (
                <tr key={item._id || index}>
                  <td className="border px-4 py-2">{index + 1}</td>
                  <td className="border px-4 py-2">{item.mainText}</td>
                  <td className="border px-4 py-2">{item.subText}</td>
                  <td className="border px-4 py-2">{item.highlightText}</td>
                  <td className="border px-4 py-2">{item.offerText}</td>
                  <td className="border px-4 py-2">{item.bgColor}</td>
                  <td className="border px-4 py-2">{item.textColor}</td>
                  <td className="border px-4 py-2">{item.link}</td>
                  <td className="border px-4 py-2 space-x-2">
                    <button
                      onClick={() => handleDelete(index, item._id)}
                      className="py-1 px-2 bg-red-500 text-white rounded"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
};

export default StickyTopContentPage;
