import ProductList from './home/ProductList';

const CategoryProduct = ({products}) => {

    return (
        <>
            <div className="app-container py-4">
                <ProductList products={products} />
            </div>
        </>
    )
}

export default CategoryProduct