import React, { useState } from 'react';

const ReviewDetails = () => {
  const [rating, setRating] = useState(0);
  const [reviewTitle, setReviewTitle] = useState('');
  const [reviewName, setReviewName] = useState('');
  const [detailedReview, setDetailedReview] = useState('');

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleSubmit = () => {
    // Handle the submit action
    console.log('Rating:', rating);
    console.log('Review Title:', reviewTitle);
    console.log('Your Name:', reviewName);
    console.log('Detailed Review:', detailedReview);
  };

  return (
    <div className="p-6 max-w-xl mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h2 className="text-xl font-semibold">Rate & Review</h2>
      <div>
        <p className="text-sm font-medium">SELECT THE STARS TO RATE THE PRODUCT</p>
        <div className="flex items-center mt-2">
          <img
            src="https://via.placeholder.com/100"
            alt="Product"
            className="w-24 h-24 object-cover rounded"
          />
          <div className="ml-4">
            <p className="font-medium">Unisex Anti Blue Light Protective Computer Screen Glasses</p>
            <div className="flex mt-2">
              {[1, 2, 3, 4, 5].map((star) => (
                <svg
                  key={star}
                  onClick={() => handleRating(star)}
                  className={`w-6 h-6 cursor-pointer ${rating >= star ? 'text-yellow-500' : 'text-gray-400'}`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.98a1 1 0 00.95.69h4.186c.969 0 1.371 1.24.588 1.81l-3.396 2.468a1 1 0 00-.364 1.118l1.286 3.98c.3.921-.755 1.688-1.54 1.118l-3.396-2.468a1 1 0 00-1.176 0l-3.396 2.468c-.785.57-1.84-.197-1.54-1.118l1.286-3.98a1 1 0 00-.364-1.118L2.22 9.407c-.784-.57-.38-1.81.588-1.81h4.186a1 1 0 00.95-.69l1.286-3.98z" />
                </svg>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm font-medium">LEAVE A REVIEW</p>
        <div className="mt-2">
          <input
            type="text"
            placeholder="e.g. I like it! / I don't like it!"
            value={reviewTitle}
            onChange={(e) => setReviewTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-2"
          />
          <input
            type="text"
            placeholder="Your name"
            value={reviewName}
            onChange={(e) => setReviewName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-2"
          />
          <textarea
            placeholder="Tell us more about your rating!"
            value={detailedReview}
            onChange={(e) => setDetailedReview(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-2"
            rows="4"
          ></textarea>
        </div>
      </div>
      <button
        onClick={handleSubmit}
        className="w-full py-2 bg-secondary text-white font-semibold rounded mt-4"
      >
        SUBMIT YOUR REVIEW
      </button>
    </div>
  );
};

export default ReviewDetails;
