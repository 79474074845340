import React from 'react'

const StockKeeping = () => {
  return (
    <div>
      <h1>Coming Soon!!!</h1>
    </div>
  )
}

export default StockKeeping
