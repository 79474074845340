import React from 'react'
import ContactUs from '../components/Contact'

const Contact = () => {
  return (
    <div>
      <ContactUs />
    </div>
  )
}

export default Contact
