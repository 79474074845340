import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../context/AppContext";
import { getUserReview } from '../../calls/auths';

const PendingReviewItem = ({ image, title, deliveryDate, productId, rating, comment, approved }) => {
  const navigate = useNavigate();

  const handleRateProduct = () => {
    navigate(`/user/review/details/${productId}`);
  };

  return (
    <div className="flex items-center justify-between border rounded-lg p-4 mb-4">
      <div className="flex items-center">
        <img src={image} alt={title} className="w-16 h-16 object-cover mr-4" />
        <div>
          <h3 className="font-semibold">{title}</h3>
          <p className="text-sm text-green-600">Commented on {deliveryDate}</p>
          {rating !== undefined && <p className="text-sm text-yellow-600">Rating: {rating} {rating > 1 ? 'stars' : 'star'}</p>}
          {comment && <p className="text-sm text-gray-700">Comment: {comment}</p>}
           <p className={`text-sm`}>Approval: <span className={`text-sm ${approved ? 'text-green-500' : 'text-red-500'}`}>{approved ? 'Approved 🎉' : 'Pending Approval ⏳'}</span></p>
        </div>
      </div>
      {/* <button 
        className="text-orange-500 font-semibold"
        onClick={handleRateProduct}
      >
        RATE THIS PRODUCT
      </button> */}
    </div>
  );
};

const UserReviews = () => {
  const { user } = useContext(AppContext);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchReviews() {
    const response = await getUserReview(user._id);
    if (response && response.data) {
      setReviews(response.data);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (user) {
      fetchReviews();
    }
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-3xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">
        {reviews.length > 0 ? `Reviews (${reviews.length})` : 'No review'}
      </h2>

      {reviews.length > 0 && reviews.map((review) => (
        <PendingReviewItem
          key={review._id}
          image={review.productInformation.images[0]}
          title={review.productInformation.name}
          deliveryDate={new Date(review.createdAt).toLocaleDateString()}
          productId={review.product}
          rating={review.rating}
          comment={review.comment}
          approved={review.is_approved}
        />
      ))}
    </div>
  );
};

export default UserReviews;
