import ScrollUpBtn from "../components/ScrollUpBtn"
import Products from "../components/CategoryProduct"
import Top from "../components/top"
import Heads from "../components/Heads"
import Subscribe from "../components/Subscribe"
import Footer from "../components/Footer"
import StickyTop from "../components/home/StickyTop"

const Category = () => {

    return (
        <div className=" relative w-full min-h-screen">
            <Top />
            <StickyTop />
            <Heads />
            <Products />
            <Subscribe />
            <Footer />
            <ScrollUpBtn />
        </div>
    )
}

export default Category