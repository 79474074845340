import React, { useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";

const ProductBarcode = () => {
  const { newProducts } = useContext(AppContext);

  useEffect(() => {
    // console.log(newProducts);
  }, [newProducts]);

  const printBarcode = (imageUrl, sku, name, size, price) => {
    const win = window.open();
    win.document.write(`
      <html>
      <head>
        <style>
          body {
            text-align: center;
          }
          img {
            margin-bottom: 2px;
            width: 100%;
            height: 15mm;
            margin-top: -20px;
        }
        p {
            font-size: 20px;
        }
        .barcode-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .barcode-item {
            width: 72mm;
            height: 36mm;
            box-sizing: border-box;
            padding: 2px;
            margin:2px
            border: 1px solid black;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .barcode-item p {
            margin: 0;
        }
        </style>
      </head>
      <body>
        <div class="barcode-container">
        <div class="barcode-item flex-col">
          <img src="${imageUrl}" alt="Barcode" />
          <p>SKU: ${sku}</p>
          <p>${name} (${size})</p>
          <p>₦${price.toLocaleString()}</p>
        </div>
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    win.document.close();
  };

  const groupProductsByName = (data) => {
    return data.reduce((acc, product) => {
      const { name } = product;
      acc[name] = acc[name] || [];
      acc[name].push(product);
      return acc;
    }, {});
  };

  const groupedProducts = groupProductsByName(newProducts);

  return (
    <div className="flex flex-col gap-4 mb-8">
      {Object.entries(groupedProducts).map(([name, productGroup]) => (
        <div key={name} className="flex flex-col">
          <div className="text-xl font-bold">{name}</div>
          {productGroup.map((product, index) => (
            <div
              key={index}
              className="border-t border-b border-gray-300 p-4 flex flex-col"
            >
              <div className="flex items-center mb-4">
                <div className="flex flex-col mr-4">
                  <img
                    src={product.barcode}
                    alt="Product Barcode"
                    className="w-full h-40 mb-2"
                  />
                  <span className="text-sm text-gray-600">
                    SKU: {product._id}
                  </span>
                  <button
                    onClick={() =>
                      printBarcode(product.barcode, product._id, product.name)
                    }
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 mt-2"
                  >
                    Print Barcode
                  </button>
                </div>
              </div>
              <div className="flex flex-col">
                {product.measurements.map((measurement, idx) => (
                  <div key={idx} className="flex items-center mb-4">
                    <div className="flex flex-col mr-4">
                      <img
                        src={measurement.barcode}
                        alt="Measurement Barcode"
                        className="w-full h-40 mb-2"
                      />
                      <span className="text-sm text-gray-600">
                        SKU: {measurement._id}
                      </span>
                      <button
                        onClick={() =>
                          printBarcode(
                            measurement.barcode,
                            measurement._id,
                            product.name,
                            measurement.size,
                            measurement.price
                          )
                        }
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 mt-2"
                      >
                        Print Barcode
                      </button>
                    </div>
                    <div>
                      <span className="mr-4">
                        Size: {measurement.size}, Price: ₦ {measurement.price.toLocaleString()}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ProductBarcode;
