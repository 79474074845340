const AdditionalInfo = ({additionalInformation}) => {

    return (
        <div className="">
            <div className=" mb-2">Information</div>
            <div className=" mt-2">{additionalInformation}</div>
        </div>
    )
}

export default AdditionalInfo