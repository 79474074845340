import React, { useState, useMemo, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getAllOrders, updateOrderStatusFn } from '../calls/auths';

const OrderList = ({ updateOrderStatus }) => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [editingOrder, setEditingOrder] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    product: '',
    userName: '',
    deliveryStatus: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
  };

  const handleEditOrder = (order) => {
    setEditingOrder(order);
    setNewStatus(order.status); // Updated to use `order.status`
  };

  const handleStatusChange = (e) => {
    setNewStatus(e.target.value);
  };

  const handleSaveStatus = async () => {
    const orderId = editingOrder._id;
    const status = newStatus;
    const response = await updateOrderStatusFn({ orderId, status });
    
    if (response) {
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === orderId ? { ...order, status: response.data.status } : order
        )
      );
      setEditingOrder(null);
    }
  };

  const handleCloseDetails = () => {
    setSelectedOrder(null);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: start,
      endDate: end
    }));
    setCurrentPage(1); // Reset to first page when filters change
  };

  const filteredOrders = useMemo(() => {
    return orders.filter(order => {
      const dateMatch = filters.startDate && filters.endDate
        ? new Date(order.orderDate) >= filters.startDate && new Date(order.orderDate) <= filters.endDate
        : true;
      const productMatch = filters.product
        ? order.items.some(p => p.productName.toLowerCase().includes(filters.product.toLowerCase()))
        : true;
      const userMatch = filters.userName
        ? order.username.toLowerCase().includes(filters.userName.toLowerCase())
        : true;
      const statusMatch = filters.deliveryStatus
        ? order.status.toLowerCase().includes(filters.deliveryStatus.toLowerCase())
        : true;
      return dateMatch && productMatch && userMatch && statusMatch;
    });
  }, [orders, filters]);

  const paginatedOrders = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredOrders.slice(startIndex, endIndex);
  }, [filteredOrders, currentPage, itemsPerPage]);

  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  async function getOrders() {
    const response = await getAllOrders();
    setOrders(response.data);
  }

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <div className="p-4">
      <div className="mb-4 flex space-x-4">
        <DatePicker
          selected={filters.startDate}
          onChange={handleDateChange}
          startDate={filters.startDate}
          endDate={filters.endDate}
          selectsRange
          isClearable
          placeholderText="Filter by date range"
          className="border p-2 rounded"
        />
        <input
          type="text"
          name="product"
          placeholder="Filter by product"
          value={filters.product}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
        <input
          type="text"
          name="userName"
          placeholder="Filter by username"
          value={filters.userName}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
        <input
          type="text"
          name="deliveryStatus"
          placeholder="Filter by delivery status"
          value={filters.deliveryStatus}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
      </div>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">ID</th>
            <th className="py-2 px-4 border-b">User Name</th>
            <th className="py-2 px-4 border-b">Date</th>
            <th className="py-2 px-4 border-b">Product Total (Excluding Shipping)</th>
            <th className="py-2 px-4 border-b">Coupon Code Used</th>
            <th className="py-2 px-4 border-b">Coupon Code %</th>
            <th className="py-2 px-4 border-b">Payment Status</th>
            <th className="py-2 px-4 border-b">Delivery Status</th>
            <th className="py-2 px-4 border-b">Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedOrders.map((order) => (
            <tr key={order._id} className='text-center'>{console.log(order)}
              <td className="py-2 px-4 border-b">{order._id}</td>
              <td className="py-2 px-4 border-b">{order.username}</td>
              <td className="py-2 px-4 border-b">{new Date(order.orderDate).toLocaleDateString()}</td>
              <td className="py-2 px-4 border-b">₦{order.totalAmount.toLocaleString()}.00</td>
              <td className="py-2 px-4 border-b">{order.couponName ? order.couponName : 'No coupon code used on this purchase'}</td>
              <td className="py-2 px-4 border-b">{order.couponDiscount ? order.couponDiscount + '% discount' : 'No coupon code used on this purchase'}</td>
              <td className="py-2 px-4 border-b">{order.paymentMethod}</td>
              <td className="py-2 px-4 border-b">{order.status}</td>
              <td className="py-2 px-4 border-b">
                <button
                  onClick={() => handleEditOrder(order)}
                  className="bg-secondary text-white px-4 py-2 rounded mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleViewDetails(order)}
                  className="bg-gray-400 text-secondary px-4 py-2 rounded"
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4 flex justify-between items-center">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          className="bg-gray-300 text-secondary px-4 py-2 rounded mr-2"
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          className="bg-gray-300 text-secondary px-4 py-2 rounded ml-2"
        >
          Next
        </button>
      </div>

      {selectedOrder && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center text-center">
          <div className="bg-white p-10 rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4">Customer's Order Details</h2>
            <p><strong>Order ID:</strong> {selectedOrder._id}</p>
            <p><strong>User Name:</strong> {selectedOrder.username}</p>
            <p><strong>Date:</strong> {new Date(selectedOrder.orderDate).toLocaleDateString()}</p>
            <p><strong>Total:</strong> ₦{selectedOrder.totalAmount.toLocaleString()}</p>
            <p><strong>Payment Status:</strong> {selectedOrder.paymentMethod}</p>
            <p><strong>Delivery Status:</strong> {selectedOrder.status}</p>
            <p><strong>Shipping Address:</strong> {selectedOrder.shippingAddress}</p>
            
            <h3 className="text-lg font-bold mt-4">Products:</h3>
            <ul>
            {selectedOrder.items.map((item) => {
              const hasDiscount = item.discount > 0;
              const discountedPrice = (item.price * (1 - item.discount / 100)).toFixed(2);
              const originalPrice = item.price.toFixed(2);

              return (
                  <li key={item.productsActualID}>
                        {item.productName} - {item.colorName} - {item.quantity} x 
                        {hasDiscount ? (
                          <>
                            <span style={{ textDecoration: 'line-through' }} className='text-red-500'> ₦{originalPrice}</span>{' '}
                            <span className='text-green-500'> ₦{discountedPrice} ({item.discount}% OFF)</span>
                          </>
                        ) : (
                          <> ₦{originalPrice}</>
                        )}
                  </li>
                );
              })}
            </ul>
            
            <button
              onClick={handleCloseDetails}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {editingOrder && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center text-center">
          <div className="bg-white p-10 rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4">Edit Delivery Status</h2>
            <p><strong>Order ID:</strong> {editingOrder._id}</p>
            <div className="mt-4">
              <select
                value={newStatus}
                onChange={handleStatusChange}
                className="border p-2 rounded"
              >
                {/* the values below are the only accepted values in the database and are set under enums in the order model, they are also case sensitive and MUST be in lowercase */}
                <option value="ongoing">Ongoing</option>
                <option value="delivered">Delivered</option>
                <option value="cancelled">Cancelled</option>
                <option value="returned">Returned</option>
              </select>
            </div>
            <button
              onClick={handleSaveStatus}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded mr-2"
            >
              Save
            </button>
            <button
              onClick={() => setEditingOrder(null)}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderList;
