import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import SalesRepDashboard from "./screens/sales_rep/SalesRepDashboard";
import { useContext, useEffect, useState } from "react";
import SalesRepNav from "./components/sales_rep/SalesRepNav";
import Header from "./components/Header";
import SalesRepSales from "./screens/sales_rep/SalesRepSales";
import { AppContext } from "./context/AppContext";
import Settings from "./screens/Settings";
import { jwtDecode } from "jwt-decode";
import PausedSales from "./screens/sales_rep/PausedSales";

const GetPausedSaleComponent = () => {
    const { orderNumber } = useParams();
    const getPausedSale = (orderNumber) => {
        return JSON.parse(localStorage.getItem(`pausedSale-${orderNumber}`));
    };
    const pausedSale = getPausedSale(orderNumber);
    return <SalesRepDashboard pausedSale={pausedSale} />;
};

const SalesRep = () => {
    const { setLoggedIn } = useContext(AppContext);

    const location = useLocation();
    const navigate = useNavigate();

    const [headerTitle, setHeaderTitle] = useState('');
    const [expandedNav, setExpandedNav] = useState(false);

    const handleTitleChange = () => {
        switch (location.pathname) {
            case '/sales_rep/dashboard':
                setHeaderTitle('Point of Sales');
                break;
            case '/sales_rep/sales':
                setHeaderTitle('My Sales Receipt');
                break;
            case '/sales_rep/paused-sales':
                setHeaderTitle('My Paused Sales');
                break;
            case '/sales_rep/settings':
                setHeaderTitle('Settings');
                break;
            default:
                setHeaderTitle('Sales Rep');
                break;
        }
    };

    useEffect(() => {
      const handleLogin = async () => {
          const loginSession = window.localStorage.getItem("tripstore_login");
    
          if (loginSession && loginSession !== "") {
              const userType = jwtDecode(loginSession);
              const isUser = userType.user_type === "user";
              const isAdmin = userType.user_type === "admin";
              if (isUser) {
                  navigate("/user/dashboard");
              } else if (isAdmin) {
                  navigate("/admin/dashboard");
              }
          } else {
              navigate('/login');
          }
      };
    
      handleLogin();
      handleTitleChange();
  }, [location.pathname]);

    return (
        <div className="w-full h-screen md:flex relative">
            <SalesRepNav expandedNav={expandedNav} setExpandedNav={setExpandedNav} />
            <div onClick={() => setExpandedNav(false)} className={`${expandedNav ? "block" : "hidden"} md:hidden fixed w-screen h-screen z-10`}></div>
            <div className="h-full overflow-y-auto overflow-x-hidden md:flex-1 scrollbar relative">
                <Header setExpandedNav={setExpandedNav} title={headerTitle} setLoggedIn={setLoggedIn} />
                <Routes>
                    <Route element={<SalesRepDashboard />} path="/dashboard" />
                    <Route element={<PausedSales />} path="/paused-sales/*" />
                    <Route element={<GetPausedSaleComponent />} path="/paused-sales/:orderNumber" />
                    <Route element={<SalesRepSales />} path="/sales" />
                    <Route element={<Settings userType={'SalesRep'} />} path="/settings" />
                </Routes>
            </div>
        </div>
    );
};

export default SalesRep;
