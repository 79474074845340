import React from 'react';

const MessageResponse = ({ response, msg }) => {
  // Check if response is defined
  if (!response) {
    return <div>Error: No response received</div>; // Handle the case where response is undefined
  }

  // Destructure data and status safely
  const { data, status } = response;
  console.log('Response prop:', response);
  const isSuccess = status === 200 || status === 201;
  const messageStyle = {
    color: isSuccess ? '#12da12' : '#ff4f4f',
    border: `1px solid ${isSuccess ? 'green' : 'red'}`,
    padding: '10px',
    backgroundColor: isSuccess ? "#60ff0029" : "#ff000029",
    borderRadius: '7px',
    margin: '10px 0'
  };

  return (
    <div style={messageStyle}>
      <p>{msg}</p>
    </div>
  );
};

export default MessageResponse;