import { useContext, useState } from 'react';
import Bg from '../../assets/bg.avif'
import Logo from '../../assets/ic.png'
import { FaAnglesRight } from "react-icons/fa6";
import { NavLink, useNavigate } from 'react-router-dom'
import {  userRegister } from '../../calls/auths';
import { AppContext } from '../../context/AppContext'
import Loader from '../../calls/Loader';
import MessageResponse from '../../calls/MessageResponse';

const AdminReg = () => {

    const { setLoggedIn } = useContext(AppContext)
    const Navigate = useNavigate()

    const [inputs, setInputs] = useState({ user_type: 'admin', social_auth: false })
    const [isLoading, setIsLoading] = useState(false)
    const [apiRes, setApiRes] = useState();
    const [apiMsg, setApiMsg] = useState();


    const handleInputChange = (e) => {
        const { name, value } = e.target
        setInputs({ ...inputs, [name]: value })
    }

    const onRegSubmit = async (e) => {
        e.preventDefault()

        console.log(inputs)

        setIsLoading(true);
        const response = await userRegister(inputs);
        setApiRes(response);
        setApiMsg(response.data.message || "User Logged In");
        setIsLoading(false);
        if (response.data.token) {
            setLoggedIn(true);
            window.localStorage.setItem("tripstore_login", response.data.token)
            Navigate('/admin/dashboard')
        }

        setTimeout(() => {
            setApiRes();
        }, 5000);
    }

    return (
        <div className=" relative w-full h-screen bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${Bg})` }}>
            <div className=" absolute top-0 left-0 w-full h-full bg-secondary/10 flex justify-center items-center">
                <div className=" rounded-sm overflow-hidden shadow-lg bg-white/90 backdrop-blur-sm w-[95%] max-w-[600px]">
                    <div className=" w-full p-8">
                    { isLoading && <Loader/> }
                        <NavLink to={'/'}>
                            <img src={Logo} className=' h-[80px] m-auto' alt="" />
                        </NavLink>
                        <div className=" text-2xl tracking-wide text-center">Admin Sign Up</div>
                        { apiRes && <MessageResponse response={apiRes} msg={apiMsg}/> }
                        <form onSubmit={onRegSubmit} action="" className=' w-full mt-2'>
                            <div className=" flex flex-col my-2">
                                <label htmlFor="f-name" className=' text-sm font-semibold'>First Name</label>
                                <input type="text" name='first_name' id='f-name' onChange={handleInputChange} required />
                            </div>
                            <div className=" flex flex-col my-2">
                                <label htmlFor="l-name" className=' text-sm font-semibold'>Last Name</label>
                                <input type="text" name='last_name' id='l-name' onChange={handleInputChange} required />
                            </div>
                            <div className=" flex flex-col my-2">
                                <label htmlFor="email" className=' text-sm font-semibold'>Email</label>
                                <input type="email" name='email' id='email' onChange={handleInputChange} required />
                            </div>
                            <div className=" flex flex-col my-2">
                                <label htmlFor="pwd" className=' text-sm font-semibold'>Password</label>
                                <input type="password" name='pwd' id='pwd' onChange={handleInputChange} required />
                            </div>
                            <div className=" flex flex-col my-2">
                                <label htmlFor="c-pwd" className=' text-sm font-semibold'>Confirm Password</label>
                                <input type="password" name='c_pwd' id='c-pwd' onChange={handleInputChange} required />
                            </div>
                            <button className=' w-full bg-tertiary text-white mt-4' type="submit">Register</button>
                        </form>
                        <div className=" mt-4 flex items-center gap-2 text-sm font-semibold">
                            <div className=" flex-1 text-gray-500">Already have an account?</div>
                            <div onClick={() => Navigate('/admin/dashboard')} className=" flex flex-nowrap items-center gap-2 hover:text-primary cursor-pointer">
                                <div className=" text-nowrap">Login</div>
                                <FaAnglesRight />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminReg