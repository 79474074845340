import { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { changePasswordViaAdmin, getAllUsers } from "../../calls/auths";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sliceCount, setSliceCount] = useState({ from: 0, to: 15 });
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("");

  const addSlice = () => {
    if (sliceCount.to >= filteredUsers.length) {
      setSliceCount({ from: sliceCount.from, to: sliceCount.to });
    } else {
      setSliceCount({ from: sliceCount.to, to: sliceCount.to + 15 });
      document.querySelector('#table-container').scrollTo(0, 0);
    }
  };

  const cutSlice = () => {
    if (sliceCount.from <= 0) {
      setSliceCount({ from: sliceCount.from, to: sliceCount.to });
    } else {
      setSliceCount({ from: sliceCount.from - 15, to: sliceCount.from });
      document.querySelector('#table-container').scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getAllUsers();
        const fetchedUsers = response.data || [];
        setUsers(Array.isArray(fetchedUsers) ? fetchedUsers : []);
        setFilteredUsers(Array.isArray(fetchedUsers) ? fetchedUsers : []);
      } catch (error) {
        console.error('Error fetching users:', error);
        setUsers([]);
        setFilteredUsers([]);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user => 
      user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) || 
      user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) || 
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) || 
      user.user_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
    setSliceCount({ from: 0, to: 15 });
  }, [searchTerm, users]);

  const handleEditPassword = async () => {
    console.log(email)
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
    } else {
        const response = await changePasswordViaAdmin(email, password);
        console.log(response.data);
      setPasswordError("");
      console.log("Password:", password);
      // Handle password update logic here
      setShowModal(false);
    }
  };

  return (
    <>
      <div className="h-[10vh] flex justify-center items-end">
        <div className="w-[95%]">
          <form className="relative inline-block w-full">
            <input
              type="text"
              className="w-full py-2 px-4 pr-12"
              placeholder="Search user"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="absolute right-0 top-[50%] -translate-y-[50%]" type="submit">
              <IoSearch />
            </button>
          </form>
        </div>
      </div>
      <div className="h-[70vh] flex justify-center items-start pt-6">
        <div className="w-[95%] max-h-[95%] border-2 border-slate-300 rounded-md overflow-y-auto overflow-x-auto small-scrollbar scrollbar" id="table-container">
          <table className="w-full relative">
            <thead className="bg-tertiary text-white sticky top-0 z-[1]">
              <tr className="text-nowrap *:py-1 *:px-3">
                <td>S/N</td>
                <td>Name</td>
                <td>Email</td>
                <td>Status</td>
                <td>User Type</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {
                (filteredUsers.length > 0) ?
                  <>
                    {
                      filteredUsers.slice(sliceCount.from, sliceCount.to).map((item, index) => (
                        <tr key={index} className="*:py-1 *:px-3 even:bg-slate-200 cursor-pointer hover:bg-slate-300 active:bg-tertiary active:text-white">
                          <td>{index + 1 + sliceCount.from}</td>
                          <td>{`${item.firstname} ${item.lastname}`}</td>
                          <td>{item.email}</td>
                          <td>{item.status}</td>
                          <td>{item.user_type}</td>
                          <td>
                            <button onClick={() => {setShowModal(true); setEmail(item.email)}} className="bg-primary text-white">EDIT PASSWORD</button>
                          </td>
                        </tr>
                      ))
                    }
                  </>
                  :
                  <tr>
                    <td colSpan={6} className="py-1 px-3 text-center text-gray-500 font-semibold">No user</td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className="h-[10vh] w-[95%] m-auto flex justify-between items-center">
        <button onClick={() => cutSlice()} className="flex items-center gap-4 hover:bg-slate-200">
          <FaAngleLeft /> <span className="hidden md:inline">Previous</span>
        </button>
        <div className={`${(filteredUsers.length <= 0) && "hidden"} text-sm font-semibold text-gray-500`}>
          Showing {1 + sliceCount.from} to {(sliceCount.to >= filteredUsers.length) ? filteredUsers.length : sliceCount.to} of {filteredUsers.length}
        </div>
        <button onClick={() => addSlice()} className="flex items-center gap-4 hover:bg-slate-200">
          <span className="hidden md:inline">Next</span> <FaAngleRight />
        </button>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[95%] max-w-md">
            <h2 className="text-xl font-semibold mb-4">Edit Password</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
              <input
                type="password"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            {passwordError && (
              <div className="text-red-500 text-sm mb-4">{passwordError}</div>
            )}
            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleEditPassword}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Users;
