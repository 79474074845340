import React from 'react'
import FAQs from '../components/Faq'

const Faq = () => {
  return (
    <div>
      <FAQs />
    </div>
  )
}

export default Faq
