import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserOrders } from '../../calls/auths';
import { AppContext } from '../../context/AppContext';

const OrderItem = ({ image, title, orderNumber, deliveryDate, date, status, variation, price, quantity }) => {
  const navigate = useNavigate();

  const handleSeeDetails = () => {
    navigate(`/user/details/${orderNumber}`);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-4">
      <div className="flex items-start">
        <img src={image} alt={title} className="w-16 h-16 object-cover mr-4" />
        <div className="flex-grow">
          <h3 className="text-lg font-semibold">{title}</h3>
          <p className="text-sm text-gray-600">Order {orderNumber}</p>
          {variation && <p className="text-sm text-gray-600">Variation: {variation}</p>}
          {price && <p className="text-sm text-gray-600">Price: ₦ {price.toLocaleString()}</p>}
          {quantity && <p className="text-sm text-gray-600">Quantity: {quantity}</p>}
          <div className="mt-2">
            <span className="inline-block bg-gray-600 text-white text-xs font-semibold px-2 py-1 rounded">
              {status}
            </span>
          </div>
          {deliveryDate && <p className="text-sm text-gray-600">On: {deliveryDate}</p>}
          {date && <p className="text-sm text-gray-600">On: {date}</p>}
        </div>
        <button 
          className="text-secondary text-sm font-semibold"
          onClick={handleSeeDetails}
        >
          SEE DETAILS
        </button>
      </div>
    </div>
  );
};

const OrderHistory = () => {
  const { user } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState('cancelled');
  const [currentPage, setCurrentPage] = useState(1);
  const [ongoingOrders, setOngoingOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [images, setImages] = useState([]);
  const itemsPerPage = 4;

  useEffect(() => {
    async function fetchOrders() {
      if (user && user._id) {
        try {
          const result = await fetchUserOrders(user._id);

          if (!result || !result.data) {
            console.error('Error: Unable to fetch order data');
            return;
          }

          const orders = result.data.userOrder;
          const ongoing = orders.filter(order => order.status === 'ongoing');
          const cancelled = orders.filter(order => order.status === 'cancelled');

          setOngoingOrders(ongoing);
          setCancelledOrders(cancelled);
          setImages(result.data.productImages);

        } catch (error) {
          console.error('Error fetching orders:', error);
        }
      }
    }
    fetchOrders();
  }, [user]);

  const displayedOrders = activeTab === 'ongoing' ? ongoingOrders : cancelledOrders;

  const currentOrders = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return displayedOrders.slice(firstPageIndex, lastPageIndex);
  }, [displayedOrders, currentPage]);

  const totalPages = Math.ceil(displayedOrders.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="max-w-3xl mx-auto p-4">
      <div className="flex mb-4 border-b">
        <button
          className={`mr-4 font-semibold pb-2 ${
            activeTab === 'ongoing'
              ? 'text-secondary border-b-2 border-secondary'
              : 'text-gray-500'
          }`}
          onClick={() => {
            setActiveTab('ongoing');
            setCurrentPage(1);
          }}
        >
          ONGOING/DELIVERED ({ongoingOrders.length})
        </button>
        <button
          className={`font-semibold pb-2 ${
            activeTab === 'canceled'
              ? 'text-secondary border-b-2 border-secondary'
              : 'text-gray-500'
          }`}
          onClick={() => {
            setActiveTab('cancelled');
            setCurrentPage(1);
          }}
        >
          CANCELlED/RETURNED ({cancelledOrders.length})
        </button>
      </div>
      {currentOrders.map((order, topIndex) => (
        order.items.map((item, index) => (
          <OrderItem 
            key={index} 
            {...item}
            title={item.productName || 'No title'}
            image={images[index]} // Update this with the actual image path if available
            orderNumber={order._id}
            deliveryDate={item.deliveryDate}
            date={order.orderDate}
            status={order.status}
            variation={item.colorName}
            quantity={item.quantity}
            price={item.price}
          />
        ))
      ))}
      {totalPages > 1 && (
        <div className="flex justify-center mt-4">
          <button
            className="mx-1 px-3 py-1 border rounded"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {'<'}
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`mx-1 px-3 py-1 border rounded ${
                currentPage === index + 1 ? 'bg-secondary text-white' : ''
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="mx-1 px-3 py-1 border rounded"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {'>'}
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
