import React, { useState } from 'react';
import { salesRepRegister } from '../../calls/auths';
import Loader from "../../calls/Loader";
import MessageResponse from '../../calls/MessageResponse';

const AddSalesRep = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [apiRes, setApiRes] = useState();
    const [apiMsg, setApiMsg] = useState();

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        salesRepStaffId: '',
        phoneno: '',
        email: '',
        pwd: '',
        c_pwd: '',
        user_type: 'sales_rep'
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log(formData);

        setIsLoading(true);
        const response = await salesRepRegister(formData);
        setApiRes(response);
        setApiMsg(response.data.message || "Sales Rep Created Successfully");
        setIsLoading(false);

        setTimeout(() => {
            setApiRes();
        }, 5000);
    };

    return (
        <div className="p-8">
            <div className="w-full p-8 rounded-lg shadow-lg bg-white border-2 border-slate-300">
                { apiRes && <MessageResponse response={apiRes} msg={apiMsg}/> }
                <form onSubmit={handleSubmit} className="w-full">
                    {isLoading && <Loader />}
                    <div className="flex flex-col sm:flex-row gap-2">
                        <div className="w-full flex flex-col">
                            <label htmlFor="f-name" className="text-sm font-semibold">First Name</label>
                            <input
                                type="text"
                                name="first_name"
                                id="f-name"
                                value={formData.first_name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="w-full flex flex-col">
                            <label htmlFor="l-name" className="text-sm font-semibold">Last Name</label>
                            <input
                                type="text"
                                name="last_name"
                                id="l-name"
                                value={formData.last_name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-2 my-2">
                        <div className="w-full flex flex-col">
                            <label htmlFor="staff_id" className="text-sm font-semibold">Sales Rep Staff ID</label>
                            <input
                                type="text"
                                name="salesRepStaffId"
                                id="staff_id"
                                value={formData.salesRepStaffId}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="w-full flex flex-col">
                            <label htmlFor="phone_no" className="text-sm font-semibold">Phone Number</label>
                            <input
                                type="tel"
                                name="phoneno"
                                id="phone_no"
                                value={formData.phoneno}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-col my-2">
                        <label htmlFor="email" className="text-sm font-semibold">Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="w-full flex flex-col my-2">
                        <label htmlFor="password" className="text-sm font-semibold">Password</label>
                        <input
                            type="password"
                            name="pwd"
                            id="password"
                            value={formData.pwd}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="w-full flex flex-col my-2">
                        <label htmlFor="password" className="text-sm font-semibold">Confirm Password</label>
                        <input
                            type="password"
                            name="c_pwd"
                            id="c_password"
                            value={formData.c_pwd}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button className="w-full bg-tertiary text-white" type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default AddSalesRep;
