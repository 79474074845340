import React, { useEffect, useState } from 'react';
import { BsGear } from 'react-icons/bs';
import { addMiniAmt, getMiniAmt, deleteMiniAmtById } from '../../calls/auths';
import Swal from 'sweetalert2';

const MiniAmtPoint = () => {
  const [amount, setAmount] = useState('');
  const [miniAmt, setMiniAmt] = useState([]);  // Ensure miniAmt is initialized as an array

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("tripstore_login");
    const response = await addMiniAmt({ name: amount }, token);
    if (response.status === 200) {
      getAllMiniAmt(); 
      setAmount('');
      alert('Minimum Amount Created Successfully');
    }
  };

  async function getAllMiniAmt() {
    const token = localStorage.getItem("tripstore_login");
    const response = await getMiniAmt(token);
    if (response && Array.isArray(response.data)) {
      setMiniAmt(response.data);  // Only set miniAmt if response.data is an array
    } else {
      setMiniAmt([]);  // Set miniAmt to an empty array if response.data is not an array
    }
  }

  useEffect(() => {
    getAllMiniAmt();
  }, []);

  async function deleteMiniAmt(id) {
    const token = localStorage.getItem("tripstore_login");
    const response = await deleteMiniAmtById(id, token);
    if (response.status === 200) {
      getAllMiniAmt();
      alert('Deleted Successfully');
    }
  }

  return (
    <>
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg">
          <h1 className="text-center text-2xl font-bold text-black sm:text-3xl">Create Minimum Amount</h1>
          <form onSubmit={handleSubmit} className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8">
            <div>
              <label htmlFor="name" className="sr-only">Set Amount</label>
              <div className="relative">
                <input
                  name="name"
                  type="number"
                  className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                  placeholder="Minimum Loyalty Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                  <BsGear />
                </span>
              </div>
            </div>
            <button
              type="submit"
              className="block w-full rounded-lg bg-black px-5 py-3 text-sm font-medium text-white"
            >
              Create Minimum Amount
            </button>
          </form>
        </div>
      </div>

      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg">
          <h2 className="text-center text-2xl font-bold text-black sm:text-3xl mb-3">Minimum Amounts</h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  #
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created At
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Updated At
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
  {Array.isArray(miniAmt) && miniAmt.length > 0 ? (  // Ensure miniAmt is an array and not empty
    miniAmt.map((miniAmtItem, index) => (
      <tr key={miniAmtItem._id}>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{miniAmtItem.name}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {new Date(miniAmtItem.createdAt).toLocaleDateString()} {new Date(miniAmtItem.createdAt).toLocaleTimeString()}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {new Date(miniAmtItem.updatedAt).toLocaleDateString()} {new Date(miniAmtItem.updatedAt).toLocaleTimeString()}
        </td>

        <td className="whitespace-nowrap px-4 py-2">
          <hr />
          <button
            onClick={() => {
              Swal.fire({
                title: `<strong>Delete "<u>${miniAmtItem.name}</u>" amount?</strong>`,
                icon: "warning",
                html: `This cannot be reversed!`,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: `<i class="fa fa-thumbs-up"></i> Yes`,
                confirmButtonAriaLabel: "Thumbs up, great!",
                cancelButtonText: `<i class="fa fa-thumbs-down"></i> No`,
                cancelButtonAriaLabel: "Thumbs down",
                customClass: {
                  confirmButton: 'bg-red-600 text-white px-4 py-2 rounded-md',
                  cancelButton: 'bg-black text-white px-4 py-2 rounded-md',
                },
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await deleteMiniAmt(miniAmtItem._id);
                }
              });
            }}
            className="inline-block rounded bg-black px-4 py-2 text-xs font-medium text-white hover:bg-black"
          >
            Delete
          </button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="5" className="text-center py-4">No minimum amounts found.</td>
    </tr>
  )}
</tbody>

          </table>
        </div>
      </div>
    </>
  );
};

export default MiniAmtPoint;
