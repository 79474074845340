import React, { useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { AddSize, addSizeTypes, getAllSizes } from '../../calls/auths';

const Sizes = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [sizeType, setSizeType] = useState('');
    const [sizes, setSizes] = useState([]); // State to store fetched sizes data
    const [sizename, setSizename] = useState('');
    const [selectedSize, setSelectedSize] = useState({
        // name: '',
        // _id: '',
        sizes: [] // Initialize as empty array
    });

    const [inputSize, setInputSize] = useState(''); // New state for input size

    async function handleAdd(chosenSize) {
        if(inputSize === '') {
            alert('Input a size');
            return;
        }
        const token = localStorage.getItem("tripstore_login");
        const response = await AddSize({size: inputSize, id: chosenSize._id}, token)
        if(response.status === 200) {
            alert(inputSize+' created successfully.')
            getSizes();
            handleClosePopup(); //close the popup
            setInputSize('');
        }
    }

    const handleAddSizeTypeClick = async () => {
        const token = localStorage.getItem('tripstore_login');
        const response = await addSizeTypes(sizeType, token);
        setSizeType('');
        getSizes();
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    async function getSizes() {
        const token = localStorage.getItem('tripstore_login');
        const response = await getAllSizes(token);
        setSizes(response.data); // Set the fetched data into state
    }

    function showSizes(size) {
        setIsPopupVisible(true);
        setSizename(size.name);
        setSelectedSize(size);
        console.log(size);
    }

    useEffect(() => {
        getSizes();
    }, []);

    return (
        <>
            <div className='p-6'>
                <div className='mb-3'>
                    <label
                        htmlFor="sizetype"
                        className="mb-3 relative block rounded-md border border-gray-200 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                    >
                        <input
                            type="text"
                            id="sizetype"
                            className="peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 h-9"
                            placeholder="sizetype"
                            value={sizeType}
                            onChange={(e) => setSizeType(e.target.value)}
                        />
                        <span
                            className="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                        >
                            Size Type
                        </span>
                    </label>
                    <button
                        onClick={handleAddSizeTypeClick}
                        className="inline-block rounded bg-black px-8 py-3 text-sm font-medium text-white transition hover:rotate-2 hover:scale-110 focus:outline-none focus:ring active:bg-indigo-500"
                    >
                        Add Size Type
                    </button>
                </div>

                {isPopupVisible && (
                    <>
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                            <div className="fixed inset-0 bg-black opacity-50" onClick={handleClosePopup}></div>
                            <div className="bg-white p-6 rounded shadow-lg relative w-1/2">
                                <button
                                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                                    onClick={handleClosePopup}
                                >
                                    <FiX />
                                </button>
                                <div className="relative">
                                    <h1 className='text-primary text-center'>Below are the sizes for {capitalizeFirstLetter(sizename)}</h1>
                                    <label htmlFor="Search" className="sr-only"> Search </label>
                                    <input
                                        type="text"
                                        placeholder={`Input Sizes for ${sizename}`}
                                        className="w-full rounded-md border-gray-200 py-2.5 pe-10 shadow-sm sm:text-sm"
                                        value={inputSize} // Bind state to input value
                                        onChange={(e) => setInputSize(e.target.value)} // Update state on input change
                                    />
                                    <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
                                        <button type="button" className="text-gray-600 hover:text-gray-700">
                                            <span className="sr-only">Search</span>
                                        </button>
                                    </span>
                                    <button className='mt-3 text-white bg-primary' onClick={() => handleAdd(selectedSize)}>add size</button>
                                </div>
                                <div className="overflow-x-auto mt-4 border">
                                    <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                                        <thead className="ltr:text-left rtl:text-right">
                                            <tr>
                                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Name</th>
                                                {/* <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {selectedSize.sizes.map((item, itemIndex) => (
                                                <tr key={itemIndex}>
                                                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">{item.name.toUpperCase()}</td>
                                                    <td className="whitespace-nowrap px-4 py-2">
                                                        {/* <button
                                                            className="inline-block rounded bg-primary px-4 py-2 text-xs font-medium text-white hover:bg-primary"
                                                        >
                                                            EDIT
                                                        </button> */}
                                                        {/* <button
                                                            className="ml-2 inline-block rounded bg-primary px-4 py-2 text-xs font-medium text-white hover:bg-primary"
                                                        >
                                                            DELETE
                                                        </button> */}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm border border-gray-200 rounded-lg">
                        <thead className="ltr:text-left rtl:text-right">
                            <tr>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Size Types</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Sizes</th>
                                <th className="whitespace-nowrap px-4 py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {sizes.map((size) => (
                                <tr key={size._id} title='Click to add Sizes'>
                                    <td className="text-center py-2 font-medium text-gray-900">{capitalizeFirstLetter(size.name)}</td>
                                    <td className="text-center py-2 text-gray-700">
                                        {size.sizes.map(s => s.name.toUpperCase()).join(', ')}
                                    </td>
                                    <td className='text-center'>
                                        <button
                                            onClick={() => showSizes(size)}
                                            title='click to edit'
                                            className="inline-block hover:cursor-pointer rounded bg-primary px-4 py-2 text-xs font-medium text-white hover:bg-primary"
                                        >
                                            EDIT
                                        </button>
                                        <button
                                            title='click to delete'
                                            className="ml-2 inline-block hover:cursor-pointer rounded bg-primary px-4 py-2 text-xs font-medium text-white hover:bg-primary"
                                        >
                                            DELETE
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Sizes;
