import React from 'react'
import ShippingTerms from '../components/Shipping'

const Shipping = () => {
  return (
    <div>
      <ShippingTerms />
    </div>
  )
}

export default Shipping
