import React, { useEffect, useState } from 'react';
import { ClipboardIcon } from '@heroicons/react/outline';
import { getAllZaddyOrders, updateZaddyOrderByAdmin } from '../../calls/auths';
import Swal from 'sweetalert2';

const statuses = ['Unpaid', 'Paid', 'Expired', 'Reactivate'];

const CustomerTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [copySuccess, setCopySuccess] = useState('');
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const itemsPerPage = 10; // Adjust the number of items per page as needed
  const host = window.location.host;

  async function getOrders() {
    const response = await getAllZaddyOrders();
    setOrders(response.data);
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    filterOrders();
  }, [searchTerm, orders]);

  const filterOrders = () => {
    const filtered = orders.length > 0 && orders.filter(order =>
      order.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOrders(filtered);
  };

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopySuccess('Link copied!');
    setTimeout(() => {
      setCopySuccess('');
    }, 2000);
  };

  const handleChange = async (orderId, status) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "bg-green-500 text-white border",
        cancelButton: "bg-red-500 text-white border"
      },
      buttonsStyling: false
    });

    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: `Change status to ${status}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    });

    const token = localStorage.getItem('tripstore_login');

    if (result.isConfirmed) {
      // Call the API to update the status
      const response = await updateZaddyOrderByAdmin({ orderId, status }, token);
      if (response.status === 200) {
        // Update the status in local state
        setOrders(orders.map(order => order._id === orderId ? { ...order, status } : order));
        swalWithBootstrapButtons.fire("Status Updated!", "The status has been changed 🙂", "success");
      } else {
        swalWithBootstrapButtons.fire("Error", "Status update failed. Please try again.", "error");
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire("Cancelled", "Status left unchanged 🙂", "error");
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = filteredOrders.length > 0 && filteredOrders.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  return (
    <div className="p-4">
      {copySuccess && (
        <div className="mb-4 p-2 bg-green-100 text-green-700 rounded">
          {copySuccess}
        </div>
      )}
      <input
        type="text"
        className="mb-4 p-2 border rounded w-full"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="border px-4 py-2">S/N</th>
              <th className="border px-4 py-2">Customer's Name</th>
              <th className="border px-4 py-2">Total Amount</th>
              <th className="border px-4 py-2">Billing Address</th>
              <th className="border px-4 py-2">Created At</th>
              <th className="border px-4 py-2">Shipping Address</th>
              <th className="border px-4 py-2">Shipping Price</th>
              <th className="border px-4 py-2">Shipping Title</th>
              <th className="border px-4 py-2">Updated At</th>
              <th className="border px-4 py-2">Link</th>
              <th className="border px-4 py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentOrders.length > 0 && currentOrders.map((customer, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{startIndex + index + 1}</td>
                <td className="border px-4 py-2">{customer.username}</td>
                <td className="border px-4 py-2">₦{customer.totalAmount.toLocaleString()}</td>
                <td className="border px-4 py-2">{customer.billingAddress}</td>
                <td className="border px-4 py-2">{formatDate(customer.createdAt)}</td>
                <td className="border px-4 py-2">{customer.shippingAddress}</td>
                <td className="border px-4 py-2">₦{customer.shippingPrice.toLocaleString()}</td>
                <td className="border px-4 py-2">{customer.shippingTitle}</td>
                <td className="border px-4 py-2">{formatDate(customer.updatedAt)}</td>
                <td className="border px-4 py-2">
                  <div className="flex items-center">
                    <a href={`https://${host}/zaddypay/${customer._id}`} target="_blank" className="mr-2">{host}/zaddypay/{customer._id}</a>
                    <ClipboardIcon
                      className="h-5 w-5 text-gray-500 cursor-pointer"
                      onClick={() => handleCopy(`https://${host}/zaddypay/${customer._id}`)}
                    />
                  </div>
                </td>
                <td className="border px-4 py-2">
                  <select
                    name="status"
                    value={customer.status}
                    onChange={(e) => handleChange(customer._id, e.target.value)}
                  >
                    {statuses.map((status, index) => (
                      <option key={index} value={status}>{status}</option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-between">
        <button
          className="bg-gray-200 px-4 py-2 rounded"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          className="bg-gray-200 px-4 py-2 rounded"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CustomerTable;
