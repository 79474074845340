import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { fetchPageContent, createOrUpdatePageContent } from '../../calls/auths';

const Pages = () => {
  const [selectedSection, setSelectedSection] = useState('');
  const [content, setContent] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sections = [
    'Terms and Conditions',
    'Support Policy',
    'Privacy Policy',
    'How to Shop',
    'Shipping Policy',
    'Payment Policy',
    'Faqs',
    'Contact Us',
    'About Us',
  ];

  useEffect(() => {
    if (selectedSection) {
      loadContent(selectedSection);
    }
  }, [selectedSection]);

  const loadContent = async (name) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetchPageContent(name);
      if (response.status === 200 && response.data) {
        setContent(response.data.content || '');
      } else {
        setContent('');
        setError('No content available for this section.');
      }
    } catch (error) {
      console.error('Failed to load content:', error);
      setError('Failed to load content.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('tripstore_login');
      const response = await createOrUpdatePageContent(selectedSection, content, token);
      if (response.status === 200 || response.status === 201) {
        alert('Content saved successfully!');
        setIsEditing(false);
      } else {
        setError(`Failed to save content: ${response.data?.message || response.data}`);
        console.error('Failed to save content:', response.data);
      }
    } catch (error) {
      setError('Failed to save content.');
      console.error('Failed to save content:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Page Content Manager</h1>
      <div className="mb-4">
        <label className="mr-2">Select Section:</label>
        <select
          value={selectedSection}
          onChange={(e) => {
            setSelectedSection(e.target.value);
            setIsEditing(false); // Reset editing state when selecting a new section
          }}
          className="p-2 border border-gray-300 rounded"
        >
          <option value="" disabled>Select a section</option>
          {sections.map((section) => (
            <option key={section} value={section}>
              {section}
            </option>
          ))}
        </select>
      </div>
      {isLoading && <p>Loading...</p>}
          {error && <p className="text-red-500">{error}</p>}
      {selectedSection && (
        <div>
          {isEditing ? (
            <div>
              <div className="mb-12">
                <label className="block mb-2">Content:</label>
                <ReactQuill
                  value={content}
                  onChange={setContent}
                  theme="snow"
                  className="h-64"
                />
              </div>
              <div>
                <button
                  onClick={handleSave}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  disabled={isLoading}
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="mb-12">
                <label className="block mb-2">Content Preview:</label>
                <div
                  className="border p-4 h-64 overflow-auto"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
              <button
                onClick={() => setIsEditing(true)}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                Edit
              </button>
            </div>
          )}
          
        </div>
      )}
    </div>
  );
};

export default Pages;
