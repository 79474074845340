import React from 'react';
import PrintableReceipt from '../components/PrintableReceipt';

const order = {
    orderNumber: '123456',
    date: '2023-06-22',
    customerName: 'John Doe',
    customerEmail: 'john.doe@example.com',
    items: [
        { name: 'Product 1', quantity: 2, price: 34000 },
        { name: 'Product 2', quantity: 1, price: 42000 },
        { name: 'Product 3', quantity: 3, price: 29000 },
    ],
    subtotal: 195000,
    tax: 9750,
    total: 204750
};

const Receipt = () => {
    return (
        <div>
            <PrintableReceipt order={order} />
        </div>
    );
};

export default Receipt;
