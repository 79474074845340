import { useContext, useEffect, useState } from "react";
import { HiUpload } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import {
  EditProducts,
  getProduct,
  getCategories,
  getAllSizes,
} from "../../calls/auths";
import Loader from "../../calls/Loader";
import MessageResponse from "../../calls/MessageResponse";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Select from "react-select";
import ImageGalleryPopup from "../../components/ImageGalleryPopup";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const EditProduct = () => {
  const navigate = useNavigate();
  const { updatenewProducts } = useContext(AppContext);
  const { productId } = useParams();
  const [productPic, setProductPic] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiRes, setApiRes] = useState();
  const [apiMsg, setApiMsg] = useState();
  const [inputs, setInputs] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sizeTypes, setSizeTypes] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const [product, setProduct] = useState({
    name: "",
    categories: [],
    gender: "",
    brand: "",
    type: "",
    description: "",
    additionalInformation: "",
    shippingInformation: "",
    sizeType: "",
    sizes: [],
  });
  const handleImageSelect = (images) => {
    let imageData = productPic;
    images.forEach(item => {
      imageData.push(item.url);
    });
    setProductPic(imageData);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(productPic);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setProductPic(items);
  };

  const getToken = () => {
    return localStorage.getItem('tripstore_login'); // Retrieve the token from localStorage
  };

  const options = categories.map((category) => ({
    value: category._id,
    label: category.name,
  }));

  useEffect(() => {
    const selectedValues = selectedCategories.map((option) => option.value);
    setProduct((prevProduct) => ({
      ...prevProduct,
      categories: selectedValues,
    }));
    setInputs((prevInputs) => ({ ...prevInputs, categories: selectedValues }));
  }, [selectedCategories]);

  const sizeOptions = product.sizeType && sizeTypes ? sizeTypes.find((sizeType) => sizeType.name.toLowerCase() === product.sizeType.toLowerCase())?.sizes || [] : [];
      const handleInputChange = (e, selectedOptions) => {
        if (selectedOptions) {
          // Handle Select input change
          const fullCategoryObjects = selectedOptions.map(option => ({
            value: option.value,
            label: option.label
          }));
          setProduct((prevProduct) => ({
            ...prevProduct,
            category: fullCategoryObjects,
          }));
          setInputs((prevInputs) => ({
            ...prevInputs,
            category: fullCategoryObjects,
          }));
          setSelectedCategories(selectedOptions);
        } else if (e) {
          // Handle regular input change
          const { name, value } = e.target;
          setProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
          setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
        }
      };

  const gender = {
    all: "all",
    male: "male",
    female: "female",
    unisex: "unisex",
  };

  const types = {
    cloth: "cloth",
    shoe: "shoe",
    necklace: "necklace",
    wristwatch: "wristwatch",
    bag: "bag",
    bracelet: "bracelet",
  };

  useEffect(() => {
    async function loadProduct() {
      const response = await getProduct(productId);
      const productData = response.data;
  
      const categoryOptions = Array.isArray(productData.category)
        ? productData.category.map((cat) => ({
            value: cat._id,
            label: cat.name,
          }))
        : productData.category
        ? [
            {
              value: productData.category._id,
              label: productData.category.name,
            },
          ]
        : [];
  
      setSelectedCategories(categoryOptions);
  
      setProduct((prevProduct) => ({
        ...prevProduct,
        ...productData,
        category: categoryOptions,
      }));
  
      setInputs((prevInputs) => ({
        ...prevInputs,
        ...productData,
        category: categoryOptions,
      }));

      setProduct({
        ...product,
        name: productData.name || "",
        category: categoryOptions, // Use categoryOptions here
        gender: productData.gender || "",
        brand: productData.brand || "",
        type: productData.type || "",
        description: productData.description || "",
        additionalInformation: productData.additionalInformation || "",
        shippingInformation: productData.shippingInformation || "",
        sizeType: productData.measurement_type || "",
        sizes: Array.isArray(productData.measurements)
          ? productData.measurements.map((measurement) => ({
              _id: measurement._id,
              size: measurement.size,
              costPrice: measurement.costPrice,
              price: measurement.price,
              weight: measurement.weight,
              discount: measurement.discount,
              colors: Array.isArray(measurement.colors)
                ? measurement.colors
                : [],
            }))
          : [],
      });
    
      setInputs({
        ...inputs,
        name: productData.name || "",
        category: categoryOptions, // Use categoryOptions here
        gender: productData.gender || "",
        brand: productData.brand || "",
        type: productData.type || "",
        description: productData.description || "",
        additionalInformation: productData.additionalInformation || "",
        shippingInformation: productData.shippingInformation || "",
      });
      setProductPic(
        Array.isArray(productData.images) ? productData.images : []
      );

      console.log("Updated product state:", product);
      console.log("Updated inputs state:", inputs);
      console.log("Updated selectedCategories:", categoryOptions);
      console.log("Category data received:", productData.category);
      console.log("Processed categoryOptions:", categoryOptions);
    }

    loadProduct();
  }, [productId]);

  useEffect(() => {
    const selectedValues = selectedCategories.map((option) => option.value);
    setProduct((prevProduct) => ({
      ...prevProduct,
      categories: selectedValues,
    }));
    setInputs((prevInputs) => ({ ...prevInputs, categories: selectedValues }));
  }, [selectedCategories]);

  const handleSizeChange = (sizeIndex, colorIndex, e) => {
    const { name, value } = e.target;
    const newSizes = [...product.sizes];
    newSizes[sizeIndex].colors[colorIndex][name] = value;
    setProduct({ ...product, sizes: newSizes });
  };

  const handleSizePriceChange = (sizeIndex, e) => {
    const { name, value } = e.target;
    const newSizes = [...product.sizes];
    newSizes[sizeIndex][name] = value;
    setProduct({ ...product, sizes: newSizes });
  };
  const addSize = () => {
    setProduct({
      ...product,
      sizes: [
        ...product.sizes,
        {
          size: '',
          discount: '',
          costPrice: '',
          price: '',
          weight: '',
          colors: [{ color: '', amount: 0 }],
        },
      ],
    });
  };

  const addColor = (sizeIndex) => {
    const sizes = [...product.sizes];
    const newColor = sizes[sizeIndex].colors[
      sizes[sizeIndex].colors.length - 1
    ].color
      .trim()
      .toLowerCase();
    const existingColors = sizes[sizeIndex].colors.map((color) =>
      color.color.trim().toLowerCase()
    );

    if (existingColors.filter((color) => color === newColor).length > 1) {
      alert("Color already exists. Please enter a different color.");
      return;
    }

    sizes[sizeIndex].colors.push({ color: "", amount: 0 });
    setProduct({ ...product, sizes });
  };

  const removeColor = (sizeIndex, colorIndex) => {
    const newSizes = [...product.sizes];
    newSizes[sizeIndex].colors.splice(colorIndex, 1);
    setProduct({ ...product, sizes: newSizes });
  };

  const removeSize = (index) => {
    const newSizes = [...product.sizes];
    newSizes.splice(index, 1);
    setProduct({ ...product, sizes: newSizes });
  };

  const totalStock = product.sizes.reduce(
    (acc, size) =>
      acc +
      size.colors.reduce(
        (accColor, color) => accColor + parseInt(color.amount),
        0
      ),
    0
  );

  const handleRemoveImage = (index) => {
    const newProductPics = productPic.filter((_, i) => i !== index);
    setProductPic(newProductPics);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

     // Check if categories are selected
  if (!product.category || product.category.length === 0) {
    alert("Please select at least one category.");
    return;
  }

    for (const size of product.sizes) {
      if (size.discount && (size.discount > 100 || size.discount < 0)) {
        alert("Discount must be between 0% and 100%.");
        return;
      }
    }

    setIsLoading(true);

    const token = localStorage.getItem("tripstore_login");

    const enhancedInputs = {
      ...inputs,
      images: productPic,
      measurements: product.sizes,
      category: product.category.map(cat => ({
        _id: cat.value,
        name: cat.label
      })), // Send full category objects
      productId,
    };
  
    console.log("Data being sent to backend:", enhancedInputs);
  

    // Add console logs here
    console.log("Product before submission:", product);
    console.log("Selected categories:", selectedCategories);
    console.log("Enhanced inputs:", enhancedInputs);

    const response = await EditProducts(enhancedInputs, token);
    setApiRes(response);
    setApiMsg(
      response.data.message
        ? response.data.message
        : "Product Edited Successfully"
    );
    setIsLoading(false);

    if (!response.data.message) {
      updatenewProducts(response.data);
    }

    setTimeout(() => {
      setApiRes();
      setApiMsg();
      navigate(`/admin/inventory/detail/${productId}`);
    }, 5000);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const token = getToken();
      if (!token) {
        console.error("No token provided");
        return;
      }

      const result = await getCategories(token);
      console.log("API Result:", result);

      if (result.status === 200) {
        const fetchedCategories = result.data;
        console.log("Categories:", fetchedCategories);
        setCategories(
          Array.isArray(fetchedCategories) ? fetchedCategories : []
        );
      } else {
        console.error("Error fetching category:", result.data);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSizes = async () => {
      const token = localStorage.getItem("tripstore_login");
      const response = await getAllSizes(token);
      if (response.status === 200) {
        setSizeTypes(response.data);
      } else {
        console.error("Failed to fetch sizes:", response.data);
      }
    };
    fetchSizes();
  }, []);

  useEffect(() => {
    console.log("sizeOptions:", sizeOptions);
  }, [sizeOptions]);

  return (
    <>
    <div className="p-4">
      <form
        onSubmit={handleSubmit}
        className="w-full my-4"
        encType="multipart/form-data"
      >
        {isLoading && <Loader className="w-full" />}
        {apiRes && <MessageResponse response={apiRes} msg={apiMsg} />}
        <div className="p-4 md:p-8 bg-white rounded-md shadow border-2 border-slate-300">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="productPic" direction="horizontal">
              {(provided) => (
                <div
                  className="pb-2 flex items-center flex-none gap-4 overflow-x-auto scrollbar small-scrollbar"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {productPic.map((item, index) => (
                    <Draggable key={index} draggableId={`${index}`} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="flex items-center"
                        >
                          <div className="w-[200px]">
                            <div className="w-full aspect-square rounded-md bg-gray-300 shadow-lg overflow-hidden">
                              {item && <img src={item} className="w-full h-full object-contain" alt="Not found" />}
                            </div>
                            <div className="mt-4 flex gap-2">
                              <div onClick={() => handleRemoveImage(index)} className="cursor-pointer rounded-md border-2 border-red-500 px-3 text-red-500 hover:bg-red-500 hover:text-white flex justify-center items-center active:scale-95">
                                <IoClose />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div onClick={() => setIsPopupOpen(true)} className="w-[60px] h-[60px] rounded-full border-2 border-tertiary mx-8 cursor-pointer flex justify-center items-center text-3xl transition hover:rotate-180 hover:bg-tertiary hover:text-white active:scale-95">
                    <HiUpload />
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="p-4 md:p-8 bg-white rounded-md shadow border-2 border-slate-300">
          <div className="flex flex-col md:flex-row gap-4 w-full">
            <div className="flex flex-col w-full">
              <label className="text-sm font-semibold" htmlFor="p-name">
                Product Name
              </label>
              <input
                type="text"
                name="name"
                id="p-name"
                value={product.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="flex flex-col w-full mb-4">
      <label className="text-sm font-semibold" htmlFor="p-category">
        Product Category
      </label>
      <Select
  name="category"
  id="p-category"
  value={selectedCategories}
  onChange={(newSelectedCategories) => handleInputChange(null, newSelectedCategories)}
  options={options}
  isMulti
  classNamePrefix="react-select"
  required
/>
      <div>
        {selectedCategories.map((category, index) => (
          <input
            key={index}
            name="categories"
            type="hidden"
            value={category.value} // Assuming each category has a `value` property that holds the ID
          />
        ))}
      </div>
    </div>
</div>
          <div className="flex flex-col md:flex-row gap-4 w-full">
            <div className="flex flex-col w-full">
              <label className="text-sm font-semibold" htmlFor="p-gender">
                Gender
              </label>
              <select
                name="gender"
                id="p-gender"
                value={product.gender}
                onChange={handleInputChange}
                required
              >
                <option value="">---</option>
                {Object.values(gender).map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col w-full">
              <label className="text-sm font-semibold" htmlFor="p-brand">
                Product Brand
              </label>
              <input
                type="text"
                name="brand"
                id="p-brand"
                value={product.brand}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-4 w-full">
            <div className="flex flex-col w-full">
              <label className="text-sm font-semibold" htmlFor="p-type">
                Product Type
              </label>
              <select
                name="type"
                id="p-type"
                value={product.type}
                onChange={handleInputChange}
              >
                <option value="">---</option>
                {Object.values(types).map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col w-full">
              <label className="text-sm font-semibold" htmlFor="p-description">
                Product Description
              </label>
              <textarea
                name="description"
                id="p-description"
                value={product.description}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            <div className="flex flex-col w-full">
              <label className="text-sm font-semibold" htmlFor="a-info">
                Additional Information
              </label>
              <textarea
                name="additionalInformation"
                id="a-info"
                value={product.additionalInformation}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            <div className="flex flex-col w-full">
              <label className="text-sm font-semibold" htmlFor="s-info">
                Shipping Information
              </label>
              <textarea
                name="shippingInformation"
                id="s-info"
                value={product.shippingInformation}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full mt-4">
            <div className="flex flex-col w-full">
              <label className="text-sm font-semibold" htmlFor="p-sizeType">
                Size Type
              </label>
              <select
                name="sizeType"
                id="p-sizeType"
                value={product.sizeType}
                onChange={handleInputChange}
                required
              >
                <option value="">---</option>
                {Array.isArray(sizeTypes) &&
                  sizeTypes.map((sizeType) => (
                    <option key={sizeType._id} value={sizeType.name}>
                      {sizeType.name}
                    </option>
                  ))}
              </select>
            </div>
            {Array.isArray(product.sizes) &&
              product.sizes.map((size, sizeIndex) => (
                <div key={sizeIndex} className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col md:flex-row gap-4">
                      <div className="flex flex-col w-full">
                        <label
                          className="text-sm font-semibold"
                          htmlFor={`p-size-${sizeIndex}`}
                        >
                          Size
                        </label>
                        <select
                          name={`size-${sizeIndex}`}
                          id={`p-size-${sizeIndex}`}
                          value={size.size}
                          onChange={(e) => {
                            const newSizes = [...product.sizes];
                            newSizes[sizeIndex].size = e.target.value;
                            setProduct({ ...product, sizes: newSizes });
                          }}
                          required
                        >
                          <option value="">Select Size</option>
                          {sizeOptions.map((sizeOption) => (
                            <option
                              key={sizeOption._id}
                              value={sizeOption.name}
                            >
                              {sizeOption.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex flex-col w-full">
                        <label
                          className="text-sm font-semibold"
                          htmlFor={`p-costPrice-${sizeIndex}`}
                        >
                          Cost Price
                        </label>
                        <input
                          type="number"
                          name="costPrice"
                          id={`p-costPrice-${sizeIndex}`}
                          value={size.costPrice}
                          onChange={(e) => handleSizePriceChange(sizeIndex, e)}
                          required
                        />
                      </div>

                      <div className="flex flex-col w-full">
                        <label
                          className="text-sm font-semibold"
                          htmlFor={`p-price-${sizeIndex}`}
                        >
                          Price
                        </label>
                        <input
                          type="number"
                          name="price"
                          id={`p-price-${sizeIndex}`}
                          value={size.price}
                          onChange={(e) => handleSizePriceChange(sizeIndex, e)}
                          required
                        />
                      </div>

                      <div className="flex flex-col w-full">
                        <label
                          className="text-sm font-semibold"
                          htmlFor={`p-discount-${sizeIndex}`}
                        >
                          Discount (%)
                        </label>
                        <input
                          type="number"
                          name="discount"
                          id={`p-discount-${sizeIndex}`}
                          min={0}
                          value={size.discount}
                          onChange={(e) => handleSizePriceChange(sizeIndex, e)}
                        />
                      </div>

                      <div className="flex flex-col w-full">
                        <label
                          className="text-sm font-semibold"
                          htmlFor={`p-weight-${sizeIndex}`}
                        >
                          Weight(KG)
                        </label>
                        <input
                          type="text"
                          name="weight"
                          id={`p-weight-${sizeIndex}`}
                          min={0}
                          value={size.weight}
                          onChange={(e) => handleSizePriceChange(sizeIndex, e)}
                        />
                      </div>
                    </div>

                    {size.colors.map((color, colorIndex) => (
                      <div
                        key={colorIndex}
                        className="grid grid-cols-3 w-full gap-4"
                      >
                        <div className="flex flex-col w-full">
                          <label
                            className="text-sm font-semibold"
                            htmlFor={`p-color-${sizeIndex}-${colorIndex}`}
                          >
                            Color
                          </label>
                          <input
                            className="p-1 h-10 w-full block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                            type="color"
                            name="color"
                            id={`p-color-${sizeIndex}-${colorIndex}`}
                            value={color.color}
                            onChange={(e) =>
                              handleSizeChange(sizeIndex, colorIndex, e)
                            }
                            required
                          />
                        </div>

                        <div className="flex flex-col w-full">
                          <label
                            className="text-sm font-semibold"
                            htmlFor={`p-amount-${sizeIndex}-${colorIndex}`}
                          >
                            Amount
                          </label>
                          <input
                            type="number"
                            name="amount"
                            id={`p-amount-${sizeIndex}-${colorIndex}`}
                            value={color.amount}
                            onChange={(e) =>
                              handleSizeChange(sizeIndex, colorIndex, e)
                            }
                            required
                          />
                        </div>

                        {(colorIndex > 0 || size.colors.length > 1) && (
                          <button
                            type="button"
                            onClick={() => removeColor(sizeIndex, colorIndex)}
                            className="mt-4 px-2 bg-red-700 text-white rounded-md w-full"
                          >
                            Remove Color
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="flex gap-4">
                    <button
                      type="button"
                      onClick={() => addColor(sizeIndex)}
                      className="w-fit mt-2 px-4 py-2 bg-secondary text-white rounded-md"
                    >
                      Add Color
                    </button>
                    {(sizeIndex > 0 || product.sizes.length > 1) && (
                      <button
                        type="button"
                        onClick={() => removeSize(sizeIndex)}
                        className="mt-2 px-4 py-2 bg-red-700 text-white rounded-md"
                      >
                        Remove Size
                      </button>
                    )}
                  </div>
                </div>
              ))}

            <button
              type="button"
              onClick={addSize}
              className="w-fit mt-2 px-4 py-2 bg-secondary text-white rounded-md"
            >
              Add Size
            </button>
          </div>

          <div className="flex justify-between mt-4">
            <p className="font-semibold">Total Stock: {totalStock}</p>
            <button
              type="submit"
              className="bg-tertiary text-white px-4 py-2 rounded-md"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
    <ImageGalleryPopup isOpen={isPopupOpen} onClose={closePopup} onImageSelect={handleImageSelect} multiSelect={true} />
    </>
  );
};

export default EditProduct;