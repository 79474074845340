import React from 'react'
import PrivacyPolicy from '../components/Policy'

const Policy = () => {
  return (
    <div>
      <PrivacyPolicy />
    </div>
  )
}

export default Policy
