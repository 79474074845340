import { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Pagination = ({ totalItems, itemsPerPage, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(1);
    
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return; // Prevent invalid pages
        setCurrentPage(page);
        onPageChange(page); // Call callback to load new data
    };

    const handlePrevPage = () => {
        handlePageChange(currentPage - 1);
    };

    const handleNextPage = () => {
        handlePageChange(currentPage + 1);
    };

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="rounded-b-lg border-t border-gray-200 px-4 py-2">
            <ol className="flex justify-end gap-1 text-xs font-medium">
                <li>
                    <a href="#" onClick={handlePrevPage} className="inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900">
                        <span className="sr-only">Prev Page</span>
                        <FiChevronLeft />
                    </a>
                </li>
                {pageNumbers.map((page) => (
                    <li key={page}>
                        <a
                            href="#"
                            onClick={() => handlePageChange(page)}
                            className={`block size-8 rounded text-center leading-8 ${
                                page === currentPage
                                    ? 'border-black bg-black text-white'
                                    : 'border border-gray-100 bg-white text-gray-900'
                            }`}
                        >
                            {page}
                        </a>
                    </li>
                ))}
                <li>
                    <a
                        href="#"
                        onClick={handleNextPage}
                        className="inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900"
                    >
                        <span className="sr-only">Next Page</span>
                        <FiChevronRight />
                    </a>
                </li>
            </ol>
        </div>
    );
}
export default Pagination;
