import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-modal';
import { AppContext } from "../../context/AppContext";
import { changeUserShippingDetails } from '../../calls/auths';

// Initialize Modal
Modal.setAppElement('#root');

const AccountOverview = () => {
  const { user } = useContext(AppContext);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false);
  const [address, setAddress] = useState({
    addressLine1: '',
    city: '',
    state: '',
    phone: ''
  });

  const [newsletter, setNewsletter] = useState({
    subscribed: true
  });

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const handleNewsletterChange = (e) => {
    const { name, checked } = e.target;
    setNewsletter({ ...newsletter, [name]: checked });
  };

  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    const response = await changeUserShippingDetails(user._id, address);
    if(response.status === 200) {
      window.location.reload();
    } else {
      alert('something went wrong.');
    }
  };

  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    setIsNewsletterModalOpen(false);
    // Add further processing here
  };

  // Update address state when user data is available
  useEffect(() => {
    if (user) {
      setAddress({
        addressLine1: user.address || '',
        city: user.city || '',
        state: user.state || '',
        phone: user.phone_number || ''
      });
    }
  }, [user]);

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-semibold mb-6">Account Overview</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-4 shadow rounded">
          <h2 className="font-semibold border-b pb-2 mb-2">ACCOUNT DETAILS</h2>
          <p>{user ? `${user.firstname} ${user.lastname}`: "User Name"}</p>
          <p>{user ? `${user.email}`: "User Email"}</p>
        </div>
        <div className="bg-white p-4 shadow rounded relative">
          <h2 className="font-semibold border-b pb-2 mb-2">ADDRESS BOOK</h2>
          <p className="mb-2">Your default shipping address:</p>
          <p>{user ? `${user.firstname} ${user.lastname}`: "User Name"}</p>
          <p>{user ? `${user.address}`: "Loading..."}</p>
          <p>{user ? `${user.city}, ${user.state}` : 'Loading...'}</p>
          <p>{address.phone}</p>
          <button
            onClick={() => { console.log('Opening Address Modal'); setIsAddressModalOpen(true); }}
            className="absolute top-2 right-2 text-secondary"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536M9 11v4h4m-2.5-2.5L18.5 3.5a2.121 2.121 0 112.828 2.828L12 15.5H9.5V13z" />
            </svg>
          </button>
        </div>
        <div className="bg-white p-4 shadow rounded">
          <h2 className="font-semibold border-b pb-2 mb-2">TRIPSTORE COUPON</h2>
          <div className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-3.333 0-6 1.333-6 3s2.667 3 6 3 6-1.333 6-3-2.667-3-6-3z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 12c-3.333 0-6 1.333-6 3s2.667 3 6 3 6-1.333 6-3-2.667-3-6-3z" />
            </svg>
            <span>Tripstore Coupon balance: 0</span>
          </div>
        </div>
        <div className="bg-white p-4 shadow rounded relative">
          <h2 className="font-semibold border-b pb-2 mb-2">NEWSLETTER PREFERENCES</h2>
          <p className="mb-2">You are currently subscribed to following newsletters:</p>
          <p>✓ daily newsletters</p>
          <p>✓ weekly newsletters</p>
          <p>✓ weekend newsletters</p>
          <p>✓ monthly newsletters</p>
          <button
            onClick={() => { console.log('Opening Newsletter Modal'); setIsNewsletterModalOpen(true); }}
            className="absolute top-2 right-2 text-secondary"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536M9 11v4h4m-2.5-2.5L18.5 3.5a2.121 2.121 0 112.828 2.828L12 15.5H9.5V13z" />
            </svg>
          </button>
        </div>
      </div>

      <Modal
        isOpen={isAddressModalOpen}
        onRequestClose={() => setIsAddressModalOpen(false)}
        contentLabel="Edit Address"
        className="flex items-center justify-center h-screen"
        overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-50"
      >
        <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
          <h2 className="text-xl font-bold mb-4">Edit Address</h2>
          <form onSubmit={handleAddressSubmit}>
            <div className="mb-4">
              <label className="block mb-1">Address</label>
              <input
                type="text"
                name="addressLine1"
                value={address.addressLine1}
                onChange={handleAddressChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">City</label>
              <input
                type="text"
                name="city"
                value={address.city}
                onChange={handleAddressChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">State</label>
              <input
                type="text"
                name="state"
                value={address.state}
                onChange={handleAddressChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Phone</label>
              <input
                type="number"
                name="phone"
                value={address.phone}
                onChange={handleAddressChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="flex justify-end">
              <button type="button" onClick={() => setIsAddressModalOpen(false)} className="bg-gray-500 text-white px-4 py-2 rounded mr-2">Cancel</button>
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={isNewsletterModalOpen}
        onRequestClose={() => setIsNewsletterModalOpen(false)}
        contentLabel="Edit Newsletter Preferences"
        className="flex items-center justify-center h-screen"
        overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-50"
      >
        <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
          <h2 className="text-xl font-bold mb-4">Edit Newsletter Preferences</h2>
          <form onSubmit={handleNewsletterSubmit}>
            <div className="mb-4">
              <label className="block mb-1">Daily Newsletters</label>
              <input
                type="checkbox"
                name="subscribed"
                onChange={handleNewsletterChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Weekly Newsletters</label>
              <input
                type="checkbox"
                name="subscribed"
                onChange={handleNewsletterChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Weekend Newsletters</label>
              <input
                type="checkbox"
                name="subscribed"
                onChange={handleNewsletterChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Monthly Newsletters</label>
              <input
                type="checkbox"
                name="subscribed"
                onChange={handleNewsletterChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="flex justify-end">
              <button type="button" onClick={() => setIsNewsletterModalOpen(false)} className="bg-gray-500 text-white px-4 py-2 rounded mr-2">Cancel</button>
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AccountOverview;
