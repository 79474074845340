import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import './adminstyle.css'; // Import the CSS file
import { FiSearch } from 'react-icons/fi'; // Import the icon
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { createPoint, deletePoint, getAllPoints, getAllUsers } from '../../calls/auths';
import Pagination from './Pagination';
import MiniAmtPoint from './MiniAmtPoint';

const CustomersLoyalty = () => {
    const [formData, setFormData] = useState({
        amount_from: '',
        amount_to: '',
        pointt: '',
    });

    const [points, setPoints] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPoints, setFilteredPoints] = useState([]);
    const [sliceCount, setSliceCount] = useState({ from: 0, to: 15 });
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage] = useState(10); // Adjust as needed
    const [currentPage, setCurrentPage] = useState(1);
    const [users, setUsers] = useState([]);
    // const totalPages = Math.ceil(totalItems / itemsPerPage);

    const addSlice = () => {
        if (sliceCount.to >= filteredUsers.length) {
          setSliceCount({ from: sliceCount.from, to: sliceCount.to });
        } else {
          setSliceCount({ from: sliceCount.to, to: sliceCount.to + 15 });
          document.querySelector('#table-container').scrollTo(0, 0);
        }
      };
    
      const cutSlice = () => {
        if (sliceCount.from <= 0) {
          setSliceCount({ from: sliceCount.from, to: sliceCount.to });
        } else {
          setSliceCount({ from: sliceCount.from - 15, to: sliceCount.from });
          document.querySelector('#table-container').scrollTo(0, 0);
        }
      };

      useEffect(() => {
        const fetchUsers = async () => {
          try {
            const response = await getAllUsers();
            const fetchedUsers = response.data || [];
            setUsers(Array.isArray(fetchedUsers) ? fetchedUsers : []);
            setFilteredUsers(Array.isArray(fetchedUsers) ? fetchedUsers : []);
          } catch (error) {
            console.error('Error fetching users:', error);
            setUsers([]);
            setFilteredUsers([]);
          }
        };
    
        fetchUsers();
      }, []);

    const fetchPoints = async () => {
        try {
            const response = await getAllPoints();
            if (response.status === 200) {
                const allPoints = response.data; // Assuming this is an array of all points
                setTotalItems(allPoints.length); // Set the total number of items
                const startIndex = (currentPage - 1) * itemsPerPage;
                const paginatedPoints = allPoints.slice(startIndex, startIndex + itemsPerPage);
                setFilteredPoints(paginatedPoints);
            } else {
                console.error("Failed to fetch points:", response.data);
            }
        } catch (error) {
            console.error("Error fetching points:", error);
        }
    };

    useEffect(() => {
        fetchPoints();
    }, [currentPage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formattedData = {
            ...formData,
            pointt: parseInt(formData.pointt),
        };
        const response = await createPoint(formattedData);
        // Optionally, refresh the points list after creating a new one
        getPoints();
        window.location.reload();
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        if (Array.isArray(points)) {
            const results = points.filter(point => {
                const name = point.name ? point.name.toLowerCase() : ''; // Check if point.name is defined
                const pointt = point.pointt ? point.pointt.toString().toLowerCase() : ''; // Convert pointt to string and check if defined
    
                return (
                    name.includes(searchTerm.toLowerCase()) ||
                    pointt.includes(searchTerm.toLowerCase())
                );
            });
            setFilteredPoints(results);
        } else {
            setFilteredPoints([]);
        }
    }, [searchTerm, points]);

    
    
    async function getPoints() {
        const response = await getAllPoints();
        const pointsData = Array.isArray(response.data) ? response.data : [];
        setPoints(pointsData);
        setFilteredPoints(pointsData);
    }
    
    useEffect(() => {
        getPoints();
    }, [])

    useEffect(() => {
        const fetchUsers = async () => {
          try {
            const response = await getAllUsers();
            const fetchedUsers = response.data || [];
            setUsers(Array.isArray(fetchedUsers) ? fetchedUsers : []);
            setFilteredUsers(Array.isArray(fetchedUsers) ? fetchedUsers : []);
          } catch (error) {
            console.error('Error fetching users:', error);
            setUsers([]);
            setFilteredUsers([]);
          }
        };
    
        fetchUsers();
      }, []);
    
    useEffect(() => {
        const filtered = users.filter(user => 
          user.phone_number.toLowerCase().includes(searchTerm.toLowerCase()) || 
          user.total_amount.toLowerCase().includes(searchTerm.toLowerCase()) || 
          user.points.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(filtered);
    setSliceCount({ from: 0, to: 15 });
  }, [searchTerm, users]);
    
    async function deletePointfn(id) {
        const response = await deletePoint({id});
        if(response.status === 200) {
            getPoints();
        }
    }

    return (
        <div className='mt-2 p-2'>
            {/* Create Loyalty Point Module */}
            <Popup trigger={<button className='mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black'> Create Loyalty Point</button>} position="right center" modal>
                <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-lg">
                        <form onSubmit={handleSubmit} className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8 border border-black animated-border bg-white">
                            <p className="text-center text-lg font-medium">Create Loyalty Point</p>

                            <div>
                                <label htmlFor="amount_from" className="sr-only">Amount From</label>
                                <div className="relative">
                                    <input
                                        name='amount_from'
                                        type="text"
                                        className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                        placeholder="Enter Amount Range"
                                        value={formData.amount_from}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="amount_to" className="sr-only">Amount To</label>
                                <div className="relative">
                                    <input
                                        name='amount_to'
                                        type="text"
                                        className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                        placeholder="Enter Amount Range"
                                        value={formData.amount_to}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="pointt" className="sr-only">Point</label>
                                <div className="relative">
                                    <input
                                        name='pointt'
                                        type="number"
                                        className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                        placeholder="Enter Point (in %)"
                                        value={formData.pointt}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="block w-full rounded-lg bg-black px-5 py-3 text-sm font-medium text-white"
                            >
                                Create Point
                            </button>
                        </form>
                    </div>
                </div>
            </Popup>
            <div className="relative mb-3">
                <label htmlFor="Search" className="sr-only"> Search </label>
                <input
                    type="text"
                    id="Search"
                    placeholder="Search for..."
                    className="w-full rounded-md border-gray-200 py-2.5 pe-10 shadow-sm sm:text-sm"
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
                    <button type="button" className="text-gray-600 hover:text-gray-700">
                        <span className="sr-only">Search</span>
                        <FiSearch />
                    </button>
                </span>
            </div>
            <div className="rounded-lg border border-gray-200">
                <div className="overflow-x-auto rounded-t-lg">
                    <table className="w-full divide-y-2 divide-gray-200 bg-white text-sm">
                        <thead className="">
                            <tr>
                                <th className=" py-2 font-medium text-gray-900">Amount from</th>
                                <th className=" py-2 font-medium text-gray-900">Amount To</th>
                                <th className=" py-2 font-medium text-gray-900">Point</th>
                                <th className=" py-2 font-medium text-gray-900">Action</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {filteredPoints.map((point) => (
                                <tr key={point._id}>
                                    <td className="text-center px-6 py-2 font-medium text-gray-900">{point.amount_from}</td>
                                    <td className="text-center px-6 py-2 text-gray-700">{point.amount_to}</td>
                                    <td className="text-center px-6 py-2 text-gray-700">{point.pointt}%</td>
                                    <td className="text-center px-6 py-2 text-gray-700">
                                        <Popup
                                            trigger={() => (
                                            <button className="mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black">ACTION</button>
                                            )}
                                            position="right center"
                                            closeOnDocumentClick
                                        >
                                            <button className='mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black' onClick={() => deletePointfn(point._id)}> DELETE  </button>
                                        </Popup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
            />
            </div>

            {/* Point Limit Generation */}
            <MiniAmtPoint />

            {/* Customers table */}
            <div className="relative mb-3 mt-10">
                <label htmlFor="Search" className="sr-only"> Search </label>
                <input
                    type="text"
                    id="Search"
                    placeholder="Search for..."
                    className="w-full rounded-md border-gray-200 py-2.5 pe-10 shadow-sm sm:text-sm"
                    value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
                <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
                    <button type="button" className="text-gray-600 hover:text-gray-700">
                        <span className="sr-only">Search</span>
                        <IoSearch />
                    </button>
                </span>
            </div>
            <div className="rounded-lg border border-gray-200">
                <div className="overflow-x-auto rounded-t-lg">
                    <table className="w-full divide-y-2 divide-gray-200 bg-white text-sm">
                        <thead className="">
                            <tr>
                                <th className="py-2 font-medium text-gray-900">Phone No</th>
                                <th className="py-2 font-medium text-gray-900">Total Amt</th>
                                <th className=" py-2 font-medium text-gray-900">Total Point</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                (filteredUsers.length > 0) ?
                  <>
                    {
                      filteredUsers.slice(sliceCount.from, sliceCount.to).map((item, index) => (
                        <tr key={index}>
                                    {/* <td className="px-4 py-2 font-medium text-gray-900"></td> */}
                                    <td className="text-center py-2 text-gray-900">{item.phone_number}</td>
                                    <td className="text-center py-2 text-gray-700">#{item.total_amount}</td>
                                    <td className="text-center py-2 text-gray-700">{item.points}%</td>
                                </tr>
                           ))
                        }
                      </>
                      :
                      <tr>
                        <td colSpan={6} className="py-1 px-3 text-center text-gray-500 font-semibold">No user</td>
                      </tr>
                  }
                </tbody>
                    </table>
                </div>
                <div className="h-[10vh] w-[95%] m-auto flex justify-between items-center">
        <button onClick={() => cutSlice()} className="flex items-center gap-4 hover:bg-slate-200">
          <FaAngleLeft /> <span className="hidden md:inline">Previous</span>
        </button>
        <div className={`${(filteredUsers.length <= 0) && "hidden"} text-sm font-semibold text-gray-500`}>
          Showing {1 + sliceCount.from} to {(sliceCount.to >= filteredUsers.length) ? filteredUsers.length : sliceCount.to} of {filteredUsers.length}
        </div>
        <button onClick={() => addSlice()} className="flex items-center gap-4 hover:bg-slate-200">
          <span className="hidden md:inline">Next</span> <FaAngleRight />
        </button>
      </div>
            </div>
        </div>
    );
};

export default CustomersLoyalty;
