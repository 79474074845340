const fetchProductInfo = async (barcode, API_ENDPOINT) => {
  // const response = await fetch(`http://localhost:3002/api/products/barcode/${barcode}`);
  const response = await fetch(`${API_ENDPOINT}/api/products/product-variant/${barcode}`); //returns single product
  const products = await response.json();
  if (products.length === 0) {
    throw new Error('Product not found');
  }
  // return products[0];
  return products;
};

export default fetchProductInfo;
