import React from 'react'
import Top from "../components/top"
import StickyTop from "../components/home/StickyTopHome"
import Subscribe from "../components/Category"
import Footer from "../components/Footer"
import ScrollUpBtn from "../components/ScrollUpBtn"
import LandingPage from '../components/LandingPage'
import { Analytics } from "@vercel/analytics/react"

const Test = () => {
  return (
    <div className=" relative w-full min-h-screen">
            <Analytics/>
            <Top />
            <StickyTop />
            <LandingPage />
            <Subscribe />
            <Footer />
            <ScrollUpBtn />
    </div>
  )
}

export default Test
