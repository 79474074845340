import { useContext, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AppContext } from "../../context/AppContext";

const StickyTop = () => {
    const { offset } = useContext(AppContext);
    const [showCat, setShowCat] = useState(false);

    const toggleShowCat = () => {
        setShowCat(!showCat);
    };

    return (
        <div className={`${offset > 300 ? 'fixed top-0 shadow' : '-top-10'} transition-all duration-500 hidden md:block w-full z-40 bg-tertiary text-white`}>
            <div className="app-container">
                <div className="flex items-center h-[50px]">
                    <div
                        onClick={toggleShowCat}
                        className={`${showCat ? 'bg-primary' : ''} relative transition duration-500 h-full w-[30%] lg:w-[25%] 2xl:w-[15%] group flex items-center hover:bg-primary cursor-pointer`}
                    >
                        <div className="w-full flex items-center justify-between px-4 group-hover:text-white">
                            <div className="h-full flex gap-4 items-center font-semibold">
                                <AiOutlineMenu />
                                <span>Browse Categories</span>
                            </div>
                        </div>
                        <div className={`${showCat ? 'top-[50px] opacity-100 visible' : 'top-0 opacity-0 invisible'} transition-all duration-500 absolute left-0 z-20 w-full shadow-md border-[1px] border-slate-300 bg-white divide-y-[1px] divide-slate-300`}>
                            {Object.values(category).map((item, index) => (
                                <a
                                    key={index}
                                    href={`/search?category=${encodeURIComponent(item !== 'all' ? item : '')}`}
                                    className="capitalize py-2 px-4 truncate block text-sm hover:bg-slate-200 text-gray-700 hover:text-primary"
                                >
                                    {item}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="h-full flex-1 flex items-center">
                        <div className="w-full flex items-center gap-2 justify-end px-4 border-l-[1px] border-slate-300">
                            <HiOutlineLightBulb />
                            <span>Clearance</span>
                            <span className="font-semibold">Up to 30% Off</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StickyTop;

const category = {
    all: 'all',
    casualWear: 'casual wear',
    formalWear: 'formal wear',
    sportswear: 'sportswear',
    outerwear: 'outerwear',
    footwear: 'footwear',
    accessories: 'accessories',
    sleepwear: 'sleepwear',
    underwearLingerie: 'underwear & lingerie',
    ethnicWear: 'ethnic wear',
    swimwear: 'swimwear',
    others: 'others'
};
