import React, { useState } from 'react';

const SizeGuideModal = ({ isOpen, onClose, children, title }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4">
      <div className="bg-white rounded-lg max-w-3xl w-full max-h-[90vh] overflow-auto">
        <div className="sticky top-0 bg-white p-4 border-b flex justify-between items-center">
          <h2 className="text-2xl font-bold">{title}</h2>
          <button onClick={onClose} className="text-2xl">&times;</button>
        </div>
        <div className="p-4">
          {children}
        </div>
      </div>
    </div>
  );
};

const SizeGuide = ({ imperialData, metricData }) => {
  const [unit, setUnit] = useState('imperial');

  return (
    <div>
      <div className="mb-4">
        <label className="mr-2">
          <input
            type="radio"
            value="imperial"
            checked={unit === 'imperial'}
            onChange={() => setUnit('imperial')}
            className="mr-1"
          />
          in.
        </label>
        <label>
          <input
            type="radio"
            value="metric"
            checked={unit === 'metric'}
            onChange={() => setUnit('metric')}
            className="mr-1"
          />
          cm
        </label>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="border p-2">Size</th>
              {Object.keys((unit === 'imperial' ? imperialData : metricData)[0]).slice(1).map((key) => (
                <th key={key} className="border p-2">{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(unit === 'imperial' ? imperialData : metricData).map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                {Object.values(row).map((value, i) => (
                  <td key={i} className="border p-2">{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SizeGuides = ({ category, gender }) => {
  const [isJeansModalOpen, setIsJeansModalOpen] = useState(false);
  const [isShirtModalOpen, setIsShirtModalOpen] = useState(false);
  const [isAdditionalModalOpen, setIsAdditionalModalOpen] = useState(false);
  const [isWomenShoeModalOpen, setIsWomenShoeModalOpen] = useState(false);

  const topCategories = ["Sets", "Shirts", "Tops", "Casual Wears", "Dress", "Jacket"];
  const bottomCategories = ["Bottoms"];
  const footwearCategories = ["Foot Wears"];
  const validGenders = ["male", "female", "unisex"];

  const safeString = (value) => {
    return typeof value === 'string' ? value : '';
  };

  const categoryMatches = (categories) => {
    const safeCategory = safeString(category).toLowerCase(); 
    // Check if category is an object or array of objects
    if (Array.isArray(category)) {
      return category.some(catObj => 
        categories.some(cat => 
          catObj.name.toLowerCase().includes(cat.toLowerCase())
        )
      );
    } else if (typeof category === 'object' && category !== null) {
      return categories.some(cat =>
        category.name?.toLowerCase().includes(cat.toLowerCase())
      );
    } else {
      return categories.some(cat => safeCategory.includes(cat.toLowerCase()));
    }
  };
  

  const genderMatches = (genders) => {
    return genders.includes(safeString(gender).toLowerCase());
  };

  const jeansImperialData = [
    { size: "XS", waist: "24-25", hips: "35-36", usCan: "0/1" },
    { size: "S", waist: "26-27", hips: "37-38", usCan: "3/5" },
    { size: "M", waist: "28-29", hips: "39-40", usCan: "7/9" },
    { size: "L", waist: "30.5-32", hips: "41.5-43", usCan: "11/13" },
    { size: "XL", waist: "33 1/2", hips: "44 1/2", usCan: "15" },
    { size: "1X", waist: "37-38.5", hips: "47-48.5", usCan: "14/16" },
    { size: "2X", waist: "40-42", hips: "50-52", usCan: "18/20" },
    { size: "3X", waist: "44-46", hips: "54-56", usCan: "22/24" },
  ];

  const jeansMetricData = [
    { size: "XS", waist: "61-64", hips: "89-91", uk: "2/4", eu: "32/34", aus: "4/6" },
    { size: "S", waist: "66-69", hips: "94-97", uk: "6/8", eu: "36/38", aus: "8" },
    { size: "M", waist: "71-74", hips: "99-102", uk: "10/12", eu: "40", aus: "10/12" },
    { size: "L", waist: "77-81", hips: "105-109", uk: "14", eu: "42", aus: "14/16" },
    { size: "XL", waist: "85", hips: "113", uk: "16", eu: "44", aus: "18" },
    { size: "1X", waist: "94-98", hips: "119-123", uk: "18", eu: "44/46", aus: "18/20" },
    { size: "2X", waist: "102-107", hips: "127-132", uk: "20/22", eu: "48/50", aus: "22/24" },
    { size: "3X", waist: "112-117", hips: "137-142", uk: "24/26", eu: "52/54", aus: "26/28" },
  ];

  const shirtImperialData = [
    { size: "XS", bust: "32-33", waist: "24-25", hips: "35-36", usCan: "0/1" },
    { size: "S", bust: "34-35", waist: "26-27", hips: "37-38", usCan: "3/5" },
    { size: "M", bust: "36-37", waist: "28-29", hips: "39-40", usCan: "7/9" },
    { size: "L", bust: "38.5-40", waist: "30.5-32", hips: "41.5-43", usCan: "11/13" },
    { size: "XL", bust: "42", waist: "33 1/2", hips: "44 1/2", usCan: "15" },
    { size: "1X", bust: "44-46", waist: "37-38.5", hips: "47-48.5", usCan: "14/16" },
    { size: "2X", bust: "47-49", waist: "40-42", hips: "50-52", usCan: "18/20" },
    { size: "3X", bust: "51-53", waist: "44-46", hips: "54-56", usCan: "22/24" },
  ];

  const shirtMetricData = [
    { size: "XS", bust: "81-84", waist: "61-64", hips: "89-91", uk: "2/4", eu: "32/34", aus: "4/6" },
    { size: "S", bust: "86-89", waist: "66-69", hips: "94-97", uk: "6/8", eu: "36/38", aus: "8" },
    { size: "M", bust: "91-94", waist: "71-74", hips: "99-102", uk: "10/12", eu: "40", aus: "10/12" },
    { size: "L", bust: "98-102", waist: "77-81", hips: "105-109", uk: "14", eu: "42", aus: "14/16" },
    { size: "XL", bust: "105", waist: "85", hips: "113", uk: "16", eu: "44", aus: "18" },
    { size: "1X", bust: "112-116", waist: "94-98", hips: "119-123", uk: "18", eu: "44/46", aus: "18/20" },
    { size: "2X", bust: "119-125", waist: "102-107", hips: "127-132", uk: "20/22", eu: "48/50", aus: "22/24" },
    { size: "3X", bust: "130-135", waist: "112-117", hips: "137-142", uk: "24/26", eu: "52/54", aus: "26/28" },
  ];

  const additionalSizeData = [
    { size: "UK 3", eu: "36", us: "5.5", cm: "22.5" },
    { size: "UK 4", eu: "37", us: "6.5", cm: "23.5" },
    { size: "UK 5", eu: "38", us: "7.5", cm: "24.5" },
    { size: "UK 6", eu: "39", us: "8.5", cm: "25.5" },
    { size: "UK 7", eu: "40", us: "9.5", cm: "26.5" },
    { size: "UK 8", eu: "41", us: "10.5", cm: "27.5" },
  ];

  const womenSizeData = [
    { size: "5", us: "5", uk: "3", eu: "35-36", aus: "5" },
    { size: "5.5", us: "5.5", uk: "3.5", eu: "36", aus: "5.5" },
    { size: "6", us: "6", uk: "4", eu: "36-37", aus: "6" },
    { size: "6.5", us: "6.5", uk: "4.5", eu: "37", aus: "6.5" },
    { size: "7", us: "7", uk: "5", eu: "37-38", aus: "7" },
    { size: "7.5", us: "7.5", uk: "5.5", eu: "38", aus: "7.5" },
    { size: "8", us: "8", uk: "6", eu: "38-39", aus: "8" },
    { size: "8.5", us: "8.5", uk: "6.5", eu: "39", aus: "8.5" },
    { size: "9", us: "9", uk: "7", eu: "39-40", aus: "9" },
    { size: "10", us: "10", uk: "7.5", eu: "40-41", aus: "10" },
    { size: "11", us: "11", uk: "8", eu: "41-42", aus: "11" },
  ];

  const renderSizeGuideButton = () => {

    if (categoryMatches(topCategories) && genderMatches(validGenders)) {
      return (
        <button
          onClick={() => setIsShirtModalOpen(true)}
          className="bg-black text-white px-4 py-2 rounded"
        >
          Top and Shirts Size Guide
        </button>
      );
    } else if (categoryMatches(footwearCategories) && gender.toLowerCase() === "male", "unisex") {
      return (
        <button
          onClick={() => setIsAdditionalModalOpen(true)}
          className="bg-black text-white px-4 py-2 rounded"
        >
          Shoe Size Guide
        </button>
      );
    } else if (categoryMatches(bottomCategories) && genderMatches(validGenders)) {
      return (
        <button
          onClick={() => setIsJeansModalOpen(true)}
          className="bg-black text-white px-4 py-2 rounded"
        >
          Jeans & Bottoms Size Guide
        </button>
      );
    } else if (categoryMatches(footwearCategories) && gender.toLowerCase() === "female") {
      return (
        <button
          onClick={() => setIsWomenShoeModalOpen(true)}
          className="bg-black text-white px-4 py-2 rounded"
        >
          Women Shoe Size Guide
        </button>
      );
    }

    console.log('No matching condition for size guide button');
    return null;
  };

  return (
    <div className="space-y-4">
      {renderSizeGuideButton()}
      
      <SizeGuideModal
        isOpen={isJeansModalOpen}
        onClose={() => setIsJeansModalOpen(false)}
        title="Jeans & Bottoms Size Guide"
      >
        <SizeGuide
          imperialData={jeansImperialData}
          metricData={jeansMetricData}
        />
      </SizeGuideModal>

      <SizeGuideModal
        isOpen={isShirtModalOpen}
        onClose={() => setIsShirtModalOpen(false)}
        title="Shirts Size Guide"
      >
        <SizeGuide
          imperialData={shirtImperialData}
          metricData={shirtMetricData}
        />
      </SizeGuideModal>

      <SizeGuideModal
        isOpen={isAdditionalModalOpen}
        onClose={() => setIsAdditionalModalOpen(false)}
        title="Shoe Size Guide"
      >
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="border p-2">UK Size</th>
                <th className="border p-2">EU Size</th>
                <th className="border p-2">US Size</th>
                <th className="border p-2">CM</th>
              </tr>
            </thead>
            <tbody>
              {additionalSizeData.map((row, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-100" : ""}
                >
                  <td className="border p-2">{row.size}</td>
                  <td className="border p-2">{row.eu}</td>
                  <td className="border p-2">{row.us}</td>
                  <td className="border p-2">{row.cm}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SizeGuideModal>

      <SizeGuideModal
        isOpen={isWomenShoeModalOpen}
        onClose={() => setIsWomenShoeModalOpen(false)}
        title="Women Shoe Size Guide"
      >
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="py-2 px-4 border">Size</th>
                <th className="py-2 px-4 border">US/CAN</th>
                <th className="py-2 px-4 border">UK</th>
                <th className="py-2 px-4 border">EU</th>
                <th className="py-2 px-4 border">AUS</th>
              </tr>
            </thead>
            <tbody>
              {womenSizeData.map((row, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-100" : ""}
                >
                  <td className="py-2 px-4 border">{row.size}</td>
                  <td className="py-2 px-4 border">{row.us}</td>
                  <td className="py-2 px-4 border">{row.uk}</td>
                  <td className="py-2 px-4 border">{row.eu}</td>
                  <td className="py-2 px-4 border">{row.aus}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SizeGuideModal>
    </div>
  );
};
    
export default SizeGuides;