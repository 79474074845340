import Footer from "../components/Footer"
import ScrollUpBtn from "../components/ScrollUpBtn"
import TitleShowcase from "../components/TitleShowcase"
import CartMain from "../components/cart/CartMain"
import Top from "../components/top"

const Cart = () => {

    return (
        <div className=" relative w-full min-h-screen">
            <Top />
            <TitleShowcase title={'Shopping Cart'} />
            <CartMain />
            <Footer />

            <ScrollUpBtn />
        </div>
    )
}

export default Cart