import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import './adminstyle.css'; // Import the CSS file
import { FiSearch } from 'react-icons/fi'; // Import the icon
import { createCoupon, deleteCoupon, getAllCoupons } from '../../calls/auths';
import Pagination from './Pagination';

const Coupon = () => {
    const [formData, setFormData] = useState({
        name: '',
        code: '',
        discount: '',
        startDate: '',
        endDate: '',
    });

    const [coupons, setCoupons] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCoupons, setFilteredCoupons] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage] = useState(10); // Number of items per page
    const [currentPage, setCurrentPage] = useState(1);

    const fetchCoupons = async () => {
        try {
            const response = await getAllCoupons();
            if (response.status === 200) {
                const allCoupons = response.data; // Assuming this is an array of all coupons
                setTotalItems(allCoupons.length); // Set the total number of items
                const startIndex = (currentPage - 1) * itemsPerPage;
                const paginatedCoupons = allCoupons.slice(startIndex, startIndex + itemsPerPage);
                setCoupons(paginatedCoupons);
            } else {
                console.error("Failed to fetch coupons:", response.data);
            }
        } catch (error) {
            console.error("Error fetching coupons:", error);
        }
    };

    useEffect(() => {
        fetchCoupons();
    }, [currentPage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formattedData = {
            ...formData,
            discount: parseInt(formData.discount),
            startDate: new Date(formData.startDate),
            endDate: new Date(formData.endDate),
        };
        const response = await createCoupon(formattedData);
        // Optionally, refresh the coupons list after creating a new one
        getCoupons();
        window.location.reload();
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const results = coupons.filter(coupon =>
            coupon.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            coupon.code.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCoupons(results);
    }, [searchTerm, coupons]);

    async function getCoupons() {
        const response = await getAllCoupons();
        setCoupons(response.data);
        setFilteredCoupons(response.data); // Initialize filteredCoupons with all coupons
    }
    
    useEffect(() => {
        getCoupons();
    }, [])

    async function deleteCouponfn(id) {
        const response = await deleteCoupon({id});
        if(response.status === 200) {
            getCoupons();
        }
    }

    return (
        <div className='mt-2 p-2'>
            <Popup trigger={<button className='mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black'> Create Coupon</button>} position="right center" modal>
                <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-lg">
                        <form onSubmit={handleSubmit} className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8 border border-black animated-border bg-white">
                            <p className="text-center text-lg font-medium">Create Coupon</p>

                            <div>
                                <label htmlFor="coupon_name" className="sr-only">Coupon Name</label>
                                <div className="relative">
                                    <input
                                        name='name'
                                        type="text"
                                        className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                        placeholder="Enter Coupon Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="coupon_code" className="sr-only">Coupon Code</label>
                                <div className="relative">
                                    <input
                                        name='code'
                                        type="text"
                                        className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                        placeholder="Enter Coupon Code"
                                        value={formData.code}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="coupon_discount" className="sr-only">Coupon Discount</label>
                                <div className="relative">
                                    <input
                                        name='discount'
                                        type="number"
                                        className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                        placeholder="Enter Coupon Discount(%)"
                                        value={formData.discount}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="coupon_code" className="sr-only">Coupon Start Date</label>
                                <div className="relative">
                                    <span>Start Date</span>
                                    <input
                                        name='startDate'
                                        type="date"
                                        className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                        value={formData.startDate}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="end_date" className="sr-only">Coupon End Date</label>
                                <div className="relative">
                                    <span>End Date</span>
                                    <input
                                        name='endDate'
                                        type="date"
                                        className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                        value={formData.endDate}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="block w-full rounded-lg bg-black px-5 py-3 text-sm font-medium text-white"
                            >
                                Create Coupon
                            </button>
                        </form>
                    </div>
                </div>
            </Popup>
            <div className="relative mb-3">
                <label htmlFor="Search" className="sr-only"> Search </label>
                <input
                    type="text"
                    id="Search"
                    placeholder="Search for..."
                    className="w-full rounded-md border-gray-200 py-2.5 pe-10 shadow-sm sm:text-sm"
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
                    <button type="button" className="text-gray-600 hover:text-gray-700">
                        <span className="sr-only">Search</span>
                        <FiSearch />
                    </button>
                </span>
            </div>
            <div className="rounded-lg border border-gray-200">
                <div className="overflow-x-auto rounded-t-lg">
                    <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                        <thead className="">
                            <tr>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Name</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Code</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Discount</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Start Date</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">End Date</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Action</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {filteredCoupons.map((coupon) => (
                                <tr key={coupon._id}>
                                    <td className="text-center py-2 font-medium text-gray-900">{coupon.name}</td>
                                    <td className="text-center py-2 text-gray-700">{coupon.code}</td>
                                    <td className="text-center py-2 text-gray-700">{coupon.discount}%</td>
                                    <td className="text-center py-2 text-gray-700">{new Date(coupon.startDate).toLocaleDateString()}</td>
                                    <td className="text-center py-2 text-gray-700">{new Date(coupon.endDate).toLocaleDateString()}</td>
                                    <td className="text-center py-2 text-gray-700">
                                        <Popup
                                            trigger={open => (
                                            <button className="mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black">ACTION</button>
                                            )}
                                            position="right center"
                                            closeOnDocumentClick
                                        >
                                            <button className='mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black' onClick={() => deleteCouponfn(coupon._id)}> DELETE  </button>
                                        </Popup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
            />
            </div>
        </div>
    );
};

export default Coupon;