import React, { useState } from 'react';
import { updateColors } from '../../calls/auths';

const BAckends = () => {
  const [primary, setPrimary] = useState('');
    const [secondary, setSecondary] = useState('');
    const [tertiary, setTertiary] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await updateColors({ primary, secondary, tertiary });

        if (response.status === 200) {
            alert('Colors updated successfully!');
        } else {
            alert('Failed to update colors.');
        }
    };

  return (
    <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Update Colors</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="primary">
                        Primary Color
                    </label>
                    <input
                        type="text"
                        id="primary"
                        value={primary}
                        onChange={(e) => setPrimary(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="secondary">
                        Secondary Color
                    </label>
                    <input
                        type="text"
                        id="secondary"
                        value={secondary}
                        onChange={(e) => setSecondary(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tertiary">
                        Tertiary Color
                    </label>
                    <input
                        type="text"
                        id="tertiary"
                        value={tertiary}
                        onChange={(e) => setTertiary(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Update Colors
                </button>
            </form>
        </div>
  )
}

export default BAckends
