import { useContext, useState, useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { AppContext } from "../../context/AppContext";
import { removeFromCart, handleAddToCart } from "../../calls/auths"; // Import handleAddToCart

const CartMain = () => {
    const { user, cartArray, updateCatrs, productInformation, getCartCount, shipping, currentShipping, setCurrentShipping, changeShipping, setProductInformation } = useContext(AppContext);

    const [reRender, setReRender] = useState(false);

    useEffect(() => {
        localStorage.setItem('selectedShipping', JSON.stringify(currentShipping));
    }, [currentShipping]);

    const calculateSubtotal = () => {
        return cartArray.reduce((total, item, index) => {
            const product = productInformation[index]?.product;
            if (!product) {
                return total;
            }
            const discountedPrice = product.price * (1 - product.discount / 100);
            return total + (item.quantity * discountedPrice);
        }, 0);
    };

    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        return subtotal + currentShipping.shipping.price;
    };

    async function removeFromCartFunction(index) {
        if (user) {
            const userId = user._id;
            const productId = productInformation[index].product.productId;
            const colorId = productInformation[index].product.colorId;
            const response = await removeFromCart({ userId, productId, colorId });
            if (response.status === 200) {
                getCartCount()
            }
        } else {
            const localStorageCart = JSON.parse(localStorage.getItem('cart')) || [];
            if (localStorageCart.length > 0) {
                // Remove the item at the specified index
                localStorageCart.splice(index, 1);
                // Update the local storage with the new array
                localStorage.setItem('cart', JSON.stringify(localStorageCart));
                getCartCount()
            } else {
                getCartCount();
            }
        }
    }

    const updateCart = async (updatedCart) => {
        console.log(updatedCart)
        for (const item of updatedCart) {
            const product = productInformation.find(p => p.productId === item.product);
            if (product) {
                await handleAddToCart(user, item.productId, item.colorId, item.quantity);
            }
        }
    };

    return (
        <div className="container py-4">
            <div className="flex flex-col lg:flex-row items-start gap-4">
                <div className="w-full lg:flex-1 divide-y-[1px] divide-slate-300 border-[1px] border-slate-300 rounded-sm overflow-hidden sm:border-none">
                    {
                        productInformation && productInformation.length > 0 && productInformation.map((item, index) => (
                            <div key={index} className="relative flex w-full flex-col sm:flex-row items-center gap-4 text-sm py-2">
                                <div className="flex-1">
                                    <div className="flex gap-2 items-center">
                                        <div className="w-[60px] aspect-square rounded-sm overflow-hidden">
                                            <img src={item.product.images[0]} className="w-full h-full object-cover" alt="" />
                                        </div>
                                        <div className="flex-1">{item.product.name} - {item.product.color} - {item.product.size}</div>
                                    </div>
                                </div>
                                <div className="">
                                    {item.product.discount ? (
                                        <span className='text-secondary'>
                                            <span style={{ textDecoration: 'line-through' }}>&#8358;{item.product.price.toLocaleString()}</span>
                                            {' '}
                                            &#8358;{(item.product.price * (1 - item.product.discount / 100)).toLocaleString()}
                                            <p className="text-green-500">{item.product.discount}% OFF!</p>
                                        </span>
                                    ) : (
                                        <span className='text-secondary'>&#8358;{item.product.price.toLocaleString()}</span>
                                    )}
                                </div>
                                <div className="">
                                    <div className="flex *:min-w-[40px] border-[1px] border-slate-300 *:flex *:justify-center *:items-center *:p-2">
                                        <div onClick={async () => {
                                            let updatedCart = cartArray ? [...cartArray] : [...productInformation];
                                            if (updatedCart[index].quantity > 1) {
                                                console.log(updatedCart)
                                                updatedCart[index].quantity -= 1;
                                                updateCatrs(updatedCart);
                                                productInformation[index].quantity -= 1;
                                                await updateCart(updatedCart);
                                            }
                                        }} className="select-none font-semibold hover:text-primary cursor-pointer aspect-square">-</div>
                                        <div className="h-full">
                                            {user && cartArray[index] && cartArray[index].quantity ? `${cartArray[index].quantity}` : `${productInformation[index].quantity}`}
                                        </div>
                                        <div onClick={async () => {
                                            let updatedCart = cartArray ? [...cartArray] : [...productInformation];
                                            updatedCart[index].quantity += 1;
                                            updateCatrs(updatedCart);
                                            productInformation[index].quantity += 1;
                                            await updateCart(updatedCart);
                                        }} className="select-none font-semibold hover:text-primary cursor-pointer aspect-square">+</div>
                                    </div>
                                </div>
                                <div className="text-primary font-semibold">
                                    &#8358;
                                    {user && cartArray[index] && cartArray[index].quantity ? `${(cartArray[index].quantity * (item.product.price * (1 - item.product.discount / 100))).toLocaleString()}` : `${(productInformation[index].quantity * (item.product.price * (1 - item.product.discount / 100))).toLocaleString()}`}
                                </div>
                                <button onClick={() => removeFromCartFunction(index)} className="absolute sm:static top-2 right-2 text-lg text-gray-400 hover:text-gray-700 cursor-pointer"><IoCloseOutline /></button>
                            </div>
                        ))
                    }
                </div>
                <div className="w-full lg:w-[300px] rounded-sm border-[1px] border-dashed border-slate-300 bg-slate-100 p-2">
                    <div className="font-semibold">Cart Total</div>
                    <div className="w-full h-[1px] my-2 bg-slate-400"></div>
                    <div className="flex items-center justify-between">
                        <div className="">Subtotal:</div>
                        <div className="">&#8358;{calculateSubtotal().toLocaleString()}</div>
                    </div>
                    <div className="w-full h-[1px] my-4 bg-slate-300"></div>
                    <div className="">Shipping:</div>
                    <div className="my-2">
                        {
                            shipping.map((item, index) => (
                                <div key={index} className="flex items-center justify-between my-2">
                                    <div onClick={() => changeShipping(index)} className="flex items-center gap-2 group cursor-pointer">
                                        <div className={`${(currentShipping.index === index) ? 'border-primary' : 'border-slate-300 group-hover:border-primary'} w-[20px] aspect-square rounded-full border-[1px] flex justify-center items-center`}>
                                            {
                                                (currentShipping.index === index) &&
                                                <div className="w-[10px] aspect-square rounded-full bg-primary"></div>
                                            }
                                        </div>
                                        <div className="capitalize">{item.title}</div>
                                    </div>
                                    <div className="">&#8358;{item.price.toLocaleString()}</div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="w-full h-[1px] my-4 bg-slate-300"></div>
                    <div className="flex items-center justify-between text-primary font-semibold">
                        <div className="">Total:</div>
                        <div className="">&#8358;{calculateTotal().toLocaleString()}</div>
                    </div>
                    <div className="mt-4">
                        <a href="/checkout" className="block py-2 text-sm border-[1px] border-primary text-primary text-center font-semibold hover:bg-primary hover:text-white">PROCEED TO CHECKOUT</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartMain;
