import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { FaArrowUpLong } from "react-icons/fa6";

const ScrollUpBtn = () => {

    const { offset } = useContext(AppContext)

    const scrollUp = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <div onClick={scrollUp} className={`${(offset > 0) ? ' bottom-16 visible opacity-100' : ' bottom-0 invisible opacity-0'} transition-all duration-500 z-[1000000] fixed right-4 w-[60px] h-[60px] flex justify-center items-center bg-secondary/5 rounded text-xl active:scale-95 cursor-pointer hover:bg-secondary/10 text-tertiary/50 hover:text-tertiary`}>
            <FaArrowUpLong />
        </div>
    )
}

export default ScrollUpBtn