import Logo from "../../assets/logo-bg.png"
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { AppContext } from '../../context/AppContext';
import { useContext, useState } from "react";
import { ToastComponent } from "../ToastComponent";

const BaseSticky = ({ setDiscount, outOfStock, colorId, discount, removefromWishListFunction, addToWishListFunction, setIsInWishlist, addToCartFunction, isInWishlist, img = Logo, title = "", price = 0, qty, setQty, product, handleAddToCart, handleAddToWishlist }) => {
    const { user, getCartCount } = useContext(AppContext)
    const [addToCartSuccessfully, setAddToCartSuccessfully] = useState(false);

    const removeQty = () => {
        if (qty > 1) {
            setQty(qty - 1)
        }
    }

    const addQty = () => {
        setQty(qty + 1)
    }

    return (
        <div className=" hidden md:block z-50 fixed bottom-0 w-full py-4 bg-white shadow-[0_0_5px_2px_rgba(0,0,0,0.2)]">
            <div className=" container">
                <div className=" flex items-center gap-4">
                    <div className=" w-[60px] aspect-square border-[1px] border-slate-300 rounded-sm overflow-hidden">
                        <img src={img} className=" w-full h-full object-cover" alt="" />
                    </div>
                    <div className=" flex-1">
                        <div className="">{title}</div>
                    </div>
                    <div className="">
                        <div className=" text-primary font-semibold">
                            {discount ? (
                                <span className='text-secondary'>
                                    <span style={{ textDecoration: 'line-through' }}>&#8358;{price.toLocaleString()}</span>
                                    {' '}
                                    &#8358;{(price - (price * (discount / 100))).toLocaleString()}
                                    <p className="text-green-500">{discount}% OFF!</p>
                                </span>
                            ) : (
                                <span className='text-secondary'>&#8358;{price.toLocaleString()}</span>
                            )}
                        </div>
                    </div>
                    <div className="">
                        <div className=" flex *:min-w-[40px]  border border-secondary border-l-4 border-b-2 *:flex *:justify-center *:items-center *:p-2">
                            <div onClick={removeQty} className="select-none font-semibold hover:text-primary active:text-[12px] cursor-pointer aspect-square hover:border" title="Reduce">-</div>
                            <div className=" h-full">{qty}</div>
                            <div onClick={addQty} className="select-none font-semibold hover:text-primary active:text-[12px] cursor-pointer aspect-square hover:border" title="Add">+</div>
                        </div>
                    </div>
                    <div className="">
                        <button
                            disabled={outOfStock}
                            title={outOfStock ? `Select a color for this product` : 'Add to Cart'}  
                            onClick={() => addToCartFunction()} 
                            className={`border border-secondary border-l-4 border-b-2 active:text-[12px] h-[40px] px-8 flex items-center gap-2 text-primary  hover:border-l-4 hover hover transition cursor-pointer ${outOfStock ? 'cursor-not-allowed opacity-50' : ''}`}>
                            <MdOutlineAddShoppingCart />
                            <span>{colorId ? 'Add to Cart' : 'Select a color' }</span>
                        </button> 
                    </div>
                    {isInWishlist ?
                    <button onClick={() => removefromWishListFunction(user, product)} title="Remove from Wishlist" className="border border-secondary border-l-4 border-b-2 active:text-[12px] text-red-500 cursor-pointer">
                        <FaHeart />
                    </button>
                    :
                    <button onClick={() => addToWishListFunction(user, product)} title="Add to Wishlist" className="border border-secondary border-l-4 border-b-2 active:text-[12px] text-gray-500 cursor-pointer hover:text-red-500">
                        <FaHeart />
                    </button>}
                </div>
            </div>
            {addToCartSuccessfully && <ToastComponent success message={'Added to cart'} onClose={() => setAddToCartSuccessfully(false)} />}
        </div>
    )
}

export default BaseSticky