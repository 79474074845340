import React from 'react'
import PaymentMethod from '../components/Payment'

const Payment = () => {
  return (
    <div>
      <PaymentMethod />
    </div>
  )
}

export default Payment
