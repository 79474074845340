import { useEffect, useState } from "react"
import Description from "./Description"
import AdditionalInfo from "./AdditionalInfo"
import ShippingReturns from "./ShippingReturns"
import Reviews from "./Reviews"

const tabs = {
    description: 'description',
    additionalInfo: 'additional information',
    shippingReturns: 'shipping & returns',
    reviews: 'reviews'
}

const InfoTabs = ({ reviews=[], setReviews, productId, description='lorem', additionalInformation, shippingInformation }) => {

    const [currentTab, setCurrentTab] = useState(tabs.description)
    const [reviewTabIsShowing, setreviewTabIsShowing] = useState(false)

    const changeTab = (tab) => {
        setCurrentTab(tab)
    }

    useEffect(() => {
        if(currentTab === 'reviews') {
            setreviewTabIsShowing(true);
        } else {
            setreviewTabIsShowing(false);
        }
    }, [currentTab]);

    return (
        <div className=" container py-4">
            <div className=" flex flex-wrap justify-center gap-8">
                {
                    Object.values(tabs).map((item, index) => (
                        <div onClick={() => changeTab(item)} key={index} className={`${(currentTab === item) ? ' border-b-2 border-primary text-primary' : ' hover:text-primary hover:border-b-2 hover:border-primary'} cursor-pointer font-semibold px-6 pb-2 text-nowrap capitalize`}>
                            {item} {(item === tabs.reviews) && `(${(reviews || []).filter(item => item.is_approved).length})`}
                        </div>
                    ))
                }
            </div>
            <div className=" relative border-[1px] border-slate-300 rounded-md">
                <div className={`${(currentTab === tabs.description) ? ' visible opacity-100' : ' invisible opacity-0 absolute'} transition-all duration-500 p-4 top-0 w-full`}>
                    <Description description={description} />
                </div>
                <div className={`${(currentTab === tabs.additionalInfo) ? ' visible opacity-100' : ' invisible opacity-0 absolute'} transition-all duration-500 p-4 top-0 w-full`}>
                    <AdditionalInfo additionalInformation={additionalInformation} />
                </div>
                <div className={`${(currentTab === tabs.shippingReturns) ? ' visible opacity-100' : ' invisible opacity-0 absolute'} transition-all duration-500 p-4 top-0 w-full`}>
                    <ShippingReturns shippingInformation={shippingInformation} />
                </div>
                <div className={`${(currentTab === tabs.reviews) ? ' visible opacity-100' : ' invisible opacity-0 absolute'} transition-all duration-500 p-4 top-0 w-full`}>
                    <Reviews productId={productId} reviewTabIsShowing={reviewTabIsShowing} reviews={reviews} setReviews={setReviews} />
                </div>
            </div>
        </div>
    )
}

export default InfoTabs