import React from 'react'
import AboutUs from '../components/About'

const About = () => {
  return (
    <div>
      <AboutUs />
    </div>
  )
}

export default About
