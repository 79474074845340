/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ScrollUpBtn from "../components/ScrollUpBtn";
import CategoryProduct from "../components/CategoryProduct";
import Top from "../components/top";
import Heads from "../components/Heads";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import StickyTop from "../components/home/StickyTopHome";
import { searchResponse } from '../calls/auths';

const Search = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const queryObject = {};
  params.forEach((value, key) => {
    queryObject[key] = value;
  });

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Track current page
  const [hasMore, setHasMore] = useState(true); // Check if there are more products to load


  const getProducts = async (query, page) => {
    setLoading(true);
    try {
      const response = await searchResponse({ ...query, page, limit: 12 }); // Adjust limit as needed
      if (response.status === 200) {
        if (response.data.products.length > 0) {
          setProducts((prev) => [...prev, ...response.data.products]); // Append new products
        } else {
          setHasMore(false); // No more products to load
        }
      } else {
        console.error("Failed to fetch products:", response.data);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setProducts([]); // Reset products when query changes
    setPage(1); // Reset to first page
    setHasMore(true); // Reset hasMore
    getProducts(queryObject, 1);
  }, [location.search]); // Load products when the search query changes

  useEffect(() => {
    if (page > 1) {
      getProducts(queryObject, page); // Load products for the new page
    }
  }, [page]); // Load products when the page changes

  // Load More Button Handler
  const loadMoreProducts = () => {
    if (hasMore) {
      setPage((prev) => prev + 1); // Increment the page to load more products
    }
  };

  return (
    <div className="relative w-full min-h-screen">
      <Top />
      <StickyTop />
      <Heads />
      {loading && page === 1 ? (
        <div className="flex justify-center items-center h-64">
          <span>Loading products...</span>
        </div>
      ) : (
        <>
          <CategoryProduct products={products} />
          {loading && page > 1 && (
            <div className="flex justify-center items-center h-64">
              <span>Loading more products...</span>
            </div>
          )}
          {hasMore && !loading && (
            <div className="flex justify-center my-2">
              <button
                onClick={loadMoreProducts}
                className="px-4 py-2 bg-black text-white rounded"
              >
                Load More
              </button>
            </div>
          )}
        </>
      )}
      <Subscribe />
      <Footer />
      <ScrollUpBtn />
    </div>
  );
};

export default Search;
