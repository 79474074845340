import Footer from "../components/Footer"
import ScrollUpBtn from "../components/ScrollUpBtn"
import TitleShowcase from "../components/TitleShowcase"
import CheckoutMain from "../components/checkout/CheckoutMain"
import Top from "../components/top"

const Checkout = () => {

    return(
        <div className=" relative w-full min-h-screen">
            <Top />
            <TitleShowcase title={'Checkout'} />
            <CheckoutMain />
            <Footer />

            <ScrollUpBtn />
        </div>
    )
}

export default Checkout