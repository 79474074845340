import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select'; // Import Select component
import {
  createProductCategoriesContent,
  getAllProductCategoriesContent,
  deleteProductCategoriesContent,
  updateProductCategoriesContentStatus,
  getCategories, // Make sure this function is correctly imported
} from '../../calls/auths';
import ImageGalleryPopup from '../../components/ImageGalleryPopup';

Modal.setAppElement('#root');

const ProductCategories = () => {
  const [formData, setFormData] = useState({
    title: '',
    offerText: '',
    link: '',
    image: '',
  });

  const [tableData, setTableData] = useState([]);
  const [categories, setCategories] = useState([]); // State to store categories
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [error, setError] = useState(null);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const getToken = () => {
    return localStorage.getItem('tripstore_login'); // Retrieve the token from localStorage
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getAllProductCategoriesContent();
      if (response.status === 200) {
        let newData = Array.isArray(response.data) ? response.data : [response.data];
        if (newData.length === 0) {
          setTableData([]);
          setError('No data available.'); // Set error to display "No data available."
        } else {
          setTableData(newData);
        }
      } else {
        setError('Failed to fetch data. Please try again later.');
        setTableData([]);
      }
    } catch (error) {
      setError('An error occurred while fetching data. Please try again later.');
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };  

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = getToken(); // Ensure you have a function to get the token
        if (!token) {
          console.error('No token provided');
          return;
        }

        const result = await getCategories(token);
        console.log('API Result:', result);

        if (result.status === 200) {
          const fetchedCategories = result.data;
          console.log('Categories:', fetchedCategories);
          setCategories(
            fetchedCategories.map((category) => ({
              value: category._id,
              label: category.name,
            }))
          );
        } else {
          console.error('Error fetching categories:', result.data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleImageSelect = (images) => {
    if (images.length) {
      const imageUrl = images[0].secure_url; // Select the first image URL
      setFormData((prevState) => ({
        ...prevState,
        image: imageUrl,
      }));
    }
    closePopup();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCategoryChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState, 
      title: selectedOption.label,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const link = `/search?category=${encodeURIComponent(formData.link)}`;

    try {
      const response = await createProductCategoriesContent({
        ...formData,
        link, 
      });
      if (response.status === 201) {
        alert('Fashion Category Content created successfully!');
        setFormData({ title: '', offerText: '', link: '', image: '' });
        setIsModalOpen(false);
        setTableData((prevState) => [...prevState, response.data]);
      } else {
        alert('Failed to create Fashion Category Content. Please try again.');
      }
    } catch (error) {
      alert('Failed to create Fashion Category Content. Please try again.');
    }
  };

  const handleDelete = async (index, id) => {
    try {
      const response = await deleteProductCategoriesContent(id);
      if (response.status === 200) {
        setTableData((prevState) => prevState.filter((_, i) => i !== index));
      } else {
        alert('Failed to delete Fashion Category Content. Please try again.');
      }
    } catch (error) {
      alert('Failed to delete Fashion Category Content. Please try again.');
    }
  };

  const handleChangeStatus = async (index, id, currentStatus) => {
    const newStatus = currentStatus === 'Active' ? 'Inactive' : 'Active';
    try {
      const response = await updateProductCategoriesContentStatus(id, newStatus);
      if (response.status === 200) {
        setTableData((prevState) =>
          prevState.map((item, i) =>
            i === index ? { ...item, status: newStatus } : item
          )
        );
      } else {
        alert('Failed to update status. Please try again.');
      }
    } catch (error) {
      alert('Failed to update status. Please try again.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10">
      <button
        onClick={() => setIsModalOpen(true)}
        className="mb-5 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Create Fashion Category Content
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Create Fashion Category Content"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-2xl font-bold mb-5">Landing Page Fashion Categories Content</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
        <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Category Name
            </label>
            <input
            name="title"
              id="title"
              type='text'
              value={formData.title}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="link" className="block text-sm font-medium text-gray-700">
              Category
            </label>
            <Select
              type="text"
              id="link"
              name='link'
              value={categories.find((option) => option.label === formData.link)}
              onChange={handleCategoryChange}
              options={categories}
              required
            />
          </div>
          <div>
            <label htmlFor="offerText" className="block text-sm font-medium text-gray-700">
              Offer Text
            </label>
            <input
              type="text"
              id="offerText"
              name="offerText"
              value={formData.offerText}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="image" className="block text-sm font-medium text-gray-700">
              Image
            </label>
            <input
              type="text"
              id="image"
              name="image"
              value={formData.image}
              onClick={openPopup}
              readOnly
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 cursor-pointer"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Create Fashion Category Content
          </button>
        </form>

        <ImageGalleryPopup
          isOpen={isPopupOpen}
          onClose={closePopup}
          onImageSelect={handleImageSelect}
          multiSelect={false}
        />
      </Modal>

      {isLoading ? (
  <p>Loading...</p>
) : error ? (
  <p className="text-red-500">{error}</p>
) : tableData.length === 0 ? (
  <p>No data available.</p>
) : (
  <table className="min-w-full bg-white border">
    <thead>
      <tr>
        <th className="px-4 py-2">S/N</th>
        <th className="px-4 py-2">Title</th>
        <th className="px-4 py-2">Offer Text</th>
        <th className="px-4 py-2">Link</th>
        <th className="px-4 py-2">Action</th>
      </tr>
    </thead>
    <tbody>
      {tableData
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map((item, index) => (
          <tr key={item._id || index}>
            <td className="border px-4 py-2">{index + 1}</td>
            <td className="border px-4 py-2">{item.title}</td>
            <td className="border px-4 py-2">{item.offerText}</td>
            <td className="border px-4 py-2">{item.link}</td>
            <td className="border px-4 py-2 space-x-2">
              <button
                onClick={() => handleDelete(index, item._id)}
                className="py-1 px-2 bg-red-500 text-white rounded"
              >
                Delete
              </button>
              <button
                onClick={() => handleChangeStatus(index, item._id, item.status)}
                className={`py-1 px-2 ${item.status === 'Active' ? 'bg-green-500' : 'bg-yellow-500'} text-white rounded`}
              >
                {item.status === 'Active' ? 'Deactivate' : 'Activate'}
              </button>
            </td>
          </tr>
        ))}
    </tbody>
  </table>
)}

      {/* ) : (
        <p>No data available.</p>
      )} */}
    </div>
  );
};

export default ProductCategories;
