import React, { useEffect, useState } from 'react';
import { BsGear } from 'react-icons/bs';
import { addCategory, getCategories, deleteCategoryById } from '../../calls/auths';
import Swal from 'sweetalert2';

const AddCategory = () => {
  const [categoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("tripstore_login");
    const response = await addCategory({ name: categoryName }, token);
    if (response.status === 200) {
      getAllCategories(); // Refresh the categories list after adding a new category
      setCategoryName('');
      alert('Category Created Successfully');
    }
  };

  async function getAllCategories() {
    const token = localStorage.getItem("tripstore_login");
    const response = await getCategories(token);
    setCategories(response.data); // Assuming response.data contains the categories
  }

  useEffect(() => {
    getAllCategories();
  }, []);

  async function deleteCategory(id) {
    const token = localStorage.getItem("tripstore_login");
    const response = await deleteCategoryById(id, token);
    if (response.status === 200) {
      getAllCategories(); // Refresh the categories list after deleting a category
      alert('Deleted Successfully');
    }
  }

  return (
    <>
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg">
          <h1 className="text-center text-2xl font-bold text-black sm:text-3xl">Create Category</h1>
          <form onSubmit={handleSubmit} className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8">
            <div>
              <label htmlFor="name" className="sr-only">Name</label>
              <div className="relative">
                <input
                  name="name"
                  type="text"
                  className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                  placeholder="Category Name"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                  <BsGear />
                </span>
              </div>
            </div>
            <button
              type="submit"
              className="block w-full rounded-lg bg-black px-5 py-3 text-sm font-medium text-white"
            >
              Create Category
            </button>
          </form>
        </div>
      </div>

      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg">
          <h2 className="text-center text-2xl font-bold text-black sm:text-3xl mb-3">Categories</h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  #
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created At
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Updated At
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {categories.length > 0 && categories
                .filter(category => category.name !== 'Uncategorized') // Filter out "Uncategorized"
                .map((category, index) => (
                <tr key={category._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{category.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(category.createdAt).toLocaleDateString()} {new Date(category.createdAt).toLocaleTimeString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(category.updatedAt).toLocaleDateString()} {new Date(category.updatedAt).toLocaleTimeString()}
                    </td>

                    <td className="whitespace-nowrap px-4 py-2">
                        {/* <button
                            href="#"
                            className="inline-block rounded bg-black px-4 py-2 text-xs font-medium text-white hover:bg-black"
                        >
                            Edit
                        </button> */}
                        <hr/>
                        <button
                            onClick={() => {
                                Swal.fire({
                                    title: `<strong>Delete "<u>${category.name}</u>" category?</strong>`,
                                    icon: "warning",
                                    html: `This cannot be reversed!`,
                                    showCloseButton: true,
                                    showCancelButton: true,
                                    focusConfirm: false,
                                    confirmButtonText: `<i class="fa fa-thumbs-up"></i> Yes`,
                                    confirmButtonAriaLabel: "Thumbs up, great!",
                                    cancelButtonText: `<i class="fa fa-thumbs-down"></i> No`,
                                    cancelButtonAriaLabel: "Thumbs down",
                                    customClass: {
                                      confirmButton: 'bg-red-600 text-white px-4 py-2 rounded-md',
                                      cancelButton: 'bg-black text-white px-4 py-2 rounded-md'
                                    }
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        // User clicked "Yes"
                                        await deleteCategory(category._id);
                                    }
                                });
                            }}
                            href="#"
                            className="inline-block rounded bg-black px-4 py-2 text-xs font-medium text-white hover:bg-black"
                        >
                            Delete
                        </button>
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
