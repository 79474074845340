import { useEffect, useState } from "react";
import { FaExclamationCircle, FaCheckCircle, FaTimesCircle } from "react-icons/fa";

export function ToastComponent({ onClose, message, warning, success, error }) {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            onClose(); // Call onClose callback after timeout
        }, 7000); // Adjust timeout duration as needed (in milliseconds)

        return () => clearTimeout(timer);
    }, [onClose]);

    if (!isVisible) return null;

    let iconComponent = null;
    if (warning) {
        iconComponent = <FaExclamationCircle className="text-yellow-500 text-2xl" />;
    } else if (success) {
        iconComponent = <FaCheckCircle className="text-green-600 text-2xl" />;
    } else if (error) {
        iconComponent = <FaTimesCircle className="text-red-700 text-2xl" />;
    }

    return (
        <div className="fixed top-4 right-4 p-4 bg-gray-800 text-white rounded-lg flex items-center space-x-3 shadow-lg z-50">
            {iconComponent}
            <span className="font-semibold">{message}</span>
        </div>
    );
}
