const Subscribe = () => {

    return (
        <div className=" bg-tertiary/80 py-6 text-white">
            <div className=" app-container">
                <div className=" flex flex-col md:flex-row items-center justify-center gap-8">
                    <div className="text-center md:text-left">
                        <div className=" text-xl font-bold text-white">Join Our Newsletter</div>
                        <div className=" text-gray-300">Subcribe to get information about products and coupons</div>
                    </div>
                    <div className="">
                        <form action="">
                            <div className=" flex flex-col sm:flex-row gap-2">
                                <input type="email" name="" id="" placeholder="Enter your Email Address" className=" w-[300px] bg-white border-none text-gray-700 rounded" />
                                <button className=" bg-white/30 text-nowrap hover:bg-primary rounded" type="submit">SUBSCRIBE</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscribe