import Footer from "../components/Footer"
import ScrollUpBtn from "../components/ScrollUpBtn"
import TitleShowcase from "../components/TitleShowcase"
import Top from "../components/top"
import WishlistMain from "../components/wishlist/WishlistMain"

const Wishlist = () => {

    return (
        <div className=" relative w-full min-h-screen">
            <Top />
            <TitleShowcase title={'Wishlist'} />
            <WishlistMain />
            <Footer />

            <ScrollUpBtn />
        </div>
    )
}

export default Wishlist