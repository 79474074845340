import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const PausedSales = () => {
  const [pausedSales, setPausedSales] = useState([]);

  useEffect(() => {
    // Fetch paused sales from localStorage on component mount
    const fetchPausedSales = () => {
      const keys = Object.keys(localStorage);
      const sales = keys
        .filter((key) => key.startsWith('pausedSale-'))
        .map((key) => JSON.parse(localStorage.getItem(key)));
      setPausedSales(sales);
    };
    
    fetchPausedSales();
  }, []);

  // Function to remove sale from localStorage and update the list
  const removeSale = (orderNumber) => {
    localStorage.removeItem(`pausedSale-${orderNumber}`);
    setPausedSales((prevSales) => prevSales.filter((sale) => sale.orderNumber !== orderNumber));
  };

  return (
    <div>
      <h2>Paused Sales</h2>
      <ul>
        {pausedSales.map((sale) => (
          <li key={sale.orderNumber} className="flex items-center justify-between p-10">
            <Link to={`/sales_rep/paused-sales/${sale.orderNumber}`} className="text-blue-500">
              {sale.orderNumber}
            </Link>
            <div>
              {/* Button to check out or cancel the sale */}
              <button 
                onClick={() => removeSale(sale.orderNumber)} 
                className="ml-4 bg-red-500 text-white px-2 py-1 rounded"
              >
                Remove
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PausedSales;
