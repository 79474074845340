import React from 'react'
import HowToShop from '../components/Shopping'

const Shopping = () => {
  return (
    <div>
      <HowToShop />
    </div>
  )
}

export default Shopping
