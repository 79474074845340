import React from 'react';

const OrderDetails = () => {
  return (
    <div className="max-w-3xl mx-auto p-4 bg-white">
      <div className="flex items-center mb-4">
        <button className="mr-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </button>
        <h1 className="text-2xl font-bold">Order Details</h1>
      </div>

      <div className="mb-4">
        <p className="font-semibold">Order n° 1329222562</p>
        <p>1 Items</p>
        <p>Placed on 22-07-2023</p>
        <p>Total: ₦ 8,849</p>
      </div>

      <h2 className="text-lg font-semibold mb-2">ITEMS IN YOUR ORDER</h2>

      <div className="border rounded-lg p-4 mb-4">
        <div className="flex justify-between items-start mb-2">
          <div>
            <span className="bg-green-700 text-white text-xs font-bold px-2 py-1 rounded mr-2">DELIVERED</span>
            <span className="bg-secondary text-white text-xs font-bold px-2 py-1 rounded">NON RETURNABLE</span>
          </div>
          <button className="bg-secondary text-white px-4 py-2 rounded">BUY AGAIN</button>
        </div>
        <p>On 01-08-2023</p>
        <div className="flex mt-2">
          <img src="path_to_lint_remover_image.jpg" alt="Lint Remover" className="w-24 h-24 object-cover mr-4" />
          <div>
            <h3 className="font-semibold">Lint Remover 4 Rollers In 1</h3>
            <p>QTY: 1</p>
            <p className="font-semibold">₦ 7,999</p>
          </div>
        </div>
        <button className="text-secondary mt-2">SEE STATUS HISTORY</button>
        <p className="text-sm mt-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          The return period ended on (08-08-2023) Access our <a href="#" className="text-blue-500">Return Policy</a>.
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="border rounded-lg p-4">
          <h2 className="font-semibold mb-2">PAYMENT INFORMATION</h2>
          <h3 className="font-semibold">Payment Method</h3>
          <p>Pay with Cards, Bank Transfer or USSD</p>
          <h3 className="font-semibold mt-2">Payment Details</h3>
          <p>Items total: ₦ 7,999</p>
          <p>Delivery Fees: ₦ 850</p>
          <p className="font-semibold">Total: ₦ 8,849</p>
        </div>
        <div className="border rounded-lg p-4">
          <h2 className="font-semibold mb-2">DELIVERY INFORMATION</h2>
          <h3 className="font-semibold">Delivery Method</h3>
          <p>Door Delivery</p>
          <h3 className="font-semibold mt-2">Shipping Address</h3>
          <p>Mathew Samson</p>
          <p>Gems Communications Ltd, 464 Ado J. G. Muhammad Cres,</p>
          <p>Gwarinpa 900108, Gwarinpa, Federal Capital Territory</p>
          <p>ABUJA-KADO, Federal Capital Territory</p>
          <h3 className="font-semibold mt-2">Shipping Details</h3>
          <p>Door Delivery, Fulfilled by TOP HILL MALL</p>
          <p>Delivery between 28 July and 01 August.</p>
        </div>
      </div>

      <button className="text-secondary mt-4 font-semibold">NEED HELP?</button>
    </div>
  );
};

export default OrderDetails;