import React from 'react';
import './loads.css'; // Assuming styles are in a module file

const Loader = ({ width, right }) => {
  const style = {
    maxWidth: width,
    right: right,
    left: right && 'auto'
  }
  return (
    <div className="overlay" style={style}> {/* Outer container */}
      <div className="loader"> {/* Loader element */}
        <div className="innerLoader" /> {/* Inner element for animation */}
      </div>
    </div>
  );
};

export default Loader;