const ShippingReturns = ({shippingInformation}) => {

    return (
        <div className="">
            <div className=" mb-2">Delivery & returns</div>
            <div className=" text-gray-600 text-sm">{ shippingInformation }</div>
        </div>
    )
}

export default ShippingReturns