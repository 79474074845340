import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Header from "./components/Header";
import AddProduct from "./screens/supervisor/AddProduct";
import AddCategory from "./screens/supervisor/AddCategory";
import Sizes from "./screens/supervisor/Sizes";
import Coupons from "./screens/supervisor/Coupons";
import { AppContext } from "./context/AppContext";
import Settings from "./screens/Settings";
import Backend from "./screens/supervisor/BAckends";
import Ads from "./screens/supervisor/AddStcickyAds";
import ProductCategory from "./screens/supervisor/ProductCategories";
import Inventory from "./screens/supervisor/Inventory";
import {jwtDecode} from "jwt-decode";
import Pages from "./screens/supervisor/Pages";
import SupervisorNavs from "./components/supervisor/SupervisorNavs";

const Supervisor = () => {
    const { setLoggedIn } = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();

    const [headerTitle, setHeaderTitle] = useState('');
    const [expandedNav, setExpandedNav] = useState(false);

    const handleTitleChange = () => {
        switch (location.pathname) {
            case '/supervisor/adverts':
                setHeaderTitle('Advert Promo');
                break;
            case '/supervisor/add_product':
                setHeaderTitle('Add Product');
                break;
            case '/supervisor/add_category':
                setHeaderTitle('Add Category');
                break;
            case '/supervisor/fashion_categories':
                setHeaderTitle('Landing Page Fashion Category');
                break;
            case '/supervisor/sizes':
                setHeaderTitle('Sizes');
                break;
            case '/supervisor/coupons':
                setHeaderTitle('Coupons');
                break;
            case '/supervisor/inventory':
                setHeaderTitle('Inventory');
                break;
            case '/supervisor/settings':
                setHeaderTitle('Settings');
                break;
            case '/supervisor/backends':
                setHeaderTitle('Supervisor Backend');
                break;
            case '/supervisor/pages':
                setHeaderTitle('Supervisor Pages Setup');
                break;
            default:
                if (location.pathname.includes('/supervisor/inventory/detail/')) {
                    setHeaderTitle('Inventory > Detail');
                } else if (location.pathname.includes('/supervisor/inventory/edit/')) {
                    setHeaderTitle('Inventory > Edit');
                } else {
                    setHeaderTitle('Supervisor');
                }
                break;
        }
    };

    useEffect(() => {
        const handleLogin = async () => {
            const loginSession = window.localStorage.getItem("tripstore_login");

            if (loginSession && loginSession !== "") {
                const userType = jwtDecode(loginSession);
                const isUser = userType.user_type === "user";
                const isSalesRep = userType.user_type === "sales_rep";
                const isAdmin = userType.user_type === "admin";
                if (isUser) {
                    Navigate("/user/dashboard");
                } else if (isSalesRep) {
                    navigate("/sales_rep/dashboard");
                } else if (isAdmin) {
                    navigate("/admin/dashboard");
                }
            } else {
                navigate('/login');
            }
        };

        handleLogin();
        handleTitleChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <div className="w-full h-screen md:flex relative">
            <SupervisorNavs expandedNav={expandedNav} setExpandedNav={setExpandedNav} />
            <div onClick={() => setExpandedNav(false)} className={`${expandedNav ? "block" : "hidden"} md:hidden fixed w-screen h-screen z-10`}></div>
            <div className="h-full overflow-y-auto overflow-x-hidden md:flex-1 scrollbar relative">
                <Header setExpandedNav={setExpandedNav} title={headerTitle} setLoggedIn={setLoggedIn} />
                <Routes>
                    <Route element={<AddProduct />} path="/add_product" />
                    <Route element={<AddCategory />} path="/add_category" />
                    <Route element={<ProductCategory />} path="/fashion_categories" />
                    <Route element={<Sizes />} path="/sizes" />
                    <Route element={<Coupons />} path="/coupons" />
                    <Route element={<Ads />} path="/adverts" />
                    <Route element={<Pages />} path="/pages" />
                    <Route element={<Inventory />} path="/inventory/*" />
                    <Route element={<Backend />} path="/backends/*" />
                    <Route element={<Settings userType={'Supervisor'} />} path="/settings" />
                </Routes>
            </div>
        </div>
    );
};

export default Supervisor;
