import Logo from '../assets/ic.png'
import { FaSquareFacebook, FaSquareInstagram, FaCcPaypal, FaCcVisa, FaCcMastercard, FaCcDiscover, FaArrowRightLong } from "react-icons/fa6";

const Footer = () => {
    return (
        <div className=" py-6 border-t-[1px] border-slate-300">
            <div className=" w-[95%] max-w-[1800px] m-auto">
                <div className=" flex justify-between gap-4 flex-wrap">

                    <div className=" w-full md:w-[300px] text-gray-600">
                        <img src={Logo} className=' h-[120px]' alt="tripstore.ng" />
                        <div className=" mt-2 flex gap-4">
                            {/* <div className="">Got Questions? <br /> Call us <a href={`tel:+2349076600058`} className=" block text-primary text-nowrap">+234 907 660 0058</a></div> */}
                            <div className="">
                                <div className="">Payment Method</div>
                                <div className=" flex items-center flex-wrap gap-4 text-2xl">
                                    <a href="#/" className=""><FaCcPaypal /></a>
                                    <a href="#/" className=""><FaCcVisa /></a>
                                    <a href="#/" className=""><FaCcMastercard /></a>
                                    <a href="#/" className=""><FaCcDiscover /></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" w-[200px]">
                        <div className=" text-nowrap text-primary font-semibold text-lg mb-2">Useful Links</div>
                        <div className=" *:text-nowrap flex flex-col items-start *:my-1 hover:*:text-primary text-gray-600">
                            <a href="/about">About Tripstore</a>
                            <a href="/shopping">How to shop on Tripstore</a>
                            <a href="/faq">FAQ</a>
                            <a href="/contact">Contact us</a>
                        </div>
                    </div>

                    <div className=" w-[200px]">
                        <div className=" text-nowrap text-primary font-semibold text-lg mb-2">Customer Service</div>
                        <div className=" *:text-nowrap flex flex-col items-start *:my-1 hover:*:text-primary text-gray-600">
                            <a href="/payment">Payment Methods</a>
                            <a href="/shipping">Shipping</a>
                            <a href="/terms">Terms and conditions</a>
                            <a href="/policy">Privacy Policy</a>
                        </div>
                    </div>

                    <div className=" w-[200px]">
                        <div className=" text-nowrap text-primary font-semibold text-lg mb-2">My Account</div>
                        <div className=" *:text-nowrap flex flex-col items-start *:my-1 hover:*:text-primary text-gray-600">
                            <a href="/login">Sign In</a>
                            <a href="/cart">View Cart</a>
                            <a href="/wishlist">My Wishlist</a>
                            <a href="/dashboard">Track My Order</a>
                            <a href="/support">Help</a>
                        </div>
                    </div>

                    <div className=" w-[300px]">
                        <div className=" text-nowrap text-primary font-semibold text-lg mb-2">Sign Up to Newsletter</div>
                        <div className=" text-gray-600">Subscribe to our newsletter to get latest offers</div>
                        <form action="" className=' w-full flex items-center mt-2 h-[40px]'>
                            <input type="email" name="" id="" placeholder='Enter your Email Address' className=' rounded-l-sm flex-1 rounded-r-none border-[1px] h-full border-tertiary focus:border-primary' />
                            <button className=' bg-tertiary hover:bg-primary text-white rounded-l-none rounded-r-sm h-full aspect-square flex justify-center items-center p-0' type="submit"><FaArrowRightLong /></button>
                        </form>
                    </div>

                </div>

                <div className=" flex flex-wrap justify-between gap-4 mt-8">

                    <div className=" text-gray-600">
                        <div className="">Copyright &#169; {new Date().getFullYear()} Tripstore. All Rights Reserved.</div>
                    </div>

                    <div className=" flex gap-4 items-center *:cursor-pointer text-2xl text-gray-600">
                        <div className=" text-base">Social Media</div>
                        <a href="#/" className=" hover:text-primary"><FaSquareFacebook /></a>
                        <a href="#/" className=" hover:text-primary"><FaSquareInstagram /></a>
                        {/* <a href="#/" className=" hover:text-primary"><FaSquareTiktok /></a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer