import React from 'react';

const Modal = ({ isOpen, onClose, onSave, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-96">
        {children}
        <div className="flex justify-end mt-4">
          <button onClick={onClose} className="mr-2 bg-red-500 text-white px-4 py-2 rounded">Cancel</button>
          <button onClick={onSave} className="bg-green-500 text-white px-4 py-2 rounded">Save</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
