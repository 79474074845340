import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import { IoChevronBack } from "react-icons/io5";
import Modal from "../../Modal";
import { deleteProducts, getProduct } from "../../calls/auths";
import Loader from "../../calls/Loader";
import MessageResponse from "../../calls/MessageResponse";

const ProductDetail = () => {
  const { productId } = useParams();
  const Navigate = useNavigate();

  const printBarcodes = (
    imageUrl,
    sku,
    quantity,
    productName,
    itemPrice,
    itemSize
  ) => {
    const win = window.open();
    const content = `
      <html>
      <head>
          <style>
              body {
                  text-align: center;
              }
              img {
                  margin-bottom: 2px;
                  width: 100%;
                  height: 15mm;
                  margin-top: -20px;
              }
              p {
                  font-size: 20px;
              }
              .barcode-container {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
              }
              .barcode-item {
                  width: 72mm;
                  height: 36mm;
                  box-sizing: border-box;
                  padding: 2px;
                  margin: 2px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
              }
              .barcode-item p {
                  margin: 0;
              }
          </style>
      </head>
      <body>
          <div class="barcode-container">
              <div class="barcode-item flex-col">
                  <img src="${imageUrl}" alt="Barcode" />
                  <p>SKU: ${sku}</p>
                  <p>${productName} (${itemSize})</p>
                  <p>₦${itemPrice.toLocaleString()}</p>
              </div>
          </div>
          <script>
              window.onload = function() {
                  window.print();
              };
          </script>
      </body>
      </html>
    `;
  
    win.document.write(content);
    win.document.close();
  };

  const [product, setProduct] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteProduct = async () => {
    const token = window.localStorage.getItem("tripstore_login");
    const response = await deleteProducts(productId, token);
    console.log(response);
    // delete
    Navigate("/admin/inventory");
  };

  useEffect(() => {
    const fetchProduct = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await getProduct(productId);
        if (response.status !== 200) {
          setError("Unable to fetch Product");
        }
        setProduct(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProduct();
  }, [productId]);

  const getCategoryDisplay = (category) => {
    if (Array.isArray(category)) {
      return category.map((cat) => cat.name).join(", ");
    }
    return category;
  };

  return (
    <>
      {isLoading && <Loader />}
      {error && <MessageResponse msg={error} />}
      {product && (
        <div className=" p-4 sm:p-8">
          <div className=" flex items-center justify-between gap-2 flex-wrap">
            <div
              onClick={() => Navigate(-1)}
              className=" flex items-center gap-2 flex-nowrap *:flex-none text-sm font-semibold text-gray-500 hover:text-tertiary cursor-pointer"
            >
              <IoChevronBack />
              <span>Back</span>
            </div>
            <div className="bg-black text-white rounded-md px-4 py-1.5 hover:bg-green-800">
              <a href="/admin/add_product">Add Product</a>
            </div>
            <div className=" text-xl tracking-wide font-semibold uppercase">
              Product {(product && product.name) ?? ""}
            </div>
          </div>

          <div className="mt-2 rounded-md bg-white p-4 sm:p-8 border-2 border-slate-300 shadow-lg">
            {product.measurements &&
              product.measurements.map((item, index) => (
                <div
                  key={index}
                  className="mt-2 text-sm font-semibold flex items-center flex-wrap gap-2 *:text-nowrap"
                >
                  <div className="flex flex-col text-center">
                    <img
                      src={(item && item.barcode) ?? ""}
                      alt="Barcode"
                      className="w-100 h-100 mr-4"
                    />
                    <p>SKU: {item._id}</p>
                    <p>
                      {product.name} ({item.size})
                    </p>
                    <p>&#8358; {(item.price ?? 0).toLocaleString()}</p>
                  </div>
                  <button
                    onClick={() =>
                      printBarcodes(
                        (item && item.barcode) ?? "",
                        item._id,
                        item.colors.reduce(
                          (total, color) => total + color.amount,
                          0
                        ),
                        product.name,
                        (item.price ?? 0).toLocaleString(),
                        item.size
                      )
                    }
                    className="px-4 py-2 bg-black text-white rounded hover:bg-green-950"
                  >
                    Print
                  </button>
                </div>
              ))}
          </div>

          <div className=" mt-2 rounded-md bg-white p-4 sm:p-8 border-2 border-slate-300 shadow-lg">
            <div className=" pb-2 w-full overflow-x-auto scrollbar small-scrollbar flex gap-2 *:flex-none flex-nowrap">
              {product &&
                product.images &&
                product.images.map((item, index) => (
                  <div
                    key={index}
                    className=" relative w-[200px] h-[200px] rounded overflow-hidden bg-secondary"
                  >
                    <img
                      src={item}
                      className=" w-full h-full object-cover"
                      alt="product img"
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className=" mt-2 rounded-md bg-white p-4 sm:p-8 border-2 border-slate-300 shadow-lg">
            <div className=" text-lg font-semibold">Description</div>
            <div className=" mt-2 text-sm">
              {(product && product.description) ?? ""}
            </div>
            <div className=" text-lg font-semibold">Additional Information</div>
            <div className=" mt-2 text-sm">
              {(product && product.additionalInformation) ?? ""}
            </div>
            <div className=" text-lg font-semibold">Shipping Information</div>
            <div className=" mt-2 text-sm">
              {(product && product.shippingInformation) ?? ""}
            </div>

            <div className=" mt-2 text-sm font-semibold flex items-center flex-wrap gap-2 *:text-nowrap">
              <div className="">Categories:</div>
              <div className=" py-1 px-3 rounded-full bg-slate-300">
                {product && getCategoryDisplay(product.category)}
              </div>
            </div>
          </div>
          <div className=" mt-2 rounded-md bg-white p-4 sm:p-8 border-2 border-slate-300 shadow-lg">
            <div className="w-full pb-2 overflow-x-auto scrollbar small-scrollbar">
              <table className="w-full border-2 border-slate-300 rounded">
                <thead className="bg-slate-300">
                  <tr className="*:p-2 *:text-nowrap">
                    <td>Size</td>
                    <td>Cost Price (&#8358;)</td>
                    <td>Price (&#8358;)</td>
                    <td>Stock</td>
                    <td>Weight (KG)</td>
                  </tr>
                </thead>
                <tbody>
                  {product &&
                    product.measurements &&
                    product.measurements.map((item, index) => (
                      <tr
                        key={index}
                        className="*:p-2 *:text-nowrap even:bg-slate-200"
                      >
                        <td>{item.size}</td>{" "}
                        {/* Display the size for each item */}
                        <td>{(item.costPrice ?? 0).toLocaleString()}</td>
                        <td>{(item.price ?? 0).toLocaleString()}</td>
                        <td>
                          {item.colors.reduce(
                            (total, color) => total + color.amount,
                            0
                            )}
                        </td>
                        <td>{item.weight}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className=" mt-2 font-semibold">
              Total Stock:{" "}
              {product.measurements
                ? product.measurements.reduce(
                    (total, item) =>
                      total +
                      item.colors.reduce(
                        (colorTotal, color) => colorTotal + color.amount,
                        0
                      ),
                    0
                  )
                : 0}
            </div>
          </div>
          <div className=" mt-4 flex justify-between items-center gap-2 *:w-full sm:*:w-[200px] flex-wrap *:flex-none">
            <button
              onClick={() => Navigate(`/admin/inventory/edit/${productId}`)}
              className=" flex items-center justify-center gap-2 flex-nowrap *:flex-none border-2 border-tertiary hover:bg-tertiary hover:text-white"
            >
              <FaEdit />
              <span className=" text-nowrap">Edit Product</span>
            </button>
            <button
              onClick={() => setShowModal(true)}
              className=" flex items-center justify-center gap-2 flex-nowrap *:flex-none border-2 border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
            >
              <FaRegTrashAlt />
              <span className=" text-nowrap">Remove Product</span>
            </button>
          </div>
        </div>
      )}
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          title={"DELETE PRODUCT"}
          info={"Proceed to remove this product from record"}
          onProceed={deleteProduct}
          isProceedModal={true}
        />
      )}
    </>
  );
};

export default ProductDetail;
