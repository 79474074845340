import { useContext, useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { TiThumbsUp, TiThumbsDown } from "react-icons/ti";
import { getReviewsByProduct, submitReview } from "../../calls/auths"; // Import the necessary functions
import { AppContext } from "../../context/AppContext";

const Reviews = ({ reviews, setReviews, productId }) => {
    const { user } = useContext(AppContext)
    const [newReview, setNewReview] = useState({ rating: 0, comment: "" });
    const [submittedReviews, setSubmittedReviews] = useState(reviews);
    const [formData, setFormData] = useState(
        {
            user: null,
            product: productId
        }
    )

    useEffect(() => {
        if(user) {
            setFormData({...formData, user: user._id})
        }
    }, [user])

    const handleRatingClick = (rating) => {
        setNewReview((prev) => ({ ...prev, rating }));
    };

    const handleCommentChange = (e) => {
        setNewReview((prev) => ({ ...prev, comment: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const rating = newReview.rating;
        const comment = newReview.comment;
        const data = { user: user._id, product: productId, rating, comment }
        const token = localStorage.getItem('tripstore_login')
        // Assuming submitReview is a function that sends the new review to the server
        const response = await submitReview(data, token);
        setSubmittedReviews([...submittedReviews, response.data]);
        setNewReview({ rating: 0, comment: "" }); // Reset form
        alert('You comment has been submitted and will display after being approved 🎉')
    };

    return (
        <div className="">
            <div className="mb-2">Reviews ({submittedReviews.filter(item => item.is_approved).length})</div>
            {user ? 
                <form onSubmit={handleSubmit} className="mb-4">
                    <div className="flex items-center gap-1 mb-2">
                        {[...Array(5)].map((_, index) => (
                            <div
                                key={index}
                                className={`cursor-pointer ${index < newReview.rating ? "text-yellow-500" : "text-gray-400"}`}
                                onClick={() => handleRatingClick(index + 1)}
                            >
                                <FaStar />
                            </div>
                        ))}
                    </div>
                    <textarea
                        className="w-full border rounded p-2 mb-2"
                        placeholder="Write your review here..."
                        value={newReview.comment}
                        onChange={handleCommentChange}
                        required
                    />
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                        Submit Review
                    </button>
                </form>
                :
                <a className="text-blue-500 shadow-sm" href="/login">Login to make a review.</a>
            }
            {submittedReviews.length === 0 ? (
                <div className="text-gray-500">No reviews yet.</div>
            ) : (
                <div className="divide-y-[1px] divide-slate-300">
                    {submittedReviews.filter(item => item.is_approved).map((item, index) => (
                        <div key={index} className="flex flex-col sm:flex-row gap-6 py-4">
                            <div className="w-full sm:w-[150px]">
                                <a href="#/" className="block mb-1 truncate hover:text-primary">
                                    {item.user.firstname} {item.user.lastname}
                                </a>
                                <div className="flex gap-1 items-center text-sm">
                                    {[...Array(Math.ceil(item.rating))].map((_, index) => (
                                        <div key={index} className="text-yellow-500">
                                            <FaStar />
                                        </div>
                                    ))}
                                    {[...Array(5 - Math.ceil(item.rating))].map((_, index) => (
                                        <div key={index} className="text-gray-400">
                                            <FaStar />
                                        </div>
                                    ))}
                                </div>
                                <div className="text-sm text-gray-400">Posted {formatDate(item.createdAt)}</div>
                            </div>
                            <div className="flex-1">
                                <div className="">{item.comment}</div>
                                <div className="mt-2 flex items-center gap-4 flex-wrap">
                                    <div className="flex items-center gap-2 flex-nowrap text-xs text-gray-500 cursor-pointer hover:text-primary">
                                        <TiThumbsUp />
                                        <span className="text-nowrap">Helpful ({item.numHelpful})</span>
                                    </div>
                                    <div className="flex items-center gap-2 flex-nowrap text-xs text-gray-500 cursor-pointer hover:text-primary">
                                        <TiThumbsDown />
                                        <span className="text-nowrap">Unhelpful ({item.numUnhelpful})</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Reviews;

// Helper function to format date (replace with your actual date formatting logic)
const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust date formatting as per your requirements
};
