/* eslint-disable no-unused-vars */
import Dp from '../assets/person.jpg';
import { FaEdit, FaRegTrashAlt } from 'react-icons/fa';
import { RiLockPasswordLine } from 'react-icons/ri';
import { IoClose } from 'react-icons/io5';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { updateUser, deleteUser, changePassword } from '../calls/auths';
import { useNavigate } from "react-router-dom";

const Settings = ({ userType }) => {
    const { user } = useContext(AppContext);
    const [showEdit, setShowEdit] = useState(false);
    const [editInputs, setEditInputs] = useState({ first_name: '', last_name: '' });
    const [showChangePwd, setShowChangePwd] = useState(false);
    const [pwdInputs, setPwdInputs] = useState({});
    const [showDel, setShowDel] = useState(false);
    const [delInput, setDelInput] = useState({});
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [tailwindClass, setTailwindClass] = useState('');
    const { setLoggedIn } = useContext(AppContext);

    useEffect(() => {
        if (user) {
            setEditInputs({ first_name: user.firstname, last_name: user.lastname });
        }
    }, [user]);

    const onDelSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await deleteUser(user.email, delInput.pwd);
            if (response.status !== 200) {
                throw new Error(response.data);
            }
            window.localStorage.removeItem("tripstore_login"); //delete the session
            setLoggedIn(false);
            window.location.href = '/'; // Redirect to the homepage
        } catch (error) {
            console.error(error.message);
            // Handle error, e.g., show an error message to the user
        } finally {
            setShowDel(false);
        }
    };

    const onDelInputChange = (e) => {
        const { name, value } = e.target;
        setDelInput({ ...delInput, [name]: value });
    };

    const toggleShowDel = () => {
        setShowDel(!showDel);
    };

    const onPwdChangeSubmit = async (e) => {
        e.preventDefault();
        if(user.social_auth) {
            alert('You login with Google, you dont need a password 😊');
            return;
        }
        if (pwdInputs.new_pwd !== pwdInputs.re_pwd) {
            setError('New password and confirm password do not match.')
            return;
        }
        try {
            const response = await changePassword(user.email, pwdInputs.c_pwd, pwdInputs.new_pwd, pwdInputs.re_pwd);
            if (response.status !== 200) {
                throw new Error(response.data);
            } else {
                setTailwindClass('text-green-500');
                setMessage('Password updated sucessfully!')
            }
        } catch (error) {
            console.error(error.message);
            // Handle error, e.g., show an error message to the user
            setTailwindClass('text-red-500');
            setMessage('The current password inputed was incorrect')
        } finally {
            setShowChangePwd(false);
        }
    };

    const onPwdInputChange = (e) => {
        const { name, value } = e.target;
        setPwdInputs({ ...pwdInputs, [name]: value });
    };

    const toggleShowChangePwd = () => {
        setShowChangePwd(!showChangePwd);
    };

    const onEditChange = (e) => {
        const { name, value } = e.target;
        setEditInputs({ ...editInputs, [name]: value });
    };

    const onEditSubmit = async (e) => {
        e.preventDefault();
        const data = {
            firstname: editInputs.first_name,
            lastname: editInputs.last_name
        };

        const uses = window.localStorage.getItem("tripstore_login");

        await updateUser(uses, user._id, data, '');
        setShowEdit(false);
    };

    const toggleShowEdit = () => {
        setShowEdit(!showEdit);
    };

    return (
        <>
            <div className="p-8">
                <div className="rounded-md shadow-lg bg-white p-4 sm:p-8 border-2 border-slate-300 text-center">
                    <p className={tailwindClass}>{ message }</p>
                    <div className="w-[150px] h-[150px] rounded-full overflow-hidden m-auto">
                        <img src={Dp} className='w-full h-full object-cover' alt="" />
                    </div>
                    <div className="text-lg">{user ? `${user.firstname}` : "Loading..."} | {userType}</div>
                    <div className="font-semibold text-gray-500">{user ? `${user.email}` : "Loading..."}</div>
                    <div className="mt-4 flex justify-center flex-wrap gap-4 *:w-full *:max-w-[200px]">
                        <button onClick={toggleShowEdit} className='flex items-center gap-2 border-2 border-tertiary justify-center flex-nowrap text-sm font-semibold hover:bg-tertiary hover:text-white'><FaEdit /><span className='truncate'>Edit Profile</span></button>
                        <button onClick={toggleShowChangePwd} className='flex items-center gap-2 border-2 border-tertiary justify-center flex-nowrap text-sm font-semibold hover:bg-tertiary hover:text-white'><RiLockPasswordLine /><span className='truncate'>Change Password</span></button>
                        {/* <button onClick={toggleShowDel} className='flex items-center gap-2 border-2 border-tertiary justify-center flex-nowrap text-sm font-semibold hover:bg-red-500 hover:text-white hover:border-red-500'><FaRegTrashAlt /><span className='truncate'>Delete Account</span></button> */}
                    </div>
                </div>
            </div>
            {showEdit &&
                <div className="absolute top-0 left-0 w-full h-full bg-secondary/50 flex justify-center items-center">
                    <div onClick={toggleShowEdit} className="text-3xl absolute top-[12vh] right-4 text-white cursor-pointer hover:text-red-500"><IoClose /></div>
                    <div className="rounded-md shadow-lg bg-white/90 p-4 sm:p-8 w-[95%] max-w-[600px]">
                        <div className="text-xl text-center">Edit Profile</div>
                        <form onSubmit={onEditSubmit} className='w-full'>
                            <div className="flex flex-col my-2">
                                <label htmlFor="f-name" className='text-sm font-semibold'>First Name</label>
                                <input type="text" name="first_name" id="f-name" value={editInputs.first_name ?? ''} onChange={onEditChange} required />
                            </div>
                            <div className="flex flex-col my-2">
                                <label htmlFor="l-name" className='text-sm font-semibold'>Last Name</label>
                                <input type="text" name="last_name" id="l-name" value={editInputs.last_name ?? ''} onChange={onEditChange} required />
                            </div>
                            <button className='mt-4 bg-tertiary text-white w-full' type="submit">Update</button>
                        </form>
                    </div>
                </div>
            }
            {showChangePwd &&
                <div className="absolute top-0 left-0 w-full h-full bg-secondary/50 flex justify-center items-center">
                    <div onClick={toggleShowChangePwd} className="text-3xl absolute top-[12vh] right-4 text-white cursor-pointer hover:text-red-500"><IoClose /></div>
                    <div className="rounded-md shadow-lg bg-white/90 p-4 sm:p-8 w-[95%] max-w-[600px]">
                        <div className="text-xl text-center">Change Password</div>
                        <p className='text-center text-red-500'>{  error }</p>
                        <form onSubmit={onPwdChangeSubmit} className='w-full'>
                            <div className="flex flex-col my-2">
                                <label htmlFor="c-pwd" className='text-sm font-semibold'>Current Password</label>
                                <input type="password" name="c_pwd" id="c-pwd" onChange={onPwdInputChange} required />
                            </div>
                            <div className="flex flex-col my-2">
                                <label htmlFor="new-pwd" className='text-sm font-semibold'>New Password</label>
                                <input type="password" name="new_pwd" id="new-pwd" onChange={onPwdInputChange} required />
                            </div>
                            <div className="flex flex-col my-2">
                                <label htmlFor="re-pwd" className='text-sm font-semibold'>Confirm Password</label>
                                <input type="password" name="re_pwd" id="re-pwd" onChange={onPwdInputChange} required />
                            </div>
                            <button className='mt-4 bg-tertiary text-white w-full' type="submit">Update</button>
                        </form>
                    </div>
                </div>
            }
            {showDel &&
                <div className="absolute top-0 left-0 w-full h-full bg-secondary/50 flex justify-center items-center">
                    <div onClick={toggleShowDel} className="text-3xl absolute top-[12vh] right-4 text-white cursor-pointer hover:text-red-500"><IoClose /></div>
                    <div className="rounded-md shadow-lg bg-white/90 p-4 sm:p-8 w-[95%] max-w-[600px]">
                        <div className="text-xl text-center">Delete Account</div>
                        <div className="text-sm text-center font-semibold">This account will be removed from our record if you proceed</div>
                        <form onSubmit={onDelSubmit} className='w-full'>
                            <div className="flex flex-col my-2">
                                <label htmlFor="pwd" className='text-sm font-semibold'>Password</label>
                                <input type="password" name="pwd" id="pwd" onChange={onDelInputChange} required />
                            </div>
                            <button className='mt-4 bg-tertiary text-white w-full' type="submit">Delete</button>
                        </form>
                    </div>
                </div>
            }
        </>
    );
};

export default Settings;
