/* eslint-disable no-unused-vars */
import axios from "axios";
const paystackKey = 'pk_live_6e62bdad449c3bc9fea2487df68d786ce2bbc892'

 const baseUrl = "https://arcstack-api-production.up.railway.app";

// const baseUrl = `http://localhost:3002`


export function API_ENDPOINT() {
    return baseUrl;
}

export function getPaystackKey() {
    return paystackKey;
}

export function convertProductData(data) {
    const images = [];
  
    for (const key in data) {
      if (key.startsWith("img_")) {
        images.push(data[key]);
        delete data[key];
      }
    }
    data.images = images;
  
    return data;
}

export const stockUpdate = async (token, productId, data) => {
    try {
        const response = await axios.put(`${baseUrl}/api/products/update/color-stock/${productId}`, data, 
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        )
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const googleAuth = async (data) => {
    const { first_name, last_name, pwd, email, user_type, c_pwd, social_auth } = data;

    const passData = { firstname: first_name, lastname: last_name, password: pwd, email, user_type, social_auth: social_auth, confirmpassword: c_pwd }

    try {
        const response = await axios.post(`${baseUrl}/api/auth/googleAuth`, passData);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const userLogin = async (data) => {
    try {
        const passData = {
            email: data.email,
            password: data.pwd
        }
        const response = await axios.post(`${baseUrl}/api/auth/login`, passData);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const updateColors = async (data) => {
    try {
        const colorData = {
            primary: data.primary,
            secondary: data.secondary,
            tertiary: data.tertiary
        };
        const response = await axios.post(`${baseUrl}/api/colors/update-colors`, colorData);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const userRegister = async (data) => {
    const { first_name, last_name, pwd, email, user_type, c_pwd, social_auth } = data;

    const passData = { firstname: first_name, lastname: last_name, password: pwd, email, user_type, social_auth: social_auth, confirmpassword: c_pwd }

    try {
        const response = await axios.post(`${baseUrl}/api/auth/register`, passData);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const adminRegister = async (data) => {
    const { first_name, last_name, pwd, email, user_type, c_pwd } = data;

    const passData = { firstname: first_name, lastname: last_name, password: pwd, email, user_type, confirmpassword: c_pwd }

    try {
        const response = await axios.post(`${baseUrl}/api/auth/admin-register`, passData);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const salesRepRegister = async (data) => {
    const { first_name, last_name, pwd, email, user_type, c_pwd, salesRepStaffId } = data;

    const passData = { firstname: first_name, lastname: last_name, salesRepId: salesRepStaffId, password: pwd, email, user_type, confirmpassword: c_pwd }

    try {
        const response = await axios.post(`${baseUrl}/api/auth/registerSalesRep`, passData);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}


export const getUserInfo = async (token) => {
    try {
        const response = await axios.get(`${baseUrl}/api/auth/user`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}



export const getAllUsers = async () => {
    try {
        const response = await axios.get(`${baseUrl}/users`)
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const deleteProducts = async (data, token) => {
    try {    
        const response = await axios.delete(`${baseUrl}/api/products/deleteProduct/${data}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const updateUser = async (token, id, data, type) => {
    try {
        const response = await axios.put(`${baseUrl}/api/auth/update/${id}?type=${type}`, data, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const fetchOrCreateUser = async (token, phoneNumber) => {
    try {
        const response = await axios.post(`${baseUrl}/api/auth/fetch-or-create`, { phoneNumber }, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const addProducts = async (data, token) => {
    try {
      const {
        images,
        sizeType,
        categories,
        brand,
        type,
        gender,
        description,
        additionalInformation,
        shippingInformation,
        name,
        sizes, // Include sizes containing size, price, and colors
      } = data;
  
      // Map sizes into the format expected by the backend
      const mappedSizes = sizes.map((size) => ({
        size: size.size,
        costPrice: size.costPrice,
        price: size.price,
        discount: size.discount || 0,
        weight: size.weight,
        colors: size.colors.map((color) => ({
            color: color.color || '#000000', // Set default color if not provided
            amount: color.amount,
          })),
        }));
  
      const realData = {
        category: categories,
        gender: gender,
        brand: brand,
        type: type,
        description: description,
        additionalInformation: additionalInformation,
        shippingInformation: shippingInformation,
        name: name,
        measurements: mappedSizes, // Assuming this is handled elsewhere or not needed for this API call
        measurement_type: sizeType,
        images,
        //sizes: mappedSizes, // Include mapped sizes with colors
      };

      const response = await axios.post(`${baseUrl}/api/products/`, realData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      return {
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return {
        data: error.response ? error.response.data : 'Network Error',
        status: error.response ? error.response.status : 500,
      };
    }
  };

  export const EditProducts = async (data, token) => {
    try {
      const {
        productId,
        images,
        sizeType,
        category,
        brand,
        type,
        gender,
        description,
        additionalInformation,
        shippingInformation,
        name,
        measurements, // Include sizes containing size, price, and colors
      } = data;
  
  
      const realData = {
        _id: productId,
        category: category,
        gender: gender,
        brand: brand,
        type: type,
        description: description,
        additionalInformation: additionalInformation,
        shippingInformation: shippingInformation,
        name: name,
        measurements: measurements, // Assuming this is handled elsewhere or not needed for this API call
        measurement_type: sizeType,
        images: images,
      };

      const response = await axios.post(`${baseUrl}/api/products/edit`, realData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      return {
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return {
        data: error.response ? error.response.data : 'Network Error',
        status: error.response ? error.response.status : 500,
      };
    }
  };

  export const getProducts = async (page = 1, limit = 12) => {
    try {
        const response = await axios.get(`${baseUrl}/api/products/`, {
            params: {
                page: page,
                limit: limit,
            }
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const getProduct = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/api/products/${id}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const updateProduct = async (id, updateData) => {
    try {
      const response = await axios.put(`${baseUrl}/api/products/${id}`, updateData);
      return {
        data: response.data,
        status: response.status
      };
    } catch (error) {
      return {
        data: error.response ? error.response.data : 'Network Error',
        status: error.response ? error.response.status : 500
      };
    }
  };   

  // API call to update product stock
export const updateProductStock = async (measurementId, selectedColor, updatedAmount, token) => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/products/updateStock/${measurementId}`, 
        { color: selectedColor, updatedAmount },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response;
    } catch (error) {
      return {
        status: error.response ? error.response.status : 500,
        data: error.response ? error.response.data : 'Network error',
      };
    }
  };  

export const getProductByBarcode = async (barcode) => {
    try {
        const response = await axios.get(`${baseUrl}/api/products/barcode/${barcode}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getImageUploadLink = async (file) => {
    try {
        const response = await axios.post(`${baseUrl}/upload`, file);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const deleteUser = async (email, password) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/auth/delete`, {
            data: {
                email: email, 
                password: password,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return {
            data: response.data,
            status: response.status,
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500,
        };
    }
};

export const changePassword = async (email, password, newPassword, confirmNewPassword) => {
    try {
        const response = await axios.put(`${baseUrl}/api/auth/change-password`, {
            data: {
                email: email, 
                password: password,
                newPassword: newPassword,
                confirmNewPassword: confirmNewPassword,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return {
            data: response.data,
            status: response.status,
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500,
        };
    }
};

export const changePasswordViaAdmin = async (email, password) => {
    try {
        const response = await axios.put(`${baseUrl}/api/auth/admin/change-password`, {
            data: {
                email: email, 
                password: password
            },
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return {
            data: response.data,
            status: response.status,
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500,
        };
    }
};

export const searchResponse = async (query) => {
    try {
      const response = await axios.get(`${baseUrl}/api/products/filter`, {
        params: query
      });
      return {
        data: response.data,
        status: response.status
      };
    } catch (error) {
      return {
        data: error.response ? error.response.data : 'Network Error',
        status: error.response ? error.response.status : 500
      };
    }
};

// Cart CRUD Operations:
export const addToCart = async (data) => {
    try {
        const { user, productId, colorId, quantity } = data;

        const passData = {
            user,
            productId, 
            colorId,
            quantity
        };

        const response = await axios.post(`${baseUrl}/api/cart/add`, passData);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const removeFromCart = async (data) => {
    try {
        const { userId, productId, colorId } = data;

        const passData = {
            userId: userId,
            productId: productId,
            colorId: colorId
        };

        const response = await axios.post(`${baseUrl}/api/cart/remove`, passData);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const getCart = async (userId) => {
    try {
        const response = await axios.get(`${baseUrl}/api/cart/${userId}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

// Reviews CRUD Operations:
export const addReview = async (data) => {
    try {
        const { user, product, rating, comment } = data;

        const passData = {
            user,
            product,
            rating,
            comment
        };

        const response = await axios.post(`${baseUrl}/api/review/add`, passData);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const removeReview = async (data) => {
    try {
        const { user, product } = data;

        const passData = {
            user,
            product
        };

        const response = await axios.post(`${baseUrl}/api/review/remove`, passData);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const getReviewsByProduct = async (productId) => {
    try {
        const response = await axios.get(`${baseUrl}/api/review/${productId}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

//Wishlist CRUD Operations:
export const addToWishlist = async (data) => {
    try {
        const { user, product } = data;

        const passData = {
            user,
            product
        };

        const response = await axios.post(`${baseUrl}/api/wishlist/add`, passData);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const removeFromWishlist = async (data) => {
    try {
        const { user, product } = data;
        const response = await axios.post(`${baseUrl}/api/wishlist/remove`, data);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const getWishlist = async (userId) => {
    try {
        const response = await axios.get(`${baseUrl}/api/wishlist/${userId}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const getWishlistAndProduct = async (userId) => {
    try {
        const response = await axios.get(`${baseUrl}/api/wishlist/${userId}/wishlist-products`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const getCartAndProduct = async (userId) => {
    try {
        const response = await axios.get(`${baseUrl}/api/cart/${userId}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const handleAddToCart = async (user, productId, colorId, qty) => {
    if (user) {
        const data = {
            user: user._id,
            productId: productId,
            colorId: colorId,
            quantity: qty
        };
        try {
            const response = await addToCart(data);
            return response;
        } catch (error) {
            console.error('Failed to add to cart:', "error");
        }
    } else {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const existingProductIndex = cart.findIndex(item => item.colorId === colorId);

        if (existingProductIndex !== -1) {
            // Product exists in the cart, update the quantity
            cart[existingProductIndex].quantity = qty;
        } else {
            // Product does not exist in the cart, add new item
            cart.push({ colorId: colorId, productId: productId, quantity: qty });
        }

        localStorage.setItem('cart', JSON.stringify(cart));
        console.log('Product added to cart in local storage');
    }
};

export const handleAddToWishlist = async (user, product) => {
    if (user) {
        const data = {
            user: user._id,
            product: product._id
        };
        try {
            const response = await addToWishlist(data);
            return response;
        } catch (error) {
            console.error('Failed to add to wishlist:', "error");
        }
    } else {
        const wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
        wishlist.push(product._id);
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
        console.log('Product added to wishlist in local storage');
    }
};

export const getCartItems = async (items) => {
    try {
        const response = await axios.post(`${baseUrl}/api/products/cart/details`, {items});
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const createOrder = async (data) => {
    const { first_name, last_name, street_address, town_city, state, post_zip, phone, email, paymentMethod, couponDiscount, couponName } = data;
    try {
        const response = await axios.post(`${baseUrl}/api/orders`, data);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const fetchUserOrders = async (data) => {
    try {
        const response = await axios.get(`${baseUrl}/api/orders/user-order/${data}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const changeUserShippingDetails = async (userId, address) => {
    try {
        const response = await axios.post(`${baseUrl}/api/auth/changeUserShippingDetails/${userId}/`, address);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const getDashboardStats = async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/orders/dashboard`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const getAllOrders = async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/orders/`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const updateOrderStatusFn = async (data) => {
    try {
        const response = await axios.put(`${baseUrl}/api/orders/update-order-status`, data);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const createZaddyOrder = async (data) => {
    const { first_name, last_name, street_address, town_city, state, post_zip, phone, email, paymentMethod } = data;
    try {
        const response = await axios.post(`${baseUrl}/api/orders/zaddy`, data);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const getZaddyOrder = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/api/orders/zaddies/${id}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const updateZaddyOrder = async (id) => {
    try {
        const response = await axios.put(`${baseUrl}/api/orders/zaddies/${id}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const createSale = async (orderDetails, paymentType, token) => {
    const {
        salesRep,
        items,
        payment,
        discountAmount,
        discountPercentage,
    } = orderDetails;

    // Ensure the payment type and transactionId are included
    const paymentDetails = {
        type: paymentType, // Defaulting to Credit Card as an example
        details: {
            transactionId: payment.transactionNo || 'N/A',
            cardType: payment.cardType || 'N/A',
            first6Digits: payment.first6Digits || 'N/A',
            last4Digits: payment.last4Digits || 'N/A'
        }
    };

    // Ensure each item has the correct fields
    const formattedItems = items.map(item => ({
        product: item.name,
        quantity: item.quantity,
        price: item.price,
        color: item.color,
        size: item.size,
        sku: item.sku,
        status: item.status
    }));

    const finalOrderDetails = {
        salesRep,
        items: formattedItems,
        payment: paymentDetails,
        discountAmount,
        discountPercentage
    };

    try {
        const response = await axios.post(`${baseUrl}/api/sales/`, finalOrderDetails, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const getAllSales = async (token) => {
    try {
        const response = await axios.get(`${baseUrl}/api/sales/`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const getSaleByOrderNumber = async (orderNumber, token) => {
    try {
        const response = await axios.get(`${baseUrl}/api/sales/order/${orderNumber}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
}

export const updateProductStatus = async (token, orderNumber, sku, status) => {
    try {
        const response = await axios.put(`${baseUrl}/api/sales/${orderNumber}/items`, { sku, status }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getSalesSummary = async (token) => {
    try {
        const response = await axios.get(`${baseUrl}/api/sales/dashboard`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized. Please log in again.');
        }
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getSalesCount = async (token) => {
    try {
      const response = await axios.get(`${baseUrl}/api/sales/count`, {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      });
      return {
        data: response.data,
        status: response.status
      };
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error('Unauthorized. Please log in again.');
      }
      return {
        data: error.response ? error.response.data : 'Network Error',
        status: error.response ? error.response.status : 500
      };
    }
  };

export const createStickyTopContent = async (data) => {
    try {
        const response = await axios.post(`${baseUrl}/api/sticky-top-content/create`, data);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        console.error('Error in createStickyTopContent:', "error");
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getRandomStickyTopContent = async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/sticky-top-content/random`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getAllStickyTopContent = async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/sticky-top-content`);
        return response;
    } catch (error) {
        console.error('Error fetching all sticky top content:', "error");
        throw error;
    }
};

export const deleteStickyTopContent = async (id) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/sticky-top-content/${id}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        console.error('Error in deleteStickyTopContent:', "error");
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const updateStickyTopContentStatus = async (id, status) => {
    try {
        const response = await axios.patch(`${baseUrl}/api/sticky-top-content/${id}/status`, { status });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        console.error('Error in updateStickyTopContentStatus:', "error");
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getUserReview = async (userId) => {
    try {
        const response = await axios.get(`${baseUrl}/api/review/user/${userId}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const submitReview = async (data, token) => {
    try {
        const response = await axios.post(`${baseUrl}/api/review/add`, data, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getAllZaddyOrders = async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/orders/zaddy`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const updateZaddyOrderByAdmin = async (data, token) => {
    try {
        const response = await axios.put(`${baseUrl}/api/orders/updateZaddyOrderByAdmin/`, data, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getAllUserZaddyOrders = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/api/orders/zaddies/getSingleZaddyOrderForUser/${id}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const createCoupon = async (args) => {
    try {
        const response = await axios.post(`${baseUrl}/api/coupons/add`, args);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getAllCoupons = async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/coupons/getAllCoupons`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const activateCoupon = async (coupon) => {
    try {
        const response = await axios.post(`${baseUrl}/api/coupons/activateCoupon/${coupon}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const createBlackFriday = async (args) => {
    try {
        const response = await axios.post(`${baseUrl}/api/blackfriday/add`, args);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getAllBlackFriday = async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/blackfriday/getAllBlackFriday`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const activateBlackFriday = async (blackfriday) => {
    try {
        const response = await axios.post(`${baseUrl}/api/blackfriday/activateBlackFriday/${blackfriday}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const deleteBlackFriday = async (id) => {
    try {
        const response = await axios.post(`${baseUrl}/api/blackfriday/deleteBlackFriday/`, id);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const checkPointDet = async (phoneNumber, totalAmount) => {
    try {
        const response = await axios.post(`${baseUrl}/api/point/loyalty/process`, { phoneNumber: phoneNumber, totalAmount: totalAmount });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const createDispute = async (args) => {
    try {
        const response = await axios.post(`${baseUrl}/api/disputes/add`, args);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getAllDispute = async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/disputes/getAllDisputes`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const deleteCoupon = async (id) => {
    try {
        const response = await axios.post(`${baseUrl}/api/coupons/deleteCoupon/`, id);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const createPoint = async (args) => {
    try {
        const response = await axios.post(`${baseUrl}/api/point/add`, args);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getAllPoints = async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/point/getAllPoints`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const activatePoint = async (point) => {
    try {
        const response = await axios.post(`${baseUrl}/api/point/activatePoint/${point}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const deletePoint = async (id) => {
    try {
        const response = await axios.post(`${baseUrl}/api/point/deletePoint/`, id );
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const addCategory = async (name, token) => {
    try {    
        const response = await axios.post(`${baseUrl}/api/categories/add-category/`, name, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const getCategories = async () => {
    try {    
        const response = await axios.get(`${baseUrl}/api/categories/get-all-categories/`);
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const deleteCategoryById = async (id, token) => {
    try {    
        const response = await axios.post(`${baseUrl}/api/categories/delete-category/`, { id:id }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const addMiniAmt = async (name, token) => {
    try {    
        const response = await axios.post(`${baseUrl}/api/miniAmts/add-miniAmt/`, name, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const getMiniAmt = async () => {
    try {    
        const response = await axios.get(`${baseUrl}/api/miniAmts/get-all-miniAmts/`);
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const deleteMiniAmtById = async (id, token) => {
    try {    
        const response = await axios.post(`${baseUrl}/api/miniAmts/delete-miniAmt/`, { id:id }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const addSizeTypes = async (sizeTypes, token) => {
    try {    
        const response = await axios.post(`${baseUrl}/api/sizeTypes/add/`, { name: sizeTypes }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const getAllSizes = async () => {
    try {    
        const response = await axios.get(`${baseUrl}/api/sizeTypes/getAllSizes/`)
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const AddSize = async (data, token) => {
    try {    
        const { size, id } = data;
        const response = await axios.post(`${baseUrl}/api/sizeTypes/AddSize/`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const forgotpassword = async (data) => {
    try {    
        const { email } = data;
        const response = await axios.post(`${baseUrl}/api/password-reset/`, data);
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const resetpassword = async (data) => {
    try {    
        const { password, token } = data;
        const response = await axios.post(`${baseUrl}/api/password-reset/reset-password`, data);
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const getSliders = async () => {
    try {    
        const response = await axios.get(`${baseUrl}/api/homePage/`);
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const createSlider = async (data) => {
    try {    
        const response = await axios.post(`${baseUrl}/api/homePage/`, data);
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};


export const getLatestCategories = async () => {
    try {    
        const response = await axios.get(`${baseUrl}/api/categories/get-homepage-categories/`);
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const getImagesFromCloudinary = async (reset, nextCursor) => {
    try {    
        const response = await axios.get(`${baseUrl}/api/images`, {
            params: { next_cursor: reset ? null : nextCursor }
        });
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const uploadImageFromImageGalleryPopComponent = async (data) => {
    try {    
        const response = await axios.post(`${baseUrl}/upload-image`, data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};

export const getPageContent = async () => {
    try {    
        const response = await axios.get(`${baseUrl}/api/page-content/get-all-page-content/`);
    
        return {
          data: response.data,
          status: response.status,
        };
      } catch (error) {
        return {
          data: error.response ? error.response.data : 'Network Error',
          status: error.response ? error.response.status : 500,
        };
      }
};


export const fetchPageContent = async (name) => {
    try {
      const response = await axios.get(`${baseUrl}/api/page-content/get-page-content/${name}`);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return {
        data: error.response ? error.response.data : 'Network Error',
        status: error.response ? error.response.status : 500,
      };
    }
  };
  
  export const createOrUpdatePageContent = async (name, content, token) => {
    try {
      const response = await axios.post(`${baseUrl}/api/page-content/add-or-update-page-content`, { name, content }, {
      headers:{
        Authorization: `Bearer ${token}`,
      }});

      return {
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return {
        data: error.response ? error.response.data : 'Network Error',
        status: error.response ? error.response.status : 500,
      };
    }
  };

  export const createProductCategoriesContent = async (data, token) => {
    try {
        const response = await axios.post(`${baseUrl}/api/product-categories/create`, data, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        console.error('Error in createProductCategoriesContent:', "error");
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const getAllProductCategoriesContent = async (token) => {
    try {
        const response = await axios.get(`${baseUrl}/api/product-categories`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching all ProductCategories:', "error");
        throw error;
    }
};

export const deleteProductCategoriesContent = async (id) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/product-categories/${id}`);
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        console.error('Error in deleteProductCategoriesContent:', "error");
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};

export const updateProductCategoriesContentStatus = async (id, status) => {
    try {
        const response = await axios.patch(`${baseUrl}/api/product-categories/${id}/status`, { status });
        return {
            data: response.data,
            status: response.status
        };
    } catch (error) {
        console.error('Error in updateProductCategoriesContentStatus:', "error");
        return {
            data: error.response ? error.response.data : 'Network Error',
            status: error.response ? error.response.status : 500
        };
    }
};
