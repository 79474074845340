import React, { useState } from 'react';
import OrderList from '../../components/OrderList';

const initialOrders = [
  {
    id: '1',
    userName: 'John Doe',
    date: '2023-06-01',
    total: 100,
    paymentStatus: 'Paid',
    deliveryStatus: 'Pending',
    shippingLocation: '123 Main St, City, Country',
    products: [
      { id: 'p1', name: 'Product 1', quantity: 2, price: 20 },
      { id: 'p2', name: 'Product 2', quantity: 1, price: 60 },
    ],
    transactionDetails: 'Transaction ID: 1234567890',
  },
  // Add more orders as needed
];

const Order = () => {
  const [orders, setOrders] = useState(initialOrders);

  const updateOrderStatus = (orderId, newStatus) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId ? { ...order, deliveryStatus: newStatus } : order
      )
    );
  };

  return (
    <div>
      <OrderList orders={orders} updateOrderStatus={updateOrderStatus} />
    </div>
  );
};

export default Order;
