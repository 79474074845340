import React, { useState, useEffect } from 'react';
import { createSlider } from '../calls/auths';
import ImageGalleryPopup from './ImageGalleryPopup';  // Import ImageGalleryPopup component

const PopupCard = ({ onClose, sliderData, multiSelect = false }) => {
  const [formData, setFormData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [currentImageField, setCurrentImageField] = useState('');

  const openPopup = (index, imageField) => {
    setCurrentField(index);
    setCurrentImageField(imageField);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    let initialFormData = sliderData.slice(0, 12); // Take the first 12 elements of sliderData
    while (initialFormData.length < 12) {
      initialFormData.push({
        text1: '',
        text2: '',
        button1: '',
        button2: '',
        buttonlink1: '',
        buttonlink2: '',
        image1: '',
        image2: ''
      });
    }
    setFormData(initialFormData);
  }, [sliderData]);

  const handleChange = (index, field, value) => {
    const updatedFormData = formData.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setFormData(updatedFormData);
  };

  const handleImageSelect = (images) => {
    if (currentField !== null) {
      const imageUrls = images.length ? images.map(image => image.secure_url).join(', ') : formData[currentField][currentImageField];
      handleChange(currentField, currentImageField, imageUrls);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Create an array of promises
    const promises = formData.map(data => createSlider(data));
  
    // Wait for all promises to resolve
    await Promise.all(promises);
    
    // Show the alert message
    alert('Sliders have been updated/created successfully');
    
    // Reload the page
    window.location.reload();
  };
  

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg overflow-hidden shadow-lg w-1/2 max-h-full p-8 relative border-4 border-black">
          <button className="absolute top-2 right-2" onClick={onClose}>X</button>
          <div className="text-center">
            <h1 className="text-2xl font-bold sm:text-3xl">Slider Settings</h1>
            <p className="mt-4 text-gray-500">
              Settings for buttons, images, texts and links of the slider.
            </p>
          </div>
          <form className="mt-8 space-y-4" onSubmit={handleSubmit} style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
            <div className="overflow-x-auto border">
              <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Text 1</th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Text 2</th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Button 1</th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Button 2</th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Button 1 link</th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Button 2 link</th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Image 1</th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Image 2</th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200">
                  {formData.map((item, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <input
                          type='text'
                          value={item.text1}
                          onChange={(e) => handleChange(index, 'text1', e.target.value)}
                        />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <input
                          type='text'
                          value={item.text2}
                          onChange={(e) => handleChange(index, 'text2', e.target.value)}
                        />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <input
                          type='text'
                          value={item.button1}
                          onChange={(e) => handleChange(index, 'button1', e.target.value)}
                        />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <input
                          type='text'
                          value={item.button2}
                          onChange={(e) => handleChange(index, 'button2', e.target.value)}
                        />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <input
                          type='text'
                          value={item.buttonlink1}
                          onChange={(e) => handleChange(index, 'buttonlink1', e.target.value)}
                        />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <input
                          type='text'
                          value={item.buttonlink2}
                          onChange={(e) => handleChange(index, 'buttonlink2', e.target.value)}
                        />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <input
                          type='text'
                          value={item.image1}
                          onChange={(e) => handleChange(index, 'image1', e.target.value)}
                          onClick={() => openPopup(index, 'image1')}
                        />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <input
                          type='text'
                          value={item.image2}
                          onChange={(e) => handleChange(index, 'image2', e.target.value)}
                          onClick={() => openPopup(index, 'image2')}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button
              className="inline-block rounded border border-primary bg-primary px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
              type="submit"
            >
              UPDATE
            </button>
          </form>
        </div>
      </div>
      <ImageGalleryPopup isOpen={isPopupOpen} onClose={closePopup} onImageSelect={handleImageSelect} multiSelect={multiSelect} />
    </>
  );
};

export default PopupCard;
